import instance from "../Service";

export default class Notification {
  static async delete_notification(data) {
    return await instance.post("/notif/delete_notification", data)
  }
  static async delete_all_notifications(){
    return await instance.post(`/notif/delete_all_notifications`)
  }
  static async get_notifications(){
    return await instance.get(`/notif/get_notifications`)
  }
  static async check_notifications(){
    return await instance.get(`/notif/check_notifications`)
  }
}
