const styles = {
  container: {
    padding: "16px 0",
    border: "1px solid #F5F5F5",
    borderRadius: "8px",
    display: "flex",
  },
  flex: {
    width: '100%',
    display: 'flex',
    flexDirection:'column',
    gap :'8px'
  },
  text: {
    fontSize: "14px",
    color: "#5F5F5F"
  },
  textBold: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#2D2D2D"
  },
  block: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  unreadItem: {
    width: "8px",
    height: "8px",
    borderRadius: "50%"
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: '12px 0',
  },
};

export default styles