import { Box, FormControl, InputBase, MenuItem, Select, TextField } from "@mui/material";
import LinkUI from "./link";
import LabelUI from "./label";
import IconUI from "./icon";
import {InputUIProps} from "../../shared/types/instances";
import {forwardRef, Ref} from "react";
import styles from "./styles.module.scss";


const InputUI = forwardRef(({
    label,
    link,
    icon,
    icon_color,
    onClick,
    type,
    icon_direction,
    touched,
    select = false,
    selectData,
    selectHandleChange,
    selectDefaultValue,
    multiline,
    lines = 5,
    value,
    disabled,
    sx,
    onChange,
    readonly,
    isChangeView,
    iconStyles,
    ...otherProps
  } : InputUIProps,
    ref: Ref<HTMLInputElement| null>
) => {
  return (
    <FormControl sx={{ flexGrow: 1 }}>

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: label ? "0 16px 16px" : 0 }}>
        {label && <LabelUI htmlFor="input-with-icon-adornment" text={label} />}
        {
          link && <LinkUI label={link.label} to={link.to} />
        }
      </Box>

      <FormControl sx={{ position: "relative" }}>
        {
          !select && !multiline &&
          (
            <>
              <InputBase
                  inputRef={ref}
                  sx={{
                    border: touched ? "1px solid #FF6954" : "1px solid #D8D8D8",
                    backgroundColor: disabled ? "#fbfbfb" : "#fff",
                    borderRadius: "10px",
                    padding: "8px 16px",
                    position: "relative",
                    width: "100%"
                  }}
                  type={type}
                  value={value}
                  disabled={disabled}
                  readOnly={readonly}
                  onChange={onChange}
                  {...otherProps}
              />
              {}
              {icon &&
                  <IconUI
                      name={icon}
                      color={icon_color}
                      onClick={onClick}
                      direction={icon_direction}
                      style={{top: '25%', ...iconStyles}}
                      className={`${isChangeView && styles.iconAnimate}`}
                  />
              }
            </>
          )
        }
        {
          multiline && (
            <>
              <TextField
                id="outlined-textarea"
                multiline
                rows={lines}
                sx={{
                    borderRadius: "16px",
                    ...sx
                }}
                value={value}
                onChange={onChange}
                disabled={disabled}
                ref={ref}
                {...otherProps}
              />
              <Box sx={{ position: 'absolute', right: 0, top: "24px", }}>
                {icon && <IconUI name={icon} color={icon_color} onClick={onClick} direction={icon_direction} />}
              </Box>

            </>
          )
        }
        {
          select && <Select
            value={type}
            onChange={selectHandleChange}
            defaultValue={selectDefaultValue ? selectDefaultValue.value : selectData[0].value}
            sx={{
              borderRadius: "10px",
              border: '1px solid #D8D8D8',
              padding: "0",
              ".MuiOutlinedInput-notchedOutline":
              {
                border: 0,
                padding: 0,
              },
              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
                padding: 0,
              },
              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
                padding: 0,
              },
              "& .MuiSelect-select": {
                padding: "12px 16px !important"
              },
              boxSizing: 'border-box',
            }}
            ref={ref}
            {...otherProps}
          >
            {selectData.map((item: any,index: number) => <MenuItem key={index} value={item.value}>{item.label}</MenuItem>)}
          </Select>
        }
      </FormControl>
    </FormControl >
  )
})

export default InputUI