import {Box,} from "@mui/material"
import styles from "./styles"
import {useDispatch} from "react-redux"
import {deleteNotification, getNotifications} from "../../../../../app/slices/notification";
import {NotificationTabDesktop} from "./TabDesktop/NotificationTabDesktop";
import {NotificationTabMobile} from "./TabMobile/NotificationTabMobile";
import {useWindowSize} from "../../../../../shared/hooks/useWindowSize";

interface Props {
  data: any
}

const NotificationHistoryTab = ({ data }: Props) => {
  const dispatch = useDispatch();
  const {width} = useWindowSize();

  const deleteNotificationRequest = async (index: number) => {
    // @ts-ignore
    await dispatch(deleteNotification({notificationId: index}))
        // @ts-ignore
        .finally(dispatch(getNotifications()))
  }

  return (
    <Box sx={styles.container}>
      {data.map((item: any, index: number) => (
          (width > 1024) ? (
              <NotificationTabDesktop
                  item={item}
                  index={index}
                  deleteNotificationRequest={deleteNotificationRequest}
              />
          ) : (
              <NotificationTabMobile
                  item={item}
                  index={index}
                  deleteNotificationRequest={deleteNotificationRequest}
              />
          )
      ))}
    </Box>
  )
}

export default NotificationHistoryTab