import 'react-international-phone/style.css';
import { BaseTextFieldProps, MenuItem, Select, TextField, Typography, Box } from '@mui/material';
import { CountryIso2, defaultCountries, FlagImage, parseCountry, usePhoneInput } from 'react-international-phone';
import { filteredCountries } from '../MuiCountryInput/MuiCountryInput';
import {useTranslation} from "react-i18next";
import useLocalStorage from "../../../shared/hooks/useLocalStorage";

export interface MUIPhoneProps extends BaseTextFieldProps {
  value: string;
  onChange: (phone: string) => void;
}

const MuiPhoneInput: React.FC<MUIPhoneProps> = ({
  value,
  onChange,
  error,
  ...restProps
}) => {
  const [countryCode] = useLocalStorage('country_code', '')
  const {t} = useTranslation()

  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: countryCode || 'us',
    value,
    countries: defaultCountries,
    onChange: (data) => {
      onChange(data.phone);
    },
    disableFormatting: true,
    disableDialCodeAndPrefix: true
  });

  return (
      <Box display="flex" alignItems="center" gap={4} sx={{width:'100%'}}>
        <Select
            MenuProps={{
              style: {
                height: '300px',
                width: '360px',
                top: '5px',
                left: '-80px',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            sx={{
              border: `${error ? '1px solid #FF6954' : '1px solid rgb(216, 216, 216)'}`,
              borderRadius: '10px',
              width: '192px', // Adjust width as needed
              fieldset: {
                display: 'none',
              },
              '&.Mui-focused:has(div[aria-expanded="false"])': {
                fieldset: {
                  display: 'block',
                },
              },
              '.MuiSelect-select': {
                padding: '15px 14px',
              },
              svg: {
                right: 0 ,
              },
            }}
            value={country.iso2}
            onChange={(e) => setCountry(e.target.value as CountryIso2)}
            renderValue={(value) => (
                <Box display="flex" alignItems="center">
                  <FlagImage iso2={value} />
                  <Typography marginLeft={1}>+{country.dialCode}</Typography>
                </Box>
            )}
        >
          {filteredCountries.map((c) => {
            const country = parseCountry(c);
            return (
                <MenuItem key={country.iso2} value={country.iso2}>
                  <FlagImage iso2={country.iso2} style={{ marginRight: '8px' }} />
                  <Typography marginRight="8px">{country.name}</Typography>
                  <Typography color="gray">+{country.dialCode}</Typography>
                </MenuItem>
            );
          })}
        </Select>

        <TextField
            variant="outlined"
            color="primary"
            placeholder={t("phone_number")}
            value={inputValue}
            onChange={handlePhoneValueChange}
            type="tel"
            inputRef={inputRef}
            {...restProps}
            sx={{
              flexGrow: '1',
              border: `${error ? '1px solid #FF6954' : '1px solid rgb(216, 216, 216)'}`,
              borderRadius: '10px',
              '& fieldset': {
                border: 'none',
              }
            }} // Adjust as necessary
        />
      </Box>
  );
};

export default MuiPhoneInput;
