export const styles = {
  container: {
    display: 'flex',
    gap: "16px",
    padding: "16px",
    flexDirection: 'column',
    alignItems: 'start',
  },
  form: {
    display: 'flex',
    gap: "32px",
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: "10px",
    padding: "32px",
    width: "100%",
    boxSizing: "border-box",
    position: 'relative',
  },
  fileName: {
    color: "#000000",
    fontSize: "16px",
    fontWeight: "600",
    textAlign: "center"
  },
  cross: {
    position: "absolute",
    top: "16px",
    right: "16px",
    cursor: 'pointer',
    backgroundColor: "#F5F5F5",
    borderRadius: "5px",
    width: "24px",
    height: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems:'center',
    justifyContent:'center'
  },
  button: {
    borderRadius: "48px",
    padding: "12px 32px",
    backgroundColor: "#transparent",
    border: "2px solid #000000",
    color: "#000000",
    fontSize: '16px',
    fontWeight: '600',
    width: 'max-content',
    textTransform: 'none',
    height: '48px',
    boxSizing: 'border-box',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: "16px"
  }
}
export default styles