import { Link } from "@mui/material";

interface Props {
  label?: string,
  to: string,
  align?: "center" | "left" | "right"
  icon?: string
  target?:string
  rel?:string
}

const LinkUI = ({ label, to, align, icon, target, rel}: Props) => {
  return (
    <>
      {label &&
          <Link href={to}
                target={target}
                rel={rel}
                color="#FF8800"
                sx={{ textDecoration: "none", fontSize: "14px", fontWeight: "500", textAlign: align }}
          >
            {label}
          </Link >
      }
      {
        icon &&
        <Link href={to} target={target} rel={rel}>
          <img src={icon} alt={label} />
        </Link>
      }
    </>

  )
}

export default LinkUI