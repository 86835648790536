import {Box, Typography} from "@mui/material"
import {ButtonUI, LabelUI} from "../../../../../../ui"
import {Field, Form, Formik} from "formik"
import * as yup from 'yup';
import {useDispatch, useSelector} from "react-redux";
import {removeRejectField, setKyb_1, setStatus} from "../../../../../../../app/slices/kyb";
import React from "react";
import styles from "./styles";
import {StepProps} from "../../../../../../../shared/types/instances";
import {tDouble} from "../../../../../../../shared/utils/translateFunc";
import {useTranslation} from "react-i18next";

const validationSchema = yup.object().shape({
  company_name: yup.string().required(tDouble("field_empty")),
  date_of_incorp: yup.string().required(tDouble("field_empty")),
  company_reg_number: yup.string().required(tDouble("field_empty_2")),
  companys_website: yup.string().required(tDouble("field_empty")),
  employees_have: yup.string().required(tDouble("field_empty")),
  business_description: yup.string().required(tDouble("field_empty"))
});

const Step1 = ({ handleStepUp }: StepProps) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const company_id = useSelector((state: any) => state.dashboard.dashboard.Current_Company)
  const company = useSelector((state: any) => state.dashboard.company)
  const rejectedFields = useSelector((state: any) => state.kyb.rejectedFields || [])

  const { step_1 } = useSelector((state: any) => state.kyb)
  if (!company) return null

  const checkState = () => {
    if (company.company_name) {
      return {
        company_name: company.company_name,
        date_of_incorp: company.date_of_incorp,
        company_reg_number: company.company_reg_number,
        companys_website: company.companys_website,
        employees_have: company.employees_have,
        business_description: company.business_description
      }
    } else {
      return step_1
    }
  }

  const handleDateChange = (
      e: React.ChangeEvent<HTMLInputElement>,
      setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    const inputValue = e.target.value;
    const splitValue = inputValue.split('-');
    const maxDate = new Date("2025-12-31");
    const selectedDate = new Date(inputValue);

    if (selectedDate > maxDate) {
      setFieldValue("date_of_incorp", `2025-${splitValue[1]}-${splitValue[2]}`);
    } else {
      setFieldValue("date_of_incorp", inputValue);
    }
  };

  const handleEmployeesChange = (
      e: React.ChangeEvent<HTMLInputElement>,
      setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    let inputValue = e.target.value;

    if (inputValue === "") {
      setFieldValue("employees_have", "");
      return;
    } else if (Number(inputValue) < 1) {
      inputValue = "1";
    }

    setFieldValue("employees_have", inputValue);
  }

  const submitHandler = async (values: any) => {

    try {
      localStorage.setItem('company_name', values.company_name)
      // @ts-ignore
      dispatch(setKyb_1({ company_id, ...values })).then(() => handleStepUp())
      dispatch(setStatus('second_tier'))
    } catch (e: any) {
      console.log(e)
    }
  }

  return (
    <Formik
      key={company_id} // Уникальный ключ для каждого набора данных
      initialValues={checkState()}
      validationSchema={validationSchema}
      onSubmit={submitHandler}
      validateOnMount={false}
    >
      {({ errors, touched, values, setFieldValue, handleSubmit }: any) => {
        return (
          <Form style={{ display: 'flex', flexDirection: 'column', gap: '32px', }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', }}>
              <Box sx={{ padding: '0 16px' }}>
                <LabelUI
                  htmlFor="company_name"
                  text={t("company_name")}
                  error={(errors.company_name && touched.company_name) || rejectedFields.includes('company_name')} />
              </Box>

              <Field
                name='company_name'
                style={{
                  border: errors.company_name && touched.company_name || rejectedFields.includes('company_name') ? "1px solid #FF6954" : "1px solid #D8D8D8",
                  ...styles.field,
                }}
                value={values.company_name}
                onChange={(e: any) => {
                  setFieldValue('company_name', e.target.value)
                  dispatch(removeRejectField('company_name'))
                }}
              />
              {errors.company_name && touched.company_name ? (
                <Box sx={{ padding: '0 16px' }}>
                  <Typography
                    variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                    {errors.company_name}
                  </Typography>
                </Box>

              ) : null}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', }}>
              <Box sx={{ padding: '0 16px' }}>
                <LabelUI
                  htmlFor="date_of_incorp"
                  text={t("kyb_date")}
                  error={errors.date_of_incorp && touched.date_of_incorp || rejectedFields.includes('date_of_incorp')} />
              </Box>

              <Field
                name='date_of_incorp'
                type="date"
                max="2025-12-31"
                lang="en-US"
                placeholder="DD. MM. YYYY"
                style={{
                  border: errors.date_of_incorp && touched.date_of_incorp || rejectedFields.includes('date_of_incorp') ? "1px solid #FF6954" : "1px solid #D8D8D8",
                  ...styles.field,
                }}
                value={values.date_of_incorp}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleDateChange(e, setFieldValue)
                  dispatch(removeRejectField('date_of_incorp'))
                }}
              />
              {errors.date_of_incorp && touched.date_of_incorp ? (
                <Box sx={{ padding: '0 16px' }}>
                  <Typography
                    variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                    {errors.date_of_incorp}
                  </Typography>
                </Box>

              ) : null}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', }}>
              <Box sx={{ padding: '0 16px' }}>
                <LabelUI
                  htmlFor="company_reg_number"
                  text={t("kyb_reg_num")}
                  error={errors.company_reg_number && touched.company_reg_number || rejectedFields.includes('company_reg_number')} />
              </Box>

              <Field
                name='company_reg_number'
                style={{
                  border: errors.company_reg_number && touched.company_reg_number || rejectedFields.includes('company_reg_number') ? "1px solid #FF6954" : "1px solid #D8D8D8",
                  ...styles.field,
                }}
                value={values.company_reg_number}
                onChange={(e: any) => {
                  setFieldValue('company_reg_number', e.target.value)
                  dispatch(removeRejectField('company_reg_number'))
                }}
              />
              {errors.company_reg_number && touched.company_reg_number ? (
                <Box sx={{ padding: '0 16px' }}>
                  <Typography
                    variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                    {errors.company_reg_number}
                  </Typography>
                </Box>

              ) : null}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', }}>
              <Box sx={{ padding: '0 16px' }}>
                <LabelUI
                  htmlFor="companys_website"
                  text={t("kyb_website")}
                  error={errors.companys_website && touched.companys_website || rejectedFields.includes('companys_website')} />
              </Box>

              <Field
                name='companys_website'
                style={{
                  border: errors.companys_website && touched.companys_website || rejectedFields.includes('companys_website') ? "1px solid #FF6954" : "1px solid #D8D8D8",
                  ...styles.field,
                }}
                value={values.companys_website}
                onChange={(e: any) => {
                  setFieldValue('companys_website', e.target.value)
                  dispatch(removeRejectField('companys_website'))
                }}
              />
              {errors.companys_website && touched.companys_website ? (
                <Box sx={{ padding: '0 16px' }}>
                  <Typography
                    variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                    {errors.companys_website}
                  </Typography>
                </Box>

              ) : null}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', }}>
              <Box sx={{ padding: '0 16px' }}>
                <LabelUI
                  htmlFor="employees_have"
                  text={t("kyb_emp")}
                  error={errors.employees_have && touched.employees_have || rejectedFields.includes('employees_have')} />
              </Box>

              <Field
                name='employees_have'
                type="number"
                min="1"
                inputMode="numeric"
                style={{
                  border: errors.employees_have && touched.employees_have || rejectedFields.includes('employees_have') ? "1px solid #FF6954" : "1px solid #D8D8D8",
                  ...styles.field,
                }}
                value={values.employees_have}
                onChange={(e: any) => {
                  handleEmployeesChange(e, setFieldValue)
                  dispatch(removeRejectField('employees_have'))
                }}
              />
              {errors.employees_have && touched.employees_have ? (
                <Box sx={{ padding: '0 16px' }}>
                  <Typography
                    variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                    {errors.employees_have}
                  </Typography>
                </Box>

              ) : null}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', }}>
              <Box sx={{ padding: '0 16px' }}>
                <LabelUI
                  htmlFor="business_description"
                  text={t("kyb_descr")}
                  error={errors.business_description && touched.business_description || rejectedFields.includes('business_description')} />
              </Box>

              <Field
                name='business_description'
                as="textarea"
                style={{
                  border: errors.business_description && touched.business_description || rejectedFields.includes('business_description') ? "1px solid #FF6954" : "1px solid #D8D8D8",
                  borderRadius: "10px",
                  padding: "8px 16px",
                  position: "relative",
                  width: "100%",
                  boxSizing: "border-box",
                  height: "96px",
                }}
                value={values.business_description}
                onChange={(e: any) => {
                  setFieldValue('business_description', e.target.value)
                  dispatch(removeRejectField('business_description'))
                }}
              />
              {errors.business_description && touched.business_description  ? (
                <Box sx={{ padding: '0 16px' }}>
                  <Typography
                    variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                    {errors.business_description}
                  </Typography>
                </Box>

              ) : null}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: "16px" }}>
              <ButtonUI
                type="default"
                text="Next"
                onClick={handleSubmit} />
            </Box>
          </Form>
        )
      }}

    </Formik >
  )
}

export default Step1