import '../../../../../pages/Register/styles.css'
import Step1 from "./Steps/Step1/Step1"
import Step2 from "./Steps/Step2/Step2"
import Step3 from "./Steps/Step3/Step3"
import Step4 from "./Steps/Step4/Step4"
import Step5 from "./Steps/Step5/Step5"
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {setStatus} from "../../../../../app/slices/kyb"
import {StepProps} from "../../../../../shared/types/instances";

const FormVariant = ({ handleStepUp, handleStepDown }: StepProps) => {

  const {kyb_status='first_tier'} = useSelector((state: any) => state.kyb)
  const dispatch = useDispatch()

  useEffect(() => {
    if (kyb_status) {
      dispatch(setStatus(kyb_status))
    }
  }, [kyb_status, dispatch])

  return (
    <>
      {
        kyb_status === "first_tier" &&
        <Step1
          handleStepUp={handleStepUp}
          handleStepDown={handleStepDown}
        />
      }
      {
        kyb_status === "second_tier" &&
        <Step2
          handleStepUp={handleStepUp}
          handleStepDown={handleStepDown}
        />
      }
      {
        kyb_status === "third_tier" && <Step3
          handleStepUp={handleStepUp}
          handleStepDown={handleStepDown}
        />
      }
      {
        kyb_status === "fourth_tier" && <Step4
          handleStepUp={handleStepUp}
          handleStepDown={handleStepDown}
        />
      }
      {
        (kyb_status === "pending" || kyb_status === "approved" ) &&
          <Step5 />
      }

    </>
  )
}

export default FormVariant