
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    border: "none",
    background: "transparent",
    pointerEvent: 'none',
    margin: "0 !important",
    cursor: 'default',
    "& .MuiAccordionDetails-root": {
      padding: 0
    },
    "&::before": {
      content: "none"
    },
    "& h3 > *": {
      margin: "0 !important"
    }
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 32px',
    height: "64px",
    margin: "0 !important",
    cursor: 'default',
    "& .MuiAccordionSummary-content": {
      margin: "0 !important",
      cursor: 'default',
    },
    '&:hover:not(.Mui-disabled)': {
      cursor: 'default',
    },
  },

  content: {
    minHeight: "336px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
    borderRadius: "16px",
    cursor: 'default',
  },

} as const

export default styles