import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Dashboard from "../../api/repositories/dashboard";
import {handleApiError} from "../../helpers/handleApiError";
import {getProduct} from "./products";

const initialState = {
  dashboard : {},
  user: [],
  companies: [],
  company: {},
  products: [],
  isLoading: false,
  analytics: 'Graph',
  analyticsData: {
    data: {},
    scannedData: [],
    verifiedData: []
  },
  statisticsData: {
    'Total': 0,
    'Total Verified': 0,
    'Total Scanned': 0,
  },
}

export const getDashboard = createAsyncThunk(
  'dashboard/getDashboard',
  async (blockErrorNotify= false) => {
    try {
      const response = await Dashboard.get_dashboard()
      return response.data
    } catch (error) {
      if(!blockErrorNotify)
        handleApiError(error)
    }
  }
)
export const getProductsByCompany = createAsyncThunk(
    'dashboard/getProductsByCompany',
    async () => {
      try {
        const response = await Dashboard.get_products_by_company()
        return response.data
      } catch (error) {
        handleApiError(error)
      }
    }
)


const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setAnalyticsVariant: (state, { payload }) => {
      state.analytics = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboard.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getDashboard.fulfilled, (state, { payload }) => {
        state.isLoading = false
        if(payload) {
          state.dashboard = payload.Dashboard
          state.user = payload.Dashboard.User

          const currentCompanyId =  state.dashboard.Current_Company
          state.companies = payload.Dashboard.Companies
          state.company = state.companies.find((item) => item._id === currentCompanyId)

          state.products = payload.Dashboard.Products
          state.analyticsData.data = payload.Dashboard.Analytics

          if (state.analyticsData.data && state.analyticsData.data.qr_code.length > 0) {
            const today = new Date();
            const startDate = new Date(new Date().setDate(today.getDate() - 365));
            const endDate = today;

            const dateMap = {};
            state.analyticsData.data.total = payload.Dashboard.Products.reduce((acc,i)=>{
              return acc += i.total
            },0)
            state.analyticsData.scannedData = [];
            state.analyticsData.verifiedData = [];

            // Преобразуем данные qr_code для использования в графиках Scanned и Verified
            state.analyticsData.data.qr_code.forEach((item) => {
              const formattedDate = new Date(item.date).toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit'});
              dateMap[formattedDate] = {
                scanned: item.total_scans,
                verified: item.total_verified
              };
            });

            for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
              const formattedDate = date.toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit'});
              state.analyticsData.scannedData.push({
                name: formattedDate,
                uv: dateMap[formattedDate] ? dateMap[formattedDate].scanned : 0
              });
              state.analyticsData.verifiedData.push({
                name: formattedDate,
                uv: dateMap[formattedDate] ? dateMap[formattedDate].verified : 0
              });
            }

            state.analyticsData.scannedData = JSON.parse(JSON.stringify(state.analyticsData.scannedData));
            state.analyticsData.verifiedData = JSON.parse(JSON.stringify(state.analyticsData.verifiedData));

          }else{
            state.analyticsData.scannedData = [];
            state.analyticsData.verifiedData = [];
          }
          Object.assign(state.statisticsData, {
            'Total': state.analyticsData.data.total,
            'Total Scanned': state.analyticsData.data.total_scans,
            'Total Verified': state.analyticsData.data.total_verified,
          });
        }
      })
      .addCase(getProductsByCompany.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getProductsByCompany.fulfilled,(state, {payload}) => {
        state.isLoading = false
        state.products = payload.Event;

      })
    builder
        .addCase(getProduct.fulfilled, (state, {payload}) => {
          state.user.qr_balance = payload.Event.money
        })
  }
})
export const { setAnalyticsVariant } = dashboardSlice.actions
export default dashboardSlice.reducer
