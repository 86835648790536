import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Notification from "../../api/repositories/notification";
import {handleApiError} from "../../helpers/handleApiError";
import {formatToLocaleTime} from "../../shared/utils/formatToLocaleTime";
import {notificationText} from "../../shared/constants";

const initialState = {
  isLoading: false,
  notification: []
};

export const getNotifications = createAsyncThunk(
    "notification/getNotifications",
    async () => {
      try {
        const response = await Notification.get_notifications();
        return response.data;
      } catch (error) {
        handleApiError(error)
      }
    },
);

export const checkNotifications = createAsyncThunk(
    "notification/checkNotifications",
    async () => {
      try {
        const response = await Notification.check_notifications();
        return response.data;
      } catch (error) {
        handleApiError(error)
      }
    },
);

export const deleteAllNotifications = createAsyncThunk(
    "notification/deleteAllNotifications",
    async () => {
      try {
        const response = await Notification.delete_all_notifications();
        return response.data;
      } catch (error) {
        handleApiError(error)
      }
    },
);

export const deleteNotification = createAsyncThunk(
    "notification/deleteNotification",
    async (payload) => {
      try {
        const response = await Notification.delete_notification(payload);
        return response.data;
      } catch (error) {
        handleApiError(error)
      }
    },
);

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(getNotifications.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getNotifications.fulfilled, (state, {payload}) => {
          state.isLoading = false;

          if(payload.Event) {
            state.notification = payload.Event.map(
                (item, index) => ({
                  date: formatToLocaleTime(item.date),
                  text: notificationText[item.data] || "Unknown notification",
                  isRead: item.read,
                  type: item.type,
                  id: index,
                })
            );
          }
        })
    builder
        .addCase(checkNotifications.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(checkNotifications.fulfilled, (state) => {
          state.isLoading = false
        });
    builder
        .addCase(deleteAllNotifications.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(deleteAllNotifications.fulfilled, (state) => {
          state.isLoading = false
        });
    builder
        .addCase(deleteNotification.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(deleteNotification.fulfilled, (state) => {
          state.isLoading = false
        });
  },
});

export default notificationSlice.reducer;