import { FormControl, Grid2, MenuItem, Select, Typography, SelectChangeEvent } from "@mui/material"
import styles from "./styles"
import { useState } from "react";
import {useTranslation} from "react-i18next";
const NotificationFilters = () => {

  const [state, setState] = useState('');
  const handleChange = (event: SelectChangeEvent) => {
    setState(event.target.value);
  };

  const {t} = useTranslation()

  return (
    <FormControl
      variant="outlined"
      size="small"
      sx={{ width: "100%" }}
    >
      <Grid2 container sx={{ padding: '32px' }} spacing={10}>
        <Grid2 size={3}>
          <Select
            defaultValue={t("date")}
            onChange={handleChange}
            sx={styles.customSelector}
          >
            <MenuItem value={t("date")}>
              <Typography variant="caption" sx={styles.selector}>{t("date")}</Typography>
            </MenuItem>
            <MenuItem value={t("time")}>
              <Typography variant="caption" sx={styles.selector}>{t("time")}</Typography>
            </MenuItem>
          </Select>
        </Grid2>
        <Grid2 size={9}>
          <Typography variant="caption" sx={styles.selector}>{t("description")}</Typography>
        </Grid2>
      </Grid2>

    </FormControl>
  )
}

export default NotificationFilters

