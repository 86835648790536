import { BillsDto, OperationsDto, Product, ReferralHistoryDto, SortConfig } from '../types/instances';

type ProductsTypes = Product[] | ReferralHistoryDto[] | BillsDto[] | OperationsDto[];

export function sortProducts(dataArray: ProductsTypes, sortConfig: SortConfig): ProductsTypes {
  if (dataArray && dataArray.length > 0) {
    const {key, order} = sortConfig;
    if (!key) return dataArray;

    const extractValue: Record<string, (item: any) => number | string> = {
      date: (item) => ('created_at' in item ? new Date(item.created_at).getTime() : new Date(item.date).getTime()),
      qrcodes: (item) => item.total,
      verified: (item) => item.total_verified,
      scanned: (item) => item.total_scanned,
      feedbacks: (item) => item.feedback_chat?.length,
      description: (item) => item.description,
      amounts: (item) => item.amount,
      values: (item) => item.value,
      names: (item) => item.product_name,
      quantity: (item) => item.quantity,
      type: (item) => item.type,
    };


    const valueExtractor = extractValue[key];
    if (!valueExtractor) return dataArray;

    const sortedProducts = [...dataArray]
        .sort((a, b) => {

          const aVal = valueExtractor(a);
          const bVal = valueExtractor(b);

          if (aVal < bVal) return order === 'asc' ? -1 : 1;
          if (aVal > bVal) return order === 'asc' ? 1 : -1;
          return 0;
        });

    return sortedProducts as ProductsTypes;
  } else {
    return []
  }
}