import React from "react";
import './style.css';

interface Props {
  name?: string;
  size?: number;
  direction?: string;
  color?: string | undefined;
  onClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  style?: object | null;
  animate?: boolean;
  placeholder?: boolean;
  className?: string;
}

const IconUI = ({
  name,
  size = 24,
  onClick,
  color,
  direction = 'default',
  style,
  animate,
  placeholder,
  className,
}: Props) => {
  const isIconVisible = name !== undefined;

  return (
    <img
      onClick={onClick}
      className={`${animate ? 'mini_loader' : ''} ${className}`}
      style={{
        width: size,
        height: size,
        position: direction === "default" ? 'static' : 'absolute',
        right: direction === 'right' ? "16px" : "initial",
        left: direction === 'left' ? "0" : "initial",
        top: '1px',
        opacity: isIconVisible ? 1 : 0,  // Если имя не задано, делаем иконку прозрачной
        ...style,
      }}
      src={placeholder ? '/assets/placeholder.svg' : `/assets/icons/${name}.svg`}
      alt={name || ''}
    />
  );
};

export default IconUI;
