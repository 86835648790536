import {Box, Typography} from "@mui/material"
import {ButtonUI, LabelUI} from "../../../../../../ui"
import {useDispatch, useSelector} from "react-redux"
import {getDashboard} from "../../../../../../../app/slices/dashboard";
import {Field, Formik } from "formik";
import * as yup from 'yup';
import {Form} from "react-router-dom";
import {removeRejectField, setAdressMatch, setKyb_3, setStatus} from "../../../../../../../app/slices/kyb";
import {useState} from "react";
import MuiCountryInput from "../../../../../../ui/MuiCountryInput/MuiCountryInput";
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import {StepProps} from "../../../../../../../shared/types/instances";
import {useTranslation} from "react-i18next";
import {tDouble} from "../../../../../../../shared/utils/translateFunc";

const validationSchema = yup.object().shape({
  country: yup.string().required(tDouble("field_empty")),
  address1: yup.string().required(tDouble("field_empty")),
  address2: yup.string(),
  city: yup.string().required(tDouble("field_empty")),
  region: yup.string().required(tDouble("field_empty")),
  zip: yup.string().required(tDouble("field_empty")),
  a_country: yup.string().required(tDouble("field_empty")),
      // .test('is-specific-country', 'This is default country', (value) => value !== 'Russia'),
  a_address1: yup.string().required(tDouble("field_empty")),
  a_address2: yup.string(),
  a_city: yup.string().required(tDouble("field_empty")),
  a_region: yup.string().required('The field cannot be empty '),
  a_zip: yup.string().nullable().required(tDouble("field_empty")),
});

const Step3 = ({ handleStepUp, handleStepDown }: StepProps) => {
  const {t} = useTranslation()

  const dispatch = useDispatch()

  const company_id = useSelector((state: any) => state.dashboard.dashboard.Current_Company)
  const company = useSelector((state: any) => state.dashboard.company)
  const rejectedFields = useSelector((state: any) => state.kyb.rejectedFields || [])

  const { step_3, adress_match, isRejected } = useSelector((state: any) => state.kyb)
  const [newCountry, setNewCountry] = useState<string | null>('')

  const getCountryCodeByName = (countryName: string) => {
    countries.registerLocale(enLocale);
    const countryCode = countries.getAlpha2Code(countryName, 'en');
    return countryCode ? countryCode.toLowerCase() : null;
  }

  const checkState = () => {
    if (company.country) {
      return {
        country: company.country,
        address1: company.address1,
        address2: company.address2,
        city: company.city,
        region: company.region,
        zip: company.zip,
        a_country: company.a_country,
        a_address1: company.a_address1,
        a_address2: company.a_address2,
        a_city: company.a_city,
        a_region: company.a_region,
        a_zip: company.a_zip,
      }
    } else {
      return step_3
    }
  }

  const submitHandler = async (values: any) => {
    try {
      // @ts-ignore
      dispatch(setKyb_3({ company_id, ...values })).then(() => handleStepUp())
      dispatch(setStatus('fourth_tier'))
    } catch (e: any) {
      console.log(e)
    }
  }

  const backHandler = () => {
    // @ts-ignore
    dispatch(getDashboard()).then(() => handleStepDown())
    dispatch(setStatus('second_tier'))
  }


  return (
      <Formik
        initialValues={checkState()}
        onSubmit={submitHandler}
        validationSchema={validationSchema}
        validateOnMount={isRejected}
      >

        {({ errors, touched, values, setFieldValue, handleSubmit }: any) => {
          return (
            <Form style={{ display: 'flex', flexDirection: 'column', gap: '32px', }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', }}>
                <Box sx={{ padding: '0 16px' }}>
                  <LabelUI
                    htmlFor="country"
                    text={t("country")}
                    error={errors.country && touched.country}
                  />
                </Box>
                <Field name="country">
                  {({ field, form }:{field:any,form:any}) => (
                      <MuiCountryInput
                          {...field}
                          value={field.value}
                          onChange={(country) => {
                            form.setFieldValue(field.name, country)
                            dispatch(removeRejectField('country'))
                          }}
                          error={form.touched[field.name] && Boolean(form.errors[field.name]) || rejectedFields.includes(field.name)}
                          helperText={form.touched[field.name] && form.errors[field.name] || rejectedFields.includes(field.name)}
                      />
                  )}
                </Field>
                {errors.country && touched.country && (
                    <Box sx={{ padding: '0 16px' }}>
                      <Typography
                          variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                          {errors.country}
                      </Typography>
                    </Box>
                )}
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', }}>
                <Box sx={{ padding: '0 16px' }}>
                  <LabelUI
                      htmlFor="address1"
                      text={t("address_1")}
                      error={errors.address1 && touched.address1 } />
                </Box>

                <Field
                    name='address1'
                    style={{
                      border: errors.address1 && touched.address1 || rejectedFields.includes('address1')  ? "1px solid #FF6954" : "1px solid #D8D8D8",
                      borderRadius: "10px",
                      padding: "8px 16px",
                      position: "relative",
                      width: "100%",
                      boxSizing: "border-box",
                      height: "50px",
                    }}
                    value={values.address1}
                    onChange={(e: any) => {
                      setFieldValue('address1', e.target.value)
                      dispatch(removeRejectField('address1'))
                    }}
                />
                {errors.address1 && touched.address1 && (
                    <Box sx={{ padding: '0 16px' }}>
                      <Typography
                          variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                        {errors.address1}
                      </Typography>
                    </Box>
                )}
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', }}>
                <Box sx={{ padding: '0 16px' }}>
                  <LabelUI
                      htmlFor="address2"
                      text={t("address_2")}
                      error={errors.address2 && touched.address2 || rejectedFields.includes('address2') } />
                </Box>

                <Field
                    name='address2'
                    style={{
                      border: errors.address2 && touched.address2 || rejectedFields.includes('address2') ? "1px solid #FF6954" : "1px solid #D8D8D8",
                      borderRadius: "10px",
                      padding: "8px 16px",
                      position: "relative",
                      width: "100%",
                      boxSizing: "border-box",
                      height: "50px",
                    }}
                    onChange={(e: any) => {
                      setFieldValue('address2', e.target.value)
                      dispatch(removeRejectField('address2'))
                    }}
                    value={values.address2}
                />
                {errors.address2 && touched.address2 && (
                    <Box sx={{ padding: '0 16px' }}>
                      <Typography
                          variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                        {errors.address2}
                      </Typography>
                    </Box>
                )}
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', }}>
                <Box sx={{ padding: '0 16px' }}>
                  <LabelUI
                      htmlFor="city"
                      text={t("city")}
                      error={errors.city && touched.city || rejectedFields.includes('city') } />
                </Box>

                <Field
                    name='city'
                    style={{
                      border: errors.city && touched.city || rejectedFields.includes('city') ? "1px solid #FF6954" : "1px solid #D8D8D8",
                      borderRadius: "10px",
                      padding: "8px 16px",
                      position: "relative",
                      width: "100%",
                      boxSizing: "border-box",
                      height: "50px",
                    }}
                    onChange={(e: any) => {
                      setFieldValue('city', e.target.value)
                      dispatch(removeRejectField('city'))
                    }}
                    value={values.city}
                />
                {errors.city && touched.city && (
                    <Box sx={{ padding: '0 16px' }}>
                      <Typography
                          variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                        {errors.city}
                      </Typography>
                    </Box>
                )}
              </Box>

              <Box sx={{ display: 'flex', gap: "16px" }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', flexGrow: 1 }}>
                  <Box sx={{ padding: '0 16px' }}>
                    <LabelUI
                        htmlFor="region"
                        text={t("region")}
                        error={errors.region && touched.region || rejectedFields.includes('region')} />
                  </Box>

                  <Field
                      name='region'
                      style={{
                        border: errors.region && touched.region || rejectedFields.includes('region') ? "1px solid #FF6954" : "1px solid #D8D8D8",
                        borderRadius: "10px",
                        padding: "8px 16px",
                        position: "relative",
                        width: "100%",
                        boxSizing: "border-box",
                        height: "50px",
                      }}
                      value={values.region}
                      onChange={(e: any) => {
                        setFieldValue('region', e.target.value)
                        dispatch(removeRejectField('region'))
                      }}
                  />
                  {errors.region && touched.region && (
                      <Box sx={{ padding: '0 16px' }}>
                        <Typography
                            variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                          {errors.region}
                        </Typography>
                      </Box>
                  )}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', flexGrow: 1 }}>
                  <Box sx={{ padding: '0 16px' }}>
                    <LabelUI
                        htmlFor="zip"
                        text={t("zip")}
                        error={errors.zip && touched.zip || rejectedFields.includes('zip')} />
                  </Box>

                  <Field
                      name='zip'
                      style={{
                        border: errors.zip && touched.zip || rejectedFields.includes('zip') ? "1px solid #FF6954" : "1px solid #D8D8D8",
                        borderRadius: "10px",
                        padding: "8px 16px",
                        position: "relative",
                        width: "100%",
                        boxSizing: "border-box",
                        height: "50px",
                      }}
                      onChange={(e: any) => {
                        setFieldValue('zip', e.target.value)
                        dispatch(removeRejectField('zip'))
                      }}
                      value={values.zip}
                  />
                  {errors.zip && touched.zip && (
                      <Box sx={{ padding: '0 16px' }}>
                        <Typography
                            variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                          {errors.zip}
                        </Typography>
                      </Box>
                  )}
                </Box>
              </Box>

              <Box sx={{ display: 'flex', gap: "16px", padding: "0 16px" }}>
                <label className="switch">
                  <input type="checkbox"
                    checked={adress_match}
                    onChange={(e: any) => {
                      dispatch(setAdressMatch(e.target.checked));
                      if (e.target.checked) {
                        setNewCountry(getCountryCodeByName(values.country))
                        setFieldValue('a_country', values.country);
                        setFieldValue('a_address1', values.address1);
                        setFieldValue('a_address2', values.address2);
                        setFieldValue('a_city', values.city);
                        setFieldValue('a_region', values.region);
                        setFieldValue('a_zip', values.zip);
                      }
                    }}
                  />
                  <span className="lbl"></span>
                </label>
                <Typography
                    variant='caption'
                    sx={{
                      color: "#5F5F5F",
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '24px',
                      letterSpacing: '1%'
                    }}>{t("reg_address_point")}</Typography>

              </Box>

              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h6">
                  {t("actual_address")}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', }}>
                <Box sx={{ padding: '0 16px' }}>
                  <LabelUI
                      htmlFor="a_country"
                      text={t("country")}
                      error={errors.a_country && touched.a_country || rejectedFields.includes('a_country')} />
                </Box>
                <Field name="a_country">
                  {({ field, form }:{field:any,form:any}) => (
                      <MuiCountryInput
                          {...field}
                          value={field.value}
                          onChange={(country) => {
                            form.setFieldValue(field.name, country)
                            dispatch(removeRejectField('a_country'))
                          }}
                          error={form.touched[field.name] && Boolean(form.errors[field.name]) || rejectedFields.includes(field.name)}
                          helperText={form.touched[field.name] && form.errors[field.name] || rejectedFields.includes(field.name)}
                          newCountry={newCountry}
                      />
                  )}
                </Field>
                {errors.a_country && touched.a_country && (
                    <Box sx={{ padding: '0 16px' }}>
                      <Typography
                          variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                        {errors.a_country}
                      </Typography>
                    </Box>
                )}
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', }}>
                <Box sx={{ padding: '0 16px' }}>
                  <LabelUI
                      htmlFor="a_address1"
                      text={t("address_1")}
                      error={errors.a_address1 && touched.a_address1 || rejectedFields.includes('a_address1')} />
                </Box>

                <Field
                    name='a_address1'
                    style={{
                      border: errors.a_address1 && touched.a_address1 || rejectedFields.includes('a_address1') ? "1px solid #FF6954" : "1px solid #D8D8D8",
                      borderRadius: "10px",
                      padding: "8px 16px",
                      position: "relative",
                      width: "100%",
                      boxSizing: "border-box",
                      height: "50px",
                    }}
                    value={adress_match ? values.address1 : values.a_address1}
                    onChange={(e: any) => {
                      setFieldValue('a_address1', adress_match ? values.address1 : e.target.value)
                      dispatch(removeRejectField('a_country'))
                    }}
                />
                {errors.a_address1 && touched.a_address1 ? (
                    <Box sx={{ padding: '0 16px' }}>
                      <Typography
                          variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                        {errors.a_address1}
                      </Typography>
                    </Box>

                ) : null}
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', }}>
                <Box sx={{ padding: '0 16px' }}>
                  <LabelUI
                      htmlFor="a_address2"
                      text={t("address_2")}
                      error={errors.a_address2 && touched.a_address2 || rejectedFields.includes('a_address2')} />
                </Box>

                <Field
                    name='a_address2'
                    style={{
                      border: errors.a_address2 && touched.a_address2 || rejectedFields.includes('a_address2') ? "1px solid #FF6954" : "1px solid #D8D8D8",
                      borderRadius: "10px",
                      padding: "8px 16px",
                      position: "relative",
                      width: "100%",
                      boxSizing: "border-box",
                      height: "50px",
                    }}
                    onChange={(e: any) => {
                      setFieldValue('a_address2', adress_match ? values.address2 : e.target.value)
                      dispatch(removeRejectField('a_address2'))
                    }}
                    value={adress_match ? values.address2 : values.a_address2}
                />
                {errors.a_address2 && touched.a_address2 ? (
                    <Box sx={{ padding: '0 16px' }}>
                      <Typography
                          variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                        {errors.a_address2}
                      </Typography>
                    </Box>

                ) : null}
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', }}>
                <Box sx={{ padding: '0 16px' }}>
                  <LabelUI
                      htmlFor="a_city"
                      text={t("city")}
                      error={errors.a_city && touched.a_city || rejectedFields.includes('a_city')} />
                </Box>

                <Field
                    name='a_city'
                    style={{
                      border: errors.a_city && touched.a_city || rejectedFields.includes('a_city') ? "1px solid #FF6954" : "1px solid #D8D8D8",
                      borderRadius: "10px",
                      padding: "8px 16px",
                      position: "relative",
                      width: "100%",
                      boxSizing: "border-box",
                      height: "50px",
                    }}
                    onChange={(e: any) => {
                      setFieldValue('a_city', adress_match ? values.city : e.target.value)
                      dispatch(removeRejectField('a_city'))
                    }}
                    value={adress_match ? values.city : values.a_city}
                />
                {errors.a_city && touched.a_city ? (
                    <Box sx={{ padding: '0 16px' }}>
                      <Typography
                          variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                        {errors.a_city}
                      </Typography>
                    </Box>

                ) : null}
              </Box>

              <Box sx={{ display: 'flex', gap: "16px" }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', flexGrow: 1 }}>
                  <Box sx={{ padding: '0 16px' }}>
                    <LabelUI
                        htmlFor="a_region"
                        text={t("region")}
                        error={errors.a_region && touched.a_region || rejectedFields.includes('a_region')} />
                  </Box>

                  <Field
                      name='a_region'
                      style={{
                        border: errors.a_region && touched.a_region || rejectedFields.includes('a_region') ? "1px solid #FF6954" : "1px solid #D8D8D8",
                        borderRadius: "10px",
                        padding: "8px 16px",
                        position: "relative",
                        width: "100%",
                        boxSizing: "border-box",
                        height: "50px",
                      }}
                      value={adress_match ? values.region : values.a_region}
                      onChange={(e: any) => {
                        setFieldValue('a_region', adress_match ? values.region : e.target.value)
                        dispatch(removeRejectField('a_region'))
                      }}
                  />
                  {errors.a_region && touched.a_region ? (
                      <Box sx={{ padding: '0 16px' }}>
                        <Typography
                            variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                          {errors.a_region}
                        </Typography>
                      </Box>

                  ) : null}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', flexGrow: 1 }}>
                  <Box sx={{ padding: '0 16px' }}>
                    <LabelUI
                        htmlFor="a_zip"
                        text={t("zip")}
                        error={errors.a_zip && touched.a_zip || rejectedFields.includes('a_zip')} />
                  </Box>

                  <Field
                      name='a_zip'
                      style={{
                        border: errors.a_zip && touched.a_zip || rejectedFields.includes('a_zip') ? "1px solid #FF6954" : "1px solid #D8D8D8",
                        borderRadius: "10px",
                        padding: "8px 16px",
                        position: "relative",
                        width: "100%",
                        boxSizing: "border-box",
                        height: "50px",
                      }}
                      onChange={(e: any) => {
                        setFieldValue('a_zip', adress_match ? values.zip : e.target.value)
                        dispatch(removeRejectField('a_zip'))
                      }}
                      value={adress_match ? values.zip : values.a_zip}
                  />
                  {errors.a_zip && touched.a_zip ? (
                      <Box sx={{ padding: '0 16px' }}>
                        <Typography
                            variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                          {errors.a_zip}
                        </Typography>
                      </Box>

                  ) : null}
                </Box>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', gap: "16px" }}>
                <ButtonUI type="outline" text={t("back")} onClick={backHandler} />
                <ButtonUI type="default" text="Next" onClick={handleSubmit} />
              </Box>
            </Form>
          )
        }}
      </Formik >
  )
}

export default Step3