import {Box} from "@mui/material"
import NoData from "../NoData/NoData"
import {checkNoDataDescription} from "../../../../helpers"
import Logo from "./Logo/Logo"
import Verification from "./Verification/Verification"
import {useTranslation} from "react-i18next";
import { useWindowSize } from "@uidotdev/usehooks"
interface Props {
  setOpenModal: (openModal: boolean) => void;
  hasCompany: boolean
}

const Company = ({ setOpenModal, hasCompany }: Props) => {
  const path = window.location.pathname
  const {t} = useTranslation()
const {width} =useWindowSize();
const isMobile = width && width <= 1024;
  return (
    <>
      {
        hasCompany
          ? (
            <Box sx={{ display: "flex", gap: "32px" }}>
              {!isMobile && <Logo />}
              <Verification />
            </Box>
          )
          : (
            <Box sx={{
              minHeight: "336px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FFF",
              borderRadius: "16px",
            }}>
              <NoData
                onClick={() => setOpenModal(true)}
                buttonText={t("create_company")}
                description={checkNoDataDescription(path)} />
            </Box>
          )

      }
    </>

  )
}

export default Company