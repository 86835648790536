import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import { Provider } from 'react-redux'
import { store } from './app/store'

import './i18n';
import {GoogleOAuthProvider} from "@react-oauth/google";
import {CLIENT_ID} from "./api/Service";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={CLIENT_ID as string}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </GoogleOAuthProvider>
  </Provider>
);
