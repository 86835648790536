import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Products from "../../api/repositories/products";
import {handleApiError, handleApiErrorSaveProduct} from "../../helpers/handleApiError";

const initialState = {
  productData: {
  },
  statisticsData: {
    'Total': 0,
    'Total Verified': 0,
    'Total Scanned': 0,
  },
  analyticsData: {
    data: {},
    scannedData: [],
    verifiedData: []
  },
  productId : '',
  errorMessage: '',
  isLoading: false,
};

export const createProduct = createAsyncThunk(
  "products/createProduct",
  async (payload) => {
    try {
      const response = await Products.create_product(payload);
      return response.data;
    } catch (error) {
      handleApiError(error)
    }
  },
);
export const getProduct = createAsyncThunk(
    "products/getProduct",
    async (payload) => {
      try{
        const response = await Products.get_product(payload)
        return response.data;
      } catch (error){
        handleApiError(error)
      }
    }
)
export const deleteProduct = createAsyncThunk(
    "products/deleteProduct",
    async (payload) => {
      try {
        const response = await Products.delete_product(payload);
        return response.data;
      } catch (error) {
        handleApiError(error)
      }
    },
);

export const saveProduct = createAsyncThunk(
    "products/saveProduct",
    async (payload) => {
      try {
        const response = await Products.save_product(payload);
        return response.data;
      } catch (error) {
        handleApiErrorSaveProduct(error)
        return error
      }
    },
);

export const publishProduct = createAsyncThunk(
    "products/publishProduct",
    async (payload) => {
      try {
        const response = await Products.publish_product(payload);
        return response.data;
      } catch (error) {
        handleApiErrorSaveProduct(error)
      }
    },
);

export const read_feedback_all = createAsyncThunk(
  "products/readFeedbackAll",
  async (payload) => {
    try {
      const response = await Products.read_feedback_all(payload);
      return response.data;
    } catch (error) {
      handleApiError(error)
    }
  },
);
export const read_feedback = createAsyncThunk(
  "products/readFeedback",
  async (payload) => {
    try {
      const response = await Products.read_feedback(payload);
      return response.data;
    } catch (error) {
      handleApiError(error)
    }
  },
);

export const changeFeedbackRule = createAsyncThunk(
    "products/changeFeedbackRule",
    async (payload) => {
      try {
        const response = await Products.change_feedback_rule(payload);
        return response.data;
      } catch (error) {
        handleApiErrorSaveProduct(error)
      }
    },
);

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createProduct.fulfilled, (state) => {
        state.isLoading = false;
    });
    builder
        .addCase(saveProduct.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(saveProduct.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          if(payload.response) {
            state.errorMessage = payload.response.data.detail.Message
          }
        })
    builder
        .addCase(publishProduct.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(publishProduct.fulfilled, (state) => {
          state.isLoading = false;
        })
    builder
        .addCase(changeFeedbackRule.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(changeFeedbackRule.fulfilled, (state) => {
          state.isLoading = false;
        })
    builder
        .addCase(getProduct.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getProduct.fulfilled, (state,{payload}) => {
          state.isLoading = false;

          if(payload) {
            state.productData = payload.Event
            state.analyticsData.data = payload.Event.analytics
            state.analyticsData.data.country_scans = payload.Event.country_scans

            if ( state.analyticsData.data.length > 0 ) {
              const today = new Date();
              const startDate = new Date(new Date().setDate(today.getDate() - 365));
              const endDate = today;

              state.analyticsData.scannedData = [];
              state.analyticsData.verifiedData = [];

              const dateMap= {};

              state.analyticsData.data.forEach((item) => {
                const formattedDate = new Date(item.date).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit' });
                dateMap[formattedDate] = {
                  scanned: item.scanned,
                  verified: item.verified
                };
              });

              for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
                const formattedDate = date.toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit' });
                state.analyticsData.scannedData.push({
                  name: formattedDate,
                  uv: dateMap[formattedDate] ? dateMap[formattedDate].scanned : 0
                });
                state.analyticsData.verifiedData.push({
                  name: formattedDate,
                  uv: dateMap[formattedDate] ? dateMap[formattedDate].verified : 0
                });
              }

              state.analyticsData.scannedData = JSON.parse(JSON.stringify(state.analyticsData.scannedData));
              state.analyticsData.verifiedData = JSON.parse(JSON.stringify(state.analyticsData.verifiedData));
            } else{
              state.analyticsData.scannedData = [];
              state.analyticsData.verifiedData = [];
            }

            Object.assign(state.statisticsData, {
              'Total': state.productData.total,
              'Total Scanned': state.productData.total_scanned,
              'Total Verified': state.productData.total_verified,
            });
          }
        })
  },
});

export default productsSlice.reducer;
