import { Box, Typography } from "@mui/material";
import IconUI from "../../../../ui/icon";
import { ButtonUI } from "../../../../ui";
import React, { useState } from "react";
import {
  kybDeleteLogo,
  kybUploadLogo,
  setKybStatus,
} from "../../../../../app/slices/kyb";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../../../../api/Service";

const Logo = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const logo = useSelector((state: any) => state.kyb.logo_company);
  const [formatError, setFormatError] = useState(false);

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const file = event.target.files?.[0];

      if (file) {
        const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

        if (!allowedTypes.includes(file.type)) {
          setFormatError(true);
          event.target.value = "";
          return;
        } else {
          // @ts-ignore
          await dispatch(kybUploadLogo(file))
            // @ts-ignore
            .finally(() => dispatch(setKybStatus()));

          setFormatError(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleImageDelete = async () => {
    try {
      // @ts-ignore
      await dispatch(kybDeleteLogo())
        // @ts-ignore
        .finally(() => dispatch(setKybStatus()));
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = () => {
    const inputElement = document.getElementById(
      "logoInput"
    ) as HTMLInputElement;

    if (inputElement) {
      inputElement.value = "";
    }

    inputElement?.click();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        flexShrink:"0",
        flexBasis:"344px",
        maxWidth:"344px",
        width: '100%',
        '@media(max-width:1200px)': {
          flexBasis:"280px",
          maxWidth:"280px",
        }

      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: "#2D2D2D",
          padding: "0 32px",
          "@media(max-width:1024px)": {
            display: "none",
          },
        }}
      >
        {t("logo")}
      </Typography>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          borderRadius: "16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "#fff",
          boxShadow: "0px 0px 16px 0px #E5E5E5",
          padding: "32px",
          boxSizing: "border-box",
          gap: "32px",
        }}
      >
        <Box
          sx={{
            width: "192px",
            height: "192px",
            borderRadius: "50%",
            border: "0px solid #D8D8D8",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {logo && (
            <Box
              sx={{
                position: "absolute",
                top: "16px",
                right: "16px",
                cursor: "pointer",
              }}
            >
              <IconUI name="cross" onClick={handleImageDelete} />
            </Box>
          )}

          {logo ? (
            <img src={`${BASE_URL}/${logo}`} alt="Logo" style={{maxWidth: '192px', maxHeight: '192px'}}/>
          ) : (
            <IconUI name="media" size={80} />
          )}
        </Box>

        <ButtonUI type="default" text="Upload" onClick={handleClick} />
        <input
          type="file"
          id="logoInput"
          accept=".png,.jpg,.jpeg"
          style={{ display: "none" }}
          onChange={handleImageUpload}
        />

        {formatError && (
          <Box sx={{ padding: "0 16px" }}>
            <Typography
              variant="caption"
              sx={{ color: "#FF6954", fontSize: "14px" }}
            >
              Invalid file type. Please upload a file with a .png, .jpg, or
              .jpeg extension.
            </Typography>
          </Box>
        )}
        <Typography
          variant="caption"
          sx={{ color: "#5F5F5F", fontSize: "14px" }}
        >
          {t("logo_info")}
        </Typography>
      </Box>
    </Box>
  );
};

export default Logo;
