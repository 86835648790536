const styles = {
  container: {
    background: "#FFFFFF",
    padding: "0 32px",
    boxShadow: "0px 0px 16px 0px #E5E5E5",
    borderRadius: "16px",
    '@media (max-width: 1024px)': {
      padding: "0 16px",
    }
  },
  item: {
    padding: '32px 0',
    borderBottom: "solid 1px #F5F5F5"
  },
  text: {
    fontSize: "14px",
    color: "#2D2D2D"
  },
  text_green: {
    color: '#00C06A',
    fontSize: "14px"
  }
} as const

export default styles