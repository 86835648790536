import { useSelector } from 'react-redux';
import {useMemo} from 'react';

type DataType = 'scanned' | 'verified';
type PlaceType = 'products' | 'dashboard';


const useAnalyticsData = (
    typeValue: DataType,
    place: PlaceType,
    forMap: boolean = false
) => {
  const productAnalyticsData = useSelector((state: any) => state.products.analyticsData);

  // Селекторы для dashboard
  const dashboardAnalyticsData = useSelector((state: any) => state.dashboard.analyticsData);

  // Логика выбора данных
  const selectedData = useMemo(() => {
    const analyticsData = place === 'dashboard' ? dashboardAnalyticsData : productAnalyticsData;

    if (forMap) {
      return typeValue === 'verified'
          ? analyticsData.data.country_scans.verified
          : analyticsData.data.country_scans.scanned;
    } else {
      return typeValue === 'verified' ? analyticsData.verifiedData : analyticsData.scannedData;
    }
  }, [typeValue, place, forMap, productAnalyticsData, dashboardAnalyticsData]);

  return selectedData;
};

export default useAnalyticsData;