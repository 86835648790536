import styles from "../../styles";
import {Box, Typography} from "@mui/material";
import {AnalyticsDataType} from "../../../../../../shared/types/instances";
import {useTranslation} from "react-i18next";

interface Props {
  data: AnalyticsDataType[],
  analyticsTime: number,
  type: 'scanned' | 'verified',
}

export const AccordionGraph = ({data, analyticsTime, type}: Props) => {
  const {t} = useTranslation()

  return (
      <Box sx={styles.flex_center_box}>
        <Box
            sx={{
              height: "24px",
              backgroundColor: type === 'scanned' ? "#FF8800" : 'rgb(0, 192, 106)',
              width: "8px",
              borderRadius: "32px",
            }}>
        </Box>
        <Box>
          <Typography variant="h6" sx={styles.title}>
            {data
                .slice(data.length - analyticsTime)
                .reduce((acc:number, item:any) => acc + item.uv, 0)
            }
          </Typography>
          <Typography variant="caption" sx={styles.subtitle}>
            {type === 'scanned'
                ? t("scanned")
                : t("verified")
            }
          </Typography>
        </Box>
      </Box>
  )
}