export const ITEMS_PER_PAGE = 10

export const notificationText: Record<number, string> = {
  0: "Account successfully registered. Welcome to IQtag!",
  1: "Your KYB is under review. We’ll notify you once it’s processed",
  2: "Your KYB verification is approved. Thank you for completing the process!",
  3: "Your KYB verification failed. Please check your details and try again.",
  4: "Your QR codes are successfully purchased and ready to use!",
  5: "Failed to complete QR code purchase. Please contact support.",
  6: "Your profile has been successfully updated.",
  7: "Your withdrawal request has been submitted successfully. It will be processed shortly.",
  8: "Your withdrawal request is successfully processed. Funds will arrive shortly.",
  9: "Failed to process withdrawal. Please check your details and try again.",
  10: "Your password has been successfully updated.",
  11: "Your invoice is pending payment. Once completed, your services will be activated.",
};