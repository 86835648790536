import React from "react";
import {Navigate} from "react-router-dom";

const ProtectedRoute = ({ children }: { children: React.ReactNode}) => {
  const isAuth = localStorage.getItem('access_token')

  if (isAuth === null) {
    return <Navigate to="/sign-in" replace />;
  }

  return children;
};

export default ProtectedRoute;