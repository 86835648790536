const styles = {
  filterWrapper: (sortable?: boolean) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    userSelect: 'none',
    cursor: sortable ? 'pointer' : 'default',
  }),
  filterLabel: {
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'left',
    color: '#5F5F5F',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  selector: { fontSize: "14px", fontWeight: 400, textAlign: 'right', color: "#5F5F5F" },
  customSelector: {
    boxShadow: "none",
    color: "#5F5F5F",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
      border: 0,
    },
    '& .MuiSelect-select': {
      padding: "0 40px 0 0 !important"
    }
  },
} as const;

export default styles
