import { SortConfig } from '../types/instances'

export const getSortIcon = (key: string, sortConfig: SortConfig) => {
  if (sortConfig.key === key) {
    if (sortConfig.order === 'asc') {
      return 'sort-asc'
    } else if (sortConfig.order === 'desc') {
      return 'sort-desc'
    }
  }

  return 'sort-none'
}
