import {Box, Grid2, Typography} from '@mui/material'
import IconUI from '../../../components/ui/icon';
import {getSortIcon} from '../../../shared/utils/get-sort-icon';
import {SortConfig, SortOrder} from '../../../shared/types/instances';
import styles from './styles'
import {useTranslation} from "react-i18next";

interface FilterState {
  date: { value: SortOrder; sortable: boolean };
  qrcode: { value: SortOrder; sortable: boolean };
  verified: { value: SortOrder; sortable: boolean };
  scanned: { value: SortOrder; sortable: boolean };
  feedback: { value: SortOrder; sortable: boolean };
}

interface ProductFiltersProps {
  filter: FilterState;
  sortConfig: SortConfig;
  onChangeSort: (field: keyof FilterState) => void;
}

const ProductFilters = ({filter, sortConfig, onChangeSort}: ProductFiltersProps) => {
  const {t} = useTranslation()

  return (
      <Grid2 container sx={{
        '@media (max-width: 1024px)': {
          display: 'none'
        },
        display: 'flex',
        flexWrap: 'wrap',
        gap: '32px',
        padding: '0 0 32px 0'
      }}>
        <Grid2 size={5.2} padding={"0 0 0 160px"} sx={{display: 'flex', alignItems: 'flex-start'}}>
          <Box sx={styles.filterWrapper(true)} onClick={() => onChangeSort('date')}>
            <Typography variant="caption" sx={styles.filterLabel}>
              {t("date")}
            </Typography>
            {filter.date.sortable && (
                <IconUI name={getSortIcon('date', sortConfig)} size={16}/>
            )}
          </Box>
        </Grid2>

        <Grid2 size={1.1} sx={{display: 'flex', alignItems: 'flex-start'}}>
          <Box sx={styles.filterWrapper(true)} onClick={() => onChangeSort('qrcode')}>
            <Typography variant="caption" sx={styles.filterLabel}>
              QR Code
            </Typography>
            {filter.qrcode.sortable && (
                <IconUI name={getSortIcon('qrcodes', sortConfig)} size={16}/>
            )}
          </Box>
        </Grid2>

        <Grid2 size={2} sx={{display: 'flex', alignItems: 'flex-start'}}>
          <Box sx={styles.filterWrapper(true)} onClick={() => onChangeSort('verified')}>
            <Typography variant="caption" sx={styles.filterLabel}>
              {t("verified")}
            </Typography>
            {filter.verified.sortable && (
                <IconUI name={getSortIcon('verified', sortConfig)} size={16}/>
            )}
          </Box>
        </Grid2>

        <Grid2 size={1} sx={{display: 'flex', alignItems: 'flex-start'}}>
          <Box sx={styles.filterWrapper(true)} onClick={() => onChangeSort('scanned')}>
            <Typography variant="caption" sx={styles.filterLabel}>
              {t("scanned")}
            </Typography>
            {filter.scanned.sortable && (
                <IconUI name={getSortIcon('scanned', sortConfig)} size={16}/>
            )}
          </Box>
        </Grid2>

        <Grid2 size={1.2} sx={{display: 'flex', alignItems: 'flex-start'}}>
          <Box sx={styles.filterWrapper(true)} onClick={() => onChangeSort('feedback')}>
            <Typography variant="caption" sx={styles.filterLabel}>
              {t("feedback")}
            </Typography>
            {filter.feedback.sortable && (
                <IconUI name={getSortIcon('feedbacks', sortConfig)} size={16}/>
            )}
          </Box>
        </Grid2>
      </Grid2>
  );
};

export default ProductFilters
