const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    paddingBottom: "32px",
    border: "none",
    background: "transparent",
    pointerEvent: 'none',
    margin: "0 !important",
    "&::before": {
      content: "none"
    },
    "& h3 > *": {
      margin: "0 !important"
    },
    '&:hover:not(.Mui-disabled)': {
      cursor: 'default',
    },
    "& .MuiAccordionSummary-content": {
      cursor: 'default',
    },
  },


  boxComplete: {
    width: "40px",
    height: "32px",
    padding: "4px 16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000",
    borderRadius: "32px",
    boxSizing: "border-box",
    color: "#FFF",

  },

  boxUncomplete: {
    width: "40px",
    height: "32px",
    padding: "4px 16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
    borderRadius: "32px",
    boxSizing: "border-box",
    color: "#000",
    border: "1px solid #000",

  },

  boxRejectedComplete: {
    width: "40px",
    height: "32px",
    padding: "4px 16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#00C06A",
    borderRadius: "32px",
    boxSizing: "border-box",
    color: "#fff",
    border: "1px solid #00C06A",

  },

  boxRejectedUncomplete: {
    width: "40px",
    height: "32px",
    padding: "4px 16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FF6954",
    borderRadius: "32px",
    boxSizing: "border-box",
    color: "#fff",
    border: "1px solid #FF6954",
 
  },

  header: {
    margin: "0 !important",
    minHeight: "32px !important",
    "& .MuiAccordionSummary-content": {
      margin: "0 !important",
    },
  },
  expand: {
    background: '#FFFFFF',
    borderRadius: '16px',
    padding: "0",
    display: 'flex',
    flexDirection: 'column',
    boxShadow: "0px 0px 16px 0px #E5E5E5",
  },
  rejectBlock:{
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  info: {
    backgroundColor: "#50A2FF",
    borderRadius: "10px",
    padding: "10px 16px",
    display: "flex",
    gap: "16px",
    alignItems: "center"
  },
  infoRejected: {
    backgroundColor: "#FFBC50"
  },
  title: {
    color: "#FFF",
    fontWeight: "500"
  },
  description: {
    letterSpacing: "0.01em",
    lineHeight: '171%',
    fontSize: '14px',
    fontWeight: '400',
    color: "#5F5F5F",
    // marginBottom: '-10px',
  }
}

export default styles