enum noDataDescription {
  COMPANY = "Once you have created a company, you will be able to list the company's products </br> and issue verification QR codes for them.",
  PRODUCTS = "To add your first product, </br> create a company.",
  DASHBOARD = "There is no data to display at this time. </br> Try back later.",
  BILLS = "Once you have created a company, you will be able to list the company's products </br>and issue verification QR codes for them.",
  REFERRAL = "Invite users to see the story here"
}

export const checkNoDataDescription = (path: string) => {
  if (path.includes('products')) {
    return noDataDescription.PRODUCTS;
  } else if (path.includes('dashboard')) {
    return noDataDescription.DASHBOARD;
  } else if (path.includes('company')) {
    return noDataDescription.COMPANY;
  } else if (path.includes('bills')) {
    return noDataDescription.BILLS;
  } else if (path.includes('referal')) {
    return noDataDescription.REFERRAL;
  } else if (path.includes('notifications')) {
    return 'You have no notifications yet';
  }
};
