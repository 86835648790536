import { Box, Button, Typography } from "@mui/material"
import { ButtonUI } from "../../../../../../ui"
import IconUI from "../../../../../../ui/icon"
import React, {useEffect, useState} from "react"
import axios from "axios";
import styles from "./styles"
import {useDispatch, useSelector} from "react-redux"
import {setIsRejected, setStatus} from "../../../../../../../app/slices/kyb"
import { getDashboard } from "../../../../../../../app/slices/dashboard";
import {useDropzone} from "react-dropzone";
import {StepProps} from "../../../../../../../shared/types/instances";
import {useTranslation} from "react-i18next";
import {BASE_URL} from "../../../../../../../api/Service";
import {toast} from "react-toastify";

interface FileDropzoneProps {
  onDrop: (file: File) => void;
  fileLoad: File | null;
  fileKey: string;
}

const FileDropzone = ({ onDrop, fileLoad, fileKey }:FileDropzoneProps) => {
  const rejectedFields = useSelector((state: any) => state.kyb.rejectedFields || [])
  const [file, setFile] = useState<File | null>(fileLoad);

  const handleDrop = (acceptedFiles: File[]) => {
    setFile(acceptedFiles[0] || null);
    onDrop(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, open} = useDropzone({
    onDrop: handleDrop,
    accept: {'application/pdf': ['.pdf']},
    noClick: true,
  });

  const handleRemoveFile = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setFile(null);
  };

  useEffect(() => {
    setFile(fileLoad)
  },[fileLoad])

  return (
      <Box
          sx={{
            ...styles.form,
            border: rejectedFields.includes(fileKey) ? '1px solid #FF6954' : '1px solid #D8D8D8'
          }}
          {...getRootProps()}
          onClick={(event) => event.preventDefault()}
      >
        <Box sx={styles.content}>
          <IconUI name={file ? "file" : "upload"} size={88} />
          {file &&
              <Typography
                variant="subtitle2"
                sx={styles.fileName}
              >
                {file.name}
              </Typography>
          }
        </Box>

        {file &&
            <Box
                sx={styles.cross}
                onClick={(event: React.MouseEvent) => handleRemoveFile(event)}
            >
              <IconUI name="cross" size={24} />
            </Box>
        }
        <Button
            component="label"
            sx={styles.button}
            tabIndex={-1}
            onClick={open}
        >
          Upload
          <input
              type={'file'}
              multiple
              style={{
                clip: 'rect(0 0 0 0)',
                clipPath: 'inset(50%)',
                height: 1,
                overflow: 'hidden',
                position: 'absolute',
                bottom: 0,
                left: 0,
                whiteSpace: 'nowrap',
                width: 1,
              }}
              {...getInputProps()}
              key={file ? file.name : 'reset'}
          />
        </Button>
      </Box>
  );
};

const Step4 = ({ handleStepUp, handleStepDown }: StepProps) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const company_id = useSelector((state: any) => state.dashboard.dashboard.Current_Company)
  const company = useSelector((state: any) => state.dashboard.company)

  const access_token = localStorage.getItem('access_token')
  const [isLoading, setIsLoading] = useState(false);

  const [files, setFiles] = useState<{ file1: File | null; file2: File | null; file3: File | null }>({
    file1: null,
    file2: null,
    file3: null,
  });

  const handleFileChange = (key: string) => (file: File) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      [key]: file,
    }));
  };

  const handlePDFUpload = async () => {
    setIsLoading(true)

    if (!files.file1 || !files.file2 || !files.file3 || !company_id) {
      toast.error("All three files are required");
      return;
    }

    const formData = new FormData();
    formData.append('company_id', company_id);
    if (files.file1) formData.append('files', files.file1);
    formData.append('files', files.file2);
    formData.append('files', files.file3);

    try {
      const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL_RU}/api/kyb/upload_pdfs`,
          formData,
          {
            headers: {
              'Authorization': `Bearer ${access_token}`,
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then((response) => {
        if (response.status === 200) {
          handleStepUp()
          dispatch(setIsRejected(false))
          dispatch(setStatus('pending'))
        }
      })
    } catch (error) {
      console.error('Error uploading PDFs:', error);
    } finally {
      setIsLoading(false)
    }
  };

  const backHandler = () => {
    // @ts-ignore
    dispatch(getDashboard()).then(() => handleStepDown())
    dispatch(setStatus('third_tier'))
  }

  const checkState = () => {
    if (company.pdf_file_paths?.length > 0) {
      return {
        file1: company.pdf_file_paths[0],
        file2: company.pdf_file_paths[1],
        file3: company.pdf_file_paths[2],
      }
    }
    return {}
  }

  const fetchFiles = async () => {
    const stateFiles = checkState();
    const fileEntries = Object.entries(stateFiles);

    const filePromises = fileEntries.map(async ([key, path]) => {
      try {
        if(!path) return

        const response = await fetch(`${BASE_URL}/${path}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch ${path}`);
        }
        const blob = await response.blob();
        const file = new File([blob], path.split('/').pop() || 'file.pdf', {
          type: 'application/pdf',
        });

        setFiles((prevFiles) => ({
          ...prevFiles,
          [key]: file,
        }));
      } catch (error) {
        console.error(`Error fetching file ${key}:`, error);
      }
    });

    await Promise.all(filePromises);
  };

  useEffect(() => {
    fetchFiles()
  }, [company.pdf_file_paths])

  return (
      <>
        <Box sx={styles.container}>
          <Typography variant="subtitle2" sx={{ color: "#5F5F5F" }}>
            1. {t("extract_1")}
          </Typography>
          <FileDropzone
              onDrop={handleFileChange('file1')}
              fileLoad={files.file1}
              fileKey={"file1"}
          />
        </Box>
        <Box sx={styles.container}>
          <Typography variant="subtitle2" sx={{ color: "#5F5F5F" }}>
            2{t("extract_2")}
          </Typography>
          <FileDropzone
              onDrop={handleFileChange('file2')}
              fileLoad={files.file2}
              fileKey={"file2"}
          />
        </Box>

        <Box sx={styles.container}>
          <Typography variant="subtitle2" sx={{ color: "#5F5F5F", textAlign: 'left' }}>
            3. {t("identity")}
          </Typography>
          <FileDropzone
              onDrop={handleFileChange('file3')}
              fileLoad={files.file3}
              fileKey={"file3"}
          />
        </Box>

        <Box sx={styles.buttons}>
          <ButtonUI type="outline" text={t("back")} onClick={backHandler} />
          {isLoading ?
              <ButtonUI
                  type="icon"
                  icon="miniloader"
                  mobile
                  text=""
                  iconStyles={{zIndex: '1', top: '22%', left: '37%'}}
                  width={'100px'}
                  iconSize={30}
              />
              :
              <ButtonUI
                  type="default"
                  text="Send"
                  onClick={handlePDFUpload}
                  disabled={!files.file1 || !files.file2 || !files.file3}
              />
          }

        </Box>
      </>
  )
}

export default Step4