import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {handleApiError} from "../../helpers/handleApiError";
import Referral from "../../api/repositories/referral";

const initialState = {
  referralLink: '',
  historyData: [],
  statisticsData: {},
  isLoading: false,
};

export const getReferralData = createAsyncThunk(
    "referral/getReferralData",
    async () => {
      try {
        const response = await Referral.get_referal_data();
        return response.data;

      } catch (error) {
        handleApiError(error)
      }
    },
);

export const getHistoryData = createAsyncThunk(
    "referral/getHistoryData",
    async () => {
      try {
        const response = await Referral.get_history_data();
        return response.data;

      } catch (error) {
        handleApiError(error)
      }
    },
);

const referralSlice = createSlice({
  name: "referral",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(getReferralData.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getReferralData.fulfilled, (state, { payload }) => {
          state.isLoading = false;

          if(payload) {
            state.referralLink = payload.Event.ref_link
            state.statisticsData = {
              registered: payload.Event.registered,
              verified: payload.Event.verified,
              reward: payload.Event.reward
            };
          }
        })
    builder
        .addCase(getHistoryData.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getHistoryData.fulfilled, (state, { payload }) => {
          state.isLoading = false
          if(payload)
            state.historyData = payload.Event.history
        });
  },
});

export default referralSlice.reducer;