export const data = [
  {
    title: 'X',
    link: 'https://x.com/intent/post?text='
  },
  {
    title: 'Facebook',
    link: 'https://www.facebook.com/sharer/sharer.php?u='
  },
  {
    title: 'Telegram',
    link: 'https://t.me/share/url?url='
  }
]