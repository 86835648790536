import {Box, Typography} from "@mui/material"
import {InputUI} from "../../../../ui"
import IconUI from "../../../../ui/icon";
import styles from "./styles";
import {useTranslation} from "react-i18next";

interface Props {
  type: string;
  data: any;
}
const FormVariant = ({type, data} : Props) => {
  const {t} = useTranslation()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '32px' }}>
      {
        type === 'first_tier' && (
          <>
              <InputUI label={t("company_name")} icon='lock'  value={data.company_name} icon_direction="right"  disabled/>
              <InputUI label={t("kyb_date")} icon='lock' value={data.date_of_incorp} icon_direction="right" disabled />
              <InputUI label={t("kyb_reg_num")} icon='lock' value={data.company_reg_number} icon_direction="right"  disabled/>
              <InputUI label={t("kyb_website")} icon={'lock'} value={data.companys_website} icon_direction="right" disabled/>
              <InputUI label={t("kyb_emp")} icon='lock' value={data.employees_have} icon_direction="right" disabled/>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <InputUI multiline lines={3} label={t("kyb_descr")} icon='lock' value={data.business_description} icon_direction="right" disabled />
              </Box>
          </>
        )
      }
      {
        type === 'second_tier' && (
        <>
          <InputUI label="Email" icon='lock' value={data.email} icon_direction="right" disabled />
          <InputUI label={t("phone")}  icon='lock' value={data.phone} icon_direction="right"  disabled/>
          <InputUI label={t("kyb_prim_contact")} icon='lock' value={data.primary_contact_name} icon_direction="right" disabled/>
          <InputUI label={t("position")} icon='lock' value={data.position} icon_direction="right"  disabled/>
        </>
      )
    }
    {
      type === 'third_tier' && (
        <>
          <InputUI label={t("country")} icon='lock'  value={data.country} icon_direction="right" disabled/>
          <InputUI label={t("address_1")} icon='lock' value={data.address1} icon_direction="right" disabled/>
          <InputUI label={t("address_2")} icon='lock' value={data.address2} icon_direction="right" disabled/>
          <InputUI label={t("city")} icon='lock' value={data.city} icon_direction="right" disabled/>
          <Box sx={{ display: 'flex', gap: "16px" }}>
            <InputUI label={t("region")} icon='lock' value={data.region} icon_direction="right" disabled />
            <InputUI label={t("zip")} icon='lock' value={data.zip} icon_direction="right" disabled/>
          </Box>
          <Box sx={{ display: 'flex', gap: "16px", padding: "0 16px" }}>
            <label className="switch">
              <input type="checkbox" checked />
              <span className="lbl"></span>
            </label>
            <Typography
              variant='caption'
              sx={{
                color: "#5F5F5F",
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '24px',
                letterSpacing: '1%'
              }}>{t("reg_address_point")}</Typography>

          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h6">
              {t("actual_address")}
            </Typography>
          </Box>

          <InputUI label={t("country")} icon='lock' value={data.a_country} icon_direction="right" disabled />
          <InputUI label="Adress 1" icon='lock'  value={data.a_address1} icon_direction="right" disabled />
          <InputUI label="Adress 2" icon='lock' value={data.a_address2} icon_direction="right" disabled/>
          <InputUI label={t("city")} icon='lock' value={data.a_city} icon_direction="right" disabled/>

          <Box sx={{ display: 'flex', gap: "16px" }}>
            <InputUI label={t("region")} icon='lock' value={data.a_region} icon_direction="right" disabled/>
            <InputUI label={t("zip")} icon='lock' value={data.a_zip} icon_direction="right" disabled/>
          </Box>
        </>
      )
    }
    {
      type === 'fourth_tier' && (
        <Box sx={styles.file_container}>
          <Typography variant="subtitle2" sx={{ color: "#5F5F5F" }}>
            1. {t("extract_1")}
          </Typography>
          <Box sx={styles.file_form}>
            <Box sx={styles.file_content}>
              <IconUI name="file" size={88} />
              <Typography
                  variant="subtitle2"
                  sx={styles.file_fileName}>{data.pdf_file_paths[0]}</Typography>
            </Box>

            {
              <Box sx={styles.file_cross}>
                <IconUI name="lock" size={24} />
              </Box>
            }
          </Box>
          <Typography variant="subtitle2" sx={{ color: "#5F5F5F", maxWidth:'600px'}}>
            2. {t("extract_2")}
          </Typography>
          <Box sx={styles.file_form}>
            <Box sx={styles.file_content}>
              <IconUI name="file" size={88} />
              <Typography
                  variant="subtitle2"
                  sx={styles.file_fileName}>{data.pdf_file_paths[1]}</Typography>
            </Box>

            {
              <Box sx={styles.file_cross}>
                <IconUI name="lock" size={24} />
              </Box>
            }
          </Box>
          <Typography variant="subtitle2" sx={{ color: "#5F5F5F" }}>
            3. {t("identity")}
          </Typography>
          <Box sx={styles.file_form}>
            <Box sx={styles.file_content}>
              <IconUI name="file" size={88} />
              <Typography
                  variant="subtitle2"
                  sx={styles.file_fileName}>{data.pdf_file_paths[2]}</Typography>
            </Box>

            {
              <Box sx={styles.file_cross}>
                <IconUI name="lock" size={24} />
              </Box>
            }
          </Box>
        </Box>
      )
    }

    </Box>
  )
}
export default FormVariant