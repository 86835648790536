const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    paddingBottom: "32px",
    border: "none",
    background: "transparent",
    pointerEvent: 'none',
    margin: "0 !important",
    "& .MuiAccordionDetails-root": {
      padding: 0
    },
    "&::before": {
      content: "none"
    },
    "& h3 > *": {
      margin: "0 !important"
    },
    "& .MuiAccordionSummary-content": {
      margin: "0 !important",
      cursor: 'default',
    },
    '&:hover:not(.Mui-disabled)': {
      cursor: 'default',
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 32px',
    margin: "0 !important",
    cursor: 'default'
  },

  headerContent: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    minHeight: "64px",

    '@media (max-width: 1024px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  }
} as const

export default styles

