import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import styles from "./styles";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconUI from "../../../ui/icon";
import { useSelector } from "react-redux";
import { useWindowSize } from "@uidotdev/usehooks";
import { useTranslation } from "react-i18next";
import { ObjectsData } from "../../../../shared/types/instances";
import { StatisticsBar } from "../../StatisticsBar/StatisticsBar";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { styled } from "@mui/system";
interface Props {
  variant?: "accordion" | "default";
  statisticsDataReferral?: ObjectsData;
}
interface AnimatedTooltipProps {
  title: string;
  children: React.ReactNode;
}

const AnimatedTooltip = styled(({ className, ...props }: any) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    enterDelay={500}
    leaveDelay={200}
    arrow
    placement="top"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "#000000",
    boxShadow: "0px 4px 8px rgba(59, 58, 58, 0.2)",
    fontSize: "0.9rem",
    fontWeight: 300,
    opacity: 0,
    padding: 16,
    borderRadius: "16px",
    transition: "opacity 0.3s ease-in-out",
  },
  [`& .${tooltipClasses.popper}`]: {
    "&[data-popper-placement*='top'] .MuiTooltip-arrow": {
      color: "#ffffff",
      transform: "rotate(180deg)",
    },
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#ffffff",
  },
  "&[data-state='entering'], &[data-state='entered']": {
    [`& .${tooltipClasses.tooltip}`]: {
      opacity: 1,
    },
  },
}));

const Statistics = ({
  variant = "default",
  statisticsDataReferral = {},
}: Props) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isMobile = width && width < 1024;
  const [isOpen, setIsOpen] = useState(true);

  const statisticsDataProduct: ObjectsData = useSelector(
    (state: any) => state.products.statisticsData
  );
  const statisticsText = [t("total"), t("total_verified"), t("total_scanned")];

  const helpStatisticsAccordionText = [
    t("statistics_text.registered"),
    t("statistics_text.verified"),
    t("statistics_text.reward"),
  ];
  const helpStatisticText = [
    "Total QR codes",
    "Total product authenticity verified",
    "Total number of open QR codes scanned",
  ];

  return (
    <>
      {variant === "accordion" ? (
        <Accordion variant="outlined" sx={styles.container} expanded={isOpen}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                onClick={() => setIsOpen(!isOpen)}
                style={{ cursor: "pointer" }}
              />
            }
            sx={styles.header}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6">Statistics</Typography>
          </AccordionSummary>
          <AccordionDetails sx={styles.content}>
            {isMobile ? (
              <Box sx={styles.swiper}>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={50}
                  slidesPerView={1}
                  navigation
                  pagination={{ clickable: true }}
                  // scrollbar={{ draggable: true }}
                  // onSwiper={(swiper) => console.log(swiper)}
                  // onSlideChange={() => console.log("slide change")}
                >
                  {Object.entries(statisticsDataReferral).map(
                    ([key, value], index) => {
                      return (
                        <Box sx={{ width: "100%" }}>
                          <SwiperSlide>
                            <Box sx={styles.item} key={index}>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "16px",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    height: "24px",
                                    backgroundColor: "#2D2D2D",
                                    width: "8px",
                                    borderRadius: "32px",
                                  }}
                                ></Box>
                                <Box>
                                  <Typography variant="h6" sx={styles.title}>
                                    {value + (key === "reward" ? " $" : "")}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    sx={styles.subtitle}
                                  >
                                    {key.charAt(0).toUpperCase() +
                                      key.slice(1) +
                                      (key === "reward" ? " (Balance)" : "")}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box>
                                <AnimatedTooltip title={helpStatisticsAccordionText[index]}>
                                  <Box>
                                    <IconUI
                                      name="hint"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </Box>
                                </AnimatedTooltip>
                              </Box>
                            </Box>
                          </SwiperSlide>
                        </Box>
                      );
                    }
                  )}

                  <Box sx={{ width: "100%" }}>
                    <SwiperSlide>
                      <Box sx={styles.item}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "16px",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              height: "24px",
                              backgroundColor: "#2D2D2D",
                              width: "8px",
                              borderRadius: "32px",
                            }}
                          ></Box>
                          <Box>
                            <Typography variant="h6" sx={styles.title}>
                              {"15%"}
                            </Typography>
                            <Typography variant="caption" sx={styles.subtitle}>
                              {"Referral bonus"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <AnimatedTooltip title="Affiliate commission percentage">
                            <Box>
                              <IconUI
                                name="hint"
                                style={{ cursor: "pointer" }}
                              />
                            </Box>
                          </AnimatedTooltip>
                        </Box>
                      </Box>
                    </SwiperSlide>
                  </Box>
                </Swiper>
              </Box>
            ) : (
            <>
              {Object.entries(statisticsDataReferral).map(
                ([key, value], index) => {
                  return (
                    <Box sx={styles.item} key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "16px",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            height: "24px",
                            backgroundColor: "#2D2D2D",
                            width: "8px",
                            borderRadius: "32px",
                          }}
                        ></Box>
                        <Box>
                          <Typography variant="h6" sx={styles.title}>
                            {value + (key === "reward" ? " $" : "")}
                          </Typography>
                          <Typography variant="caption" sx={styles.subtitle}>
                            {key.charAt(0).toUpperCase() +
                              key.slice(1) +
                              (key === "reward" ? " (Balance)" : "")}
                          </Typography>
                        </Box>
                      </Box>

                      <Box>
                        <AnimatedTooltip title={helpStatisticsAccordionText[index]}>
                          <Box>
                            <IconUI name="hint" style={{ cursor: "pointer" }} />
                          </Box>
                        </AnimatedTooltip>
                      </Box>
                    </Box>
                  );
                }
              )}
              <Box sx={styles.item}>
              <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
                <Box
                  sx={{
                    height: "24px",
                    backgroundColor: "#2D2D2D",
                    width: "8px",
                    borderRadius: "32px",
                  }}
                ></Box>
                <Box>
                  <Typography variant="h6" sx={styles.title}>
                    {"15%"}
                  </Typography>
                  <Typography variant="caption" sx={styles.subtitle}>
                    {"Referral bonus"}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <AnimatedTooltip title="Affiliate commission percentage">
                  <Box>
                    <IconUI name="hint" style={{ cursor: "pointer" }} />
                  </Box>
                </AnimatedTooltip>
              </Box>
            </Box>
            </>)}
          </AccordionDetails>
        </Accordion>
      ) : (
        <>
          {isMobile ? (
            <Box sx={{ marginBottom: "50px" }}>
              <Box sx={styles.swiper}>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={50}
                  slidesPerView={1}
                  navigation
                  pagination={{ clickable: true }}
                  // scrollbar={{ draggable: true }}
                  // onSwiper={(swiper) => console.log(swiper)}
                  // onSlideChange={() => console.log("slide change")}
                >
                  {Object.entries(statisticsDataProduct).map(
                    ([key, value], index: number) => {
                      return (
                        <Box sx={{ width: "100%" }}>
                          <SwiperSlide>
                            <Box sx={styles.item} key={index}>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "16px",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    height: "24px",
                                    backgroundColor: "#2D2D2D",
                                    width: "8px",
                                    borderRadius: "32px",
                                  }}
                                ></Box>
                                <Box>
                                  <Typography variant="h6" sx={styles.title}>
                                    {new Intl.NumberFormat("en-US").format(
                                      Number(value)
                                    )}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    sx={styles.subtitle}
                                  >
                                    {statisticsText[index]}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.25rem",
                                  alignItems: "flex-end",
                                }}
                              >
                                <Box>
                                  <AnimatedTooltip title={helpStatisticText[index]}>
                                    <Box>
                                      <IconUI
                                          name="hint"
                                          style={{ cursor: "pointer" }}
                                      />
                                    </Box>
                                  </AnimatedTooltip>
                                </Box>

                                {key === "Total Verified" && (
                                  <StatisticsBar
                                    total={statisticsDataProduct["Total"]}
                                    totalVerified={
                                      statisticsDataProduct["Total Verified"]
                                    }
                                  />
                                )}
                              </Box>
                            </Box>
                          </SwiperSlide>
                        </Box>
                      );
                    }
                  )}
                </Swiper>
              </Box>
            </Box>
          ) : (
            <Box sx={styles.content}>
              {Object.entries(statisticsDataProduct).map(
                ([key, value], index) => {
                  return (
                    <Box sx={styles.item} key={key}>
                      {" "}
                      {/* Используйте key из Object.entries для уникальности */}
                      <Box
                        sx={{
                          display: "flex",
                          gap: "16px",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            height: "24px",
                            backgroundColor: "#2D2D2D",
                            width: "8px",
                            borderRadius: "32px",
                          }}
                        ></Box>
                        <Box>
                          <Typography variant="h6" sx={styles.title}>
                            {new Intl.NumberFormat("en-US").format(
                              Number(value)
                            )}
                          </Typography>
                          <Typography variant="caption" sx={styles.subtitle}>
                            {statisticsText[index]}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.25rem",
                          alignItems: "flex-end",
                        }}
                      >
                        <Box>
                          <AnimatedTooltip
                              title={helpStatisticText[index]}
                          >
                            <Box>
                              <IconUI
                                name="hint"
                                style={{ cursor: "pointer" }}
                              />
                            </Box>
                          </AnimatedTooltip>
                        </Box>

                        {key === "Total Verified" && (
                          <StatisticsBar
                            total={statisticsDataProduct["Total"]}
                            totalVerified={
                              statisticsDataProduct["Total Verified"]
                            }
                          />
                        )}
                      </Box>
                    </Box>
                  );
                }
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default Statistics;
