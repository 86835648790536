const styles = {
    container: { display: 'flex', flexDirection: 'column', gap: '32px', },
    field: {
      borderRadius: "10px",
      padding: "8px 16px",
      position: "relative",
      width: "100%",
      boxSizing: "border-box",
      height: "50px",
    }
}

export default styles