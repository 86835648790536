import React, {useEffect} from "react";
import {Box, Button, Divider, List, ListItem, Typography,} from "@mui/material";
import IconUI from "../../../../ui/icon";
import {Link} from "react-router-dom";
import {ButtonUI, CustomTooltip} from "../../../../ui";
import {NoData} from "../../../PersonalArea";
import {HeaderMenu} from "../../../../../shared/types/instances";
import {useTranslation} from "react-i18next";
import {useWindowSize} from "../../../../../shared/hooks/useWindowSize";
import {setMenuItem} from "../../../../../app/slices/drawer";
import {useDispatch, useSelector} from "react-redux";
import {checkNotifications, getNotifications} from "../../../../../app/slices/notification";

export const NotificationBlock = ({
  handleMenuClose,
  handleMenuOpen,
  menu,
  open,
}: HeaderMenu) => {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const notification = useSelector((state: any) => state.notification.notification || []);
  const { width } = useWindowSize();

  const fetchNotifications = async () => {
    try {
      // @ts-ignore
      dispatch(getNotifications())
    } catch (error) {
      console.error("Failed to fetch notifications:", error);
    }
  };

  const checkNotificationsRequest =  () => {
    try {
      // @ts-ignore
      dispatch(checkNotifications())
    } catch (error) {
      console.error("Error checking notifications:", error);
    }
  };

  useEffect(() => {
    if(width > 1024) {
      fetchNotifications();
      const interval = setInterval(fetchNotifications, 5000);

      return () => clearInterval(interval);
    }
  }, [width]);

  return (
    <CustomTooltip
      open={menu || open}
      onClose={handleMenuClose}
      title={
        <Box sx={{ position: "relative", right: "175px", top: "65px" }}>
          <Box
            sx={{
              width: "24px",
              height: "13px",
              backgroundColor: "#ffffff",
              clipPath: "polygon(0 0, 24px 0, 12px 12px)",
              transform: "rotate(180deg)",
              position: "absolute",
              right: "-35px",
              top: "-12px",
            }}
          />
          <Box
            sx={{
              boxShadow: "0px 0px 16px 0px #E5E5E5",
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              width: "410px",
              color: "#2D2D2D",
              padding: "0",
              right: "175px",
            }}
          >
            <List sx={{ padding: "16px 0 8px 0" }}>
              {notification.length > 0 ? (
                <>
                  {notification
                    .slice(0, 3)
                    .map((item: any, index: number) => (
                      <Box key={index}>
                        <ListItem disablePadding key={item.id}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              padding: "16px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                gap: "16px",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "24px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    width: "8px",
                                    height: "8px",
                                    backgroundColor: item.isRead
                                      ? "#FFF"
                                      : "#FF8800",
                                    borderRadius: "50%",
                                  }}
                                ></Box>
                              </Box>
                              <Typography
                                variant="subtitle2"
                                sx={{ color: "#5F5F5F" }}
                              >
                                {item.date}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                gap: "16px",
                              }}
                            >
                              <IconUI name={`${item.type}_color`} size={24} />
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  fontWeight: 500,
                                  lineHeight: "24px",
                                  letterSpacing: "1%",
                                }}
                              >
                                {item.text}
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        {index !== Math.min(notification.length, 3) - 1 && (
                          <Divider
                            flexItem
                            sx={{
                              width: "calc(100% - 32px)",
                              transform: "translateX(16px)",
                              borderColor: "#F5F5F5",
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "16px 0",
                    }}
                  >
                    <Link to="/home/notifications" onClick={() => dispatch(setMenuItem('/notifications'))}>
                      <ButtonUI
                        text={t("view_all")}
                        type="default"
                        onClick={handleMenuClose}
                      />
                    </Link>
                  </Box>
                </>
              ) : (
                <NoData
                  icon="no_bell"
                  description="You don't have any notifications yet, but you'll definitely get them soon!"
                  buttonText={t("view_all")}
                  link={"/home/notifications"}
                  onClick={handleMenuClose}
                  isNotification
                />
              )}
            </List>
          </Box>
        </Box>
      }
    >
      <Button
        onClick={() => {
          handleMenuOpen();
          checkNotificationsRequest();
        }}
        disableRipple
        sx={{ textTransform: "none" }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <IconUI
            name={
              notification.some((item: any) => !item.isRead)
                ? "bell_notif"
                : "bell"
            }
            size={24}
          />
          <Box
            sx={
              menu
                ? { transform: "rotate(180deg)" }
                : { transform: "rotate(0deg)" }
            }
          >
            <IconUI name="icon-down" size={8} />
          </Box>
        </Box>
      </Button>
    </CustomTooltip>
  );
};
