import { Box, Typography } from '@mui/material'
import styles from './styles'
import { useNavigate } from 'react-router'
import IconUI from '../../../../ui/icon'
import {StatisticsBar} from "../../../StatisticsBar/StatisticsBar";

export const ProductMobileCard = ({ product }: any) => {
  const navigate = useNavigate()
  return (
    <Box
      onClick={() => navigate(`/home/products/${product._id}`)}
      sx={{
        ...styles.card,
        ...(product.status === 'draft' ? styles.cardDraft : {}),
        '@media (max-width: 1024px)': {
          padding: '16px'
        },
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
      }}>
      <Box sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr auto', gap: '16px' }}>
        <Box
          sx={{
            width: '96px',
            height: '96px',
            border: '1px solid #F5F5F5',
            borderRadius: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            src={product.photos[0] ? `https://app.iqtag.io${product.photos[0]}` : '/assets/placeholder.svg'}
            alt={product.product_name}
            style={{ maxWidth: '72px', maxHeight: '72px' }}
          />
        </Box>
        <Typography
          variant="caption"
          sx={{
            ...styles.title,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 3,
            margin: '12px 0'
          }}
        >
          {product.product_name}
        </Typography>
        <Box sx={{ alignSelf: 'center' }}>
          <img src="/assets/icons/arrow_right.svg" alt="right"/>
        </Box>
      </Box>

      <Box sx={{ height: '1px', width: '100%', backgroundColor: '#F5F5F5' }}/>

      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '0' }}>
        <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          <IconUI name={'qrcode_black'}/>
          <Typography variant="caption" sx={styles.title}>
            {product.total}
          </Typography>
        </Box>
        {product.feedback_chat.length > 0 ? (
          <Box sx={styles.flexCenter}>
            <img src="/assets/icons/comment.svg" alt="comments" style={{ marginRight: '8px' }}/>
            <Typography variant="caption" sx={styles.title}>
              {product.feedback_chat.length > 99 ? '99+' : product.feedback_chat.length}
            </Typography>
          </Box>
        ) : (
          <Box sx={styles.flexCenter}>
            <img src="/assets/icons/no_comment.svg" alt="no_comment"/>
          </Box>
        )}
        <StatisticsBar
            totalVerified={product.total_verified}
            total={product.total}
            callPlaceIsProductTab
        />

      </Box>
    </Box>
  )
}