import styles from "./styles";
import {Box, Link, Typography} from "@mui/material";
import dayjs from "dayjs";
import IconUI from "../../../../../ui/icon";
import {ButtonUI} from "../../../../../ui";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {getNumberForTypeOperation, getTextForTypeOperation} from "../HistoryTab";

interface Props {
  type: 'rewards' | 'bills' | 'operations',
  item: any
}

export const TabMobile = ({type, item}: Props) => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  return (
      <Box sx={{...styles.item}}>
        <Box sx={{
            gridColumn: '1',
            color: '#5F5F5F'
          }}
        >
          <Typography variant="caption" sx={styles.text}>
            {dayjs(item.date).format('DD.MM.YYYY / HH:mm:ss')}
          </Typography>
        </Box>
        <Box sx={{
            ...(type === 'operations' ? {
              gridRow: '2',
              gridColumn: '1',
            } : {
              justifySelf: 'flex-end',
              gridRow: '1',
              gridColumn: '2',
              paddingRight: '24px',
            })
          }}
        >
          <Typography
              variant="caption"
              sx={{
                ...(item.type === 'reward' ? styles.text_green : styles.text),
                color:'#2D2D2D'
              }}
          >
            {type === "bills" && `${new Intl.NumberFormat('en-US').format(item.amount)} EUR`}
            {type === "operations" &&
                <>
                  {getTextForTypeOperation(item.type)}
                  <strong>{item.product_name ? item.product_name: item.company_name}</strong>
                </>
            }
          </Typography>
        </Box>
        <Box sx={{
          ...(type === 'operations' ? {
            justifySelf: 'flex-end',
            gridRow: '1',
            gridColumn: '2',
            paddingRight: '24px',
          } : {
            gridRow: '2',
            gridColumn: '1',
          })
        }}
        >
          <Typography
              variant="caption"
              sx={{
                ...((type === "operations" && String(item.count).startsWith("+")) ? styles.text_green : styles.text),
                color:'#2D2D2D'
              }}
          >
            {type === "bills" && `${t("purchased")} ${new Intl.NumberFormat('en-US').format(item.quantity)} ${t("qr")}`}
            {type === "operations" && getNumberForTypeOperation(item.type, item.count)}
          </Typography>
        </Box>

        {item.type === 'Sell' &&
            <IconUI
                name={'arrow_right'}
                direction={'right'}
                style={{top:'53%',cursor: 'pointer'}}
                onClick={() => navigate(`/home/products/${item.product_id}`)}
            />
        }

        {type === 'bills' &&
            <Box
                sx={{display: 'flex',
                  justifyContent: 'center',
                  paddingTop:'8px',
                  gridRow: '3',
                  gridColumn: '1'
                }}
            >
              <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.receipt_url}
                  sx={{
                    paddingRight: '0',
                  }}
              >
                <ButtonUI
                    text={t("download")}
                    type={'outline'}
                    iconStyles={{
                      fontSize: '14px',
                      padding: '4px 16px',
                      lineHeight: 'auto',
                      height: 'auto',
                      cursor: 'pointer',
                    }}
                />
              </Link>
            </Box>
        }
      </Box>
  )
}