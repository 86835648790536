import instance from "../Service";

export default class Products {
  static async create_product(data) {
    return await instance.post("/product/create_product", data);
  }
  static async add_qr_codes(data) {
    return instance.post("/product/add_qr_codes", data);
  }
  static async add_custom_field(data) {
    return await instance.post("/product/add_custom_field", data);
  }
  static async update_custom_field(data) {
    return await instance.post("/product/update_custom_field", data )
  }
  static async  delete_custom_field(data) {
    return await instance.post("/product/delete_custom_field_by_index", data )
  }
  static async reorder_custom_field(data) {
    return await instance.post("/product/reorder_custom_field", data )
  }
  static async add_photo_product(data) {
    return await instance.post("/product/add_photo_product", data ,
        {headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  }
  static async delete_photo_product(data) {
    return await instance.post("/product/delete_photo_product", data )
  }
  static async add_tag_product(data) {
    return await instance.post("/product/add_tag_product", data )
  }
  static async delete_tag_product(data) {
    return await instance.post("/product/delete_tag_product", data )
  }
  static async get_product(product_id){
    return await instance.get(`/product/get_product?product_id=${product_id}`)
  }
  static async delete_product(data) {
    return instance.post("/product/delete_product", data);
  }
  static async save_product(data) {
    return instance.post("/product/save_product", data);
  }
  static async publish_product(data) {
    return instance.post("/product/publish_product", data);
  }
  static async change_feedback_rule(data) {
    return instance.post("/product/change_feedback_rule", data);
  }
  static async read_feedback_all(data) {
    return instance.post("/product/read_feedback_all", data);
  }
  static async read_feedback(data) {
    return instance.post("/product/read_feedback", data);
  }
}
