import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import { ButtonUI, InputUI } from "../../../ui";
import { useDispatch, useSelector } from "react-redux";
import {useLayoutEffect, useRef, useState} from "react";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getReferralData } from "../../../../app/slices/referral";
import styles from "./styles";
import {createPortal} from "react-dom";
import {ModalOverlay} from "../../../../helpers/ModalOverlayClick";
import {ShareModal} from "../../Modals/ShareModal/ui/ShareModal";
import {BASE_URL} from "../../../../api/Service";

const YourLink = () => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(true);
  const [openShareModal, setOpenShareModal] = useState(false)

  const [isChangeView, setIsChangeView] = useState(false);
  const [isChangeIcon, setIsChangeIcon] = useState(false);


  const referralCode = useSelector((state: any) => state.referral.referralLink);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useLayoutEffect(() => {
    // @ts-ignore
    if(!referralCode) dispatch(getReferralData());
  }, []);

  const handleCopy = (e: React.MouseEvent) => {
    e.stopPropagation();
    if(isChangeView) return

    setIsChangeView(true)

    if (inputRef.current) {
      navigator.clipboard.writeText(inputRef.current.value)
          .then(() => console.log('good'))
          .catch((err) => console.error("Ошибка копирования:", err));
    }

    setTimeout(() => {
      setIsChangeIcon(true)
    }, 600)
    setTimeout(() => {
      setIsChangeIcon(false)
    }, 2400)
    setTimeout(() => {
      setIsChangeView(false)
    }, 3000)
  }

  return (
    <Accordion variant="outlined" sx={styles.container} expanded={isOpen}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon onClick={() => setIsOpen(!isOpen)} style={{cursor: 'pointer'}}/>}
        sx={styles.header}
        aria-controls="panel1-content"
        id="panel1-header"

      >
        <Typography variant="h6">Your Link</Typography>
      </AccordionSummary>

      <AccordionDetails sx={styles.content}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px', padding: '32px', letterSpacing: '1%', lineHeight: '24px' }}>
          <Typography variant="caption" sx={{ color: '#5F5F5F', fontSize: '14px' }}>
            Share your unique referral link to bring companies to IQTag and earn rewards for every QR code they purchase! Withdraw your earnings once you reach a minimum of €100. Start growing your network today!
          </Typography>
          <InputUI
            label="Your link"
            icon_direction="right"
            value={referralCode !== undefined ? `${BASE_URL}/sign-up?code=${referralCode}` : 'None data'}
            iconStyles={{ top: '25%',cursor: "pointer", backgroundColor:'white'}}
            ref={inputRef}
            isChangeView={isChangeView}
            icon={isChangeIcon ? "check" : "copy"}
            onClick={() => handleCopy}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center' }} >
            <ButtonUI type="outline" text="Share" onClick={() => setOpenShareModal(true)}/>
          </Box>

        </Box>
        {openShareModal &&
            createPortal(
                <ModalOverlay onClose={() => setOpenShareModal(false)} openModal={openShareModal}>
                  <ShareModal setOpenModal={setOpenShareModal} />
                </ModalOverlay>,
                document.getElementById("portal") as HTMLElement,
            )
        }

      </AccordionDetails>
    </Accordion >
  );
};

export default YourLink;