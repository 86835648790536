import {Accordion, AccordionDetails, AccordionSummary, Box, Typography,} from "@mui/material";
import styles from "./styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {ChangeEvent, useMemo, useState} from "react";
import PaginationUI from "../../../ui/pagination";
import NoData from "../NoData/NoData";
import {ITEMS_PER_PAGE} from "../../../../shared/constants";
import {checkNoDataDescription} from "../../../../helpers";
import NotificationHistoryTab from "./List/NotificationHistoryTab";
import {NotificationFilters} from "../../Filters";
import {useSelector} from "react-redux";
import {useLocation} from "react-router";
import {useWindowSize} from "../../../../shared/hooks/useWindowSize";

const NotificationHistory = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [page, setPage] = useState(1);
  const [hasPagination, setHasPagination] = useState(false);
  const {pathname} = useLocation()
  const {width} = useWindowSize();

  const notification = useSelector((state: any) => state.notification.notification || []);

  const handleChangePage = (_: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const filteredNotifications = useMemo(() => {
    let result: any

    result =  notification;
    if (result.length > ITEMS_PER_PAGE){
      setHasPagination(true)
    }else{
      setHasPagination(false)
    }

    return result;
  }, [notification]);

  const paginatedNotifications = useMemo(() => {
    if (!hasPagination) return filteredNotifications;
    return filteredNotifications.slice(
      (page - 1) * ITEMS_PER_PAGE,
      page * ITEMS_PER_PAGE
    );
  }, [page, hasPagination, filteredNotifications]);

  return (
    <Accordion variant="outlined" sx={styles.container} expanded={isOpen}>
      <AccordionSummary
        sx={styles.header}
        expandIcon={<ExpandMoreIcon onClick={() => setIsOpen(!isOpen)} style={{cursor: 'pointer'}}/>}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Box sx={styles.headerContent}>
          <Typography variant="h6">History</Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        { width > 1024 && <NotificationFilters />}
        {notification.length > 0 ? (
          <NotificationHistoryTab data={paginatedNotifications} />
        ) : (
          <NoData icon="no_bell" description={checkNoDataDescription(pathname)} />
        )}

        {hasPagination && paginatedNotifications.length &&(
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "32px",
            }}
          >
            <PaginationUI
              count={Math.ceil(filteredNotifications.length / ITEMS_PER_PAGE)}
              page={page}
              onChange={handleChangePage}
            />
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default NotificationHistory;
