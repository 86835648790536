const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    paddingBottom: "0",
    border: "none",
    background: "transparent",
    pointerEvent: 'none',
    margin: "0 !important",
    "& .MuiAccordionDetails-root": {
      padding: 0
    },
    '&:hover:not(.Mui-disabled)': {
      cursor: 'default',
    },
    "& .MuiAccordionSummary-content": {
      cursor: 'default',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 32px',
    height: "64px",
    margin: "0 !important",
    "& .MuiAccordionSummary-content": {
      margin: "0 !important",
    },
    cursor: 'default',
  },
  content: {
    background: '#FFFFFF',
    borderRadius: '16px',
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: "0px 0px 16px 0px #E5E5E5",
  }
}

export default styles