import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {handleApiError} from "../../helpers/handleApiError";
import Payment from "../../api/repositories/payment";

const initialState = {
  cardPaymentsLink: [],
  invoicePaymentsLink: []
};

export const buyStarterPackCard = createAsyncThunk(
    "pay/buyStarterPackCard",
    async (payload) => {
      try{
        const response = await Payment.buy_starter_pack_card(payload)
        return response.data;
      } catch (error){
        handleApiError(error)
      }
    }
)
export const buyGrowthPackCard = createAsyncThunk(
    "pay/buyGrowthPackCard",
    async (payload) => {
      try{
        const response = await Payment.buy_growth_pack_card(payload)
        return response.data;
      } catch (error){
        handleApiError(error)
      }
    }
)
export const buyEnterprisePackCard = createAsyncThunk(
    "pay/buyEnterprisePackCard",
    async (payload) => {
      try{
        const response = await Payment.buy_enterprise_pack_card(payload)
        return response.data;
      } catch (error){
        handleApiError(error)
      }
    }
)

export const buyStarterPackInvoice = createAsyncThunk(
    "pay/buyStarterPackInvoice",
    async (payload) => {
      try{
        const response = await Payment.buy_starter_pack_invoice(payload)
        return response.data;
      } catch (error){
        handleApiError(error)
      }
    }
)
export const buyGrowthPackInvoice = createAsyncThunk(
    "pay/buyGrowthPackInvoice",
    async (payload) => {
      try{
        const response = await Payment.buy_growth_pack_invoice(payload)
        return response.data;
      } catch (error){
        handleApiError(error)
      }
    }
)
export const buyEnterprisePackInvoice = createAsyncThunk(
    "pay/buyEnterprisePackInvoice",
    async (payload) => {
      try{
        const response = await Payment.buy_enterprise_pack_invoice(payload)
        return response.data;
      } catch (error){
        handleApiError(error)
      }
    }
)

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(buyStarterPackCard.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(buyStarterPackCard.fulfilled, (state, {payload}) => {
          state.isLoading = false;
          state.cardPaymentsLink[0] = payload.Event
        })
    builder
        .addCase(buyGrowthPackCard.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(buyGrowthPackCard.fulfilled, (state, {payload}) => {
          state.isLoading = false;
          state.cardPaymentsLink[1] = payload.Event
        })
    builder
        .addCase(buyEnterprisePackCard.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(buyEnterprisePackCard.fulfilled, (state, {payload}) => {
          state.isLoading = false;
          state.cardPaymentsLink[2] = payload.Event
        })
    builder
        .addCase(buyStarterPackInvoice.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(buyStarterPackInvoice.fulfilled, (state, {payload}) => {
          state.isLoading = false;
          state.invoicePaymentsLink[0] = payload.Event
        })
    builder
        .addCase(buyGrowthPackInvoice.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(buyGrowthPackInvoice.fulfilled, (state, {payload}) => {
          state.isLoading = false;
          state.invoicePaymentsLink[1] = payload.Event
        })
    builder
        .addCase(buyEnterprisePackInvoice.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(buyEnterprisePackInvoice.fulfilled, (state, {payload}) => {
          state.isLoading = false;
          state.invoicePaymentsLink[2] = payload.Event
        })
  },
});

export default paymentSlice.reducer;
