import {Box, Typography} from "@mui/material";
import IconUI from "../../../../../ui/icon";
import styles from "./styles";

interface Props {
  deleteNotificationRequest: (index: number) => void,
  item: any,
  index: number,
}

export const NotificationTabMobile = ({deleteNotificationRequest, item, index}: Props) => {
  return (
      <Box sx={styles.container}>
        <Box sx={styles.flex}>
          <Box sx={styles.block}>
            <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
              {!item.isRead &&
                  <Box sx={{
                    ...styles.unreadItem,
                    backgroundColor: item.isRead ? "#FFF" : "#FF8800",
                  }}>
                  </Box>
              }
              <Typography sx={styles.text}>{item.date}</Typography>
            </Box>
            <IconUI name={`${item.type}_color`} />
          </Box>
          <Box sx={styles.block}>
            <Typography sx={styles.textBold}>{item.text}</Typography>
            <Box sx={styles.iconWrapper}>
              <IconUI
                  style={{cursor: "pointer"}}
                  onClick={() => deleteNotificationRequest(index)}
                  name='del'
              />
            </Box>
          </Box>
        </Box>
      </Box>
  )
}