import { Box, Pagination, PaginationProps, Stack } from '@mui/material'

const styles = {
  pagination: {
    '.MuiPaginationItem-root:not(.MuiPaginationItem-previousNext)': {
      borderRadius: '32px',
      border: 'solid black 2px',
      width: '40px',
      height: '32px',
    },
    '.MuiPaginationItem-root:not(.MuiPaginationItem-previousNext):hover': {
      backgroundColor: '#000',
      color: '#FFF',
    },
    '.MuiPaginationItem-root.Mui-selected': {
      backgroundColor: '#000',
      color: '#FFF',
    },
  },
}

interface Props extends PaginationProps {}

const PaginationUI = ({ sx, count = 24, ...props }: Props) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Stack spacing={2}>
        <Pagination sx={Object.assign(styles.pagination, sx)} count={count} {...props} />
      </Stack>
    </Box>
  )
}

export default PaginationUI