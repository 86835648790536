import {Box, Typography} from "@mui/material"
import IconUI from "../../../../../../ui/icon"
import styles from "./styles"
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const Step5 = () => {
  const {t} = useTranslation()

  const kybStatus = useSelector((state: any) => state.kyb.kyb_status)

  const TextForStatus = (string: string) => {
    switch (string){
      case 'approved':
        return <>
          <Box sx={{...styles.info, ...styles.infoApproved}}>
            <IconUI name="check_white" />
            <Typography variant="subtitle1" sx={styles.title}>{t("kyb_status")} {t("verified")}</Typography>
          </Box>

          <Typography variant="subtitle2" sx={styles.description}>
            Your company has been successfully verified. You’re all set to access the full range of platform features.
          </Typography>
        </>
      case 'pending':
        return <>
          <Box sx={styles.info}>
            <IconUI name="info" />
            <Typography variant="subtitle1" sx={styles.title}>{t("kyb_status")} {t("pending")}</Typography>
          </Box>

          <Typography variant="subtitle2" sx={styles.description}>
            {t("pending_msg")}
          </Typography>
        </>
    }
  }

  return (
      <Box sx={styles.container}>
        {TextForStatus(kybStatus)}
      </Box >
  )
}

export default Step5