import styles from "../styles";
import {Box, Grid2, Typography} from "@mui/material";
import IconUI from "../../../../../ui/icon";

interface Props {
  deleteNotificationRequest: (index: number) => void,
  item: any,
  index: number,
}

export const NotificationTabDesktop = ({deleteNotificationRequest, item, index}: Props) => {

  return (
      <Grid2 container spacing={10} sx={styles.item} key={index}>
        <Grid2 size={3} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="caption" sx={styles.text}>{item.date}</Typography>
          <Box sx={{ width: "8px", height: "8px", backgroundColor: item.isRead ? "#FFF" : "#FF8800", borderRadius: "50%" }}></Box>
        </Grid2>
        <Grid2 size={9} sx={{ display: "flex", justifyContent: "space-between", gap: '16px', alignItems: "center" }}>
          <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
            <IconUI name={`${item.type}_color`} />
            <Typography variant="caption" sx={styles.text}>{item.text}</Typography>
          </Box>
          <Box sx={{ paddingRight: "32px" }}>
            <IconUI
                style={{cursor: "pointer"}}
                onClick={() => deleteNotificationRequest(index)}
                name='del'
            />
          </Box>
        </Grid2>
      </Grid2>
  )
}