import { Box } from "@mui/material";
import {useEffect, useMemo, useState} from "react";
import {
  ComposableMap,
  Geographies,
  Geography
} from "react-simple-maps";
import { csv } from "d3-fetch";
import { json } from "d3-fetch";
import useAnalyticsData from "../../../../shared/hooks/useAnalyticsData";

const geoUrl = "/features.json";

const AnalyticMap = ({place, type}) => {
  const [data, setData] = useState([]);
  const [geography, setGeography] = useState(null);

  const analyticsData = useAnalyticsData(type, place, true)

  useEffect(() => {
    json(geoUrl).then((geoData) => {
      setGeography(geoData);
    });
  }, []);

  useEffect(() => {
    csv(`/vulnerability.csv`).then((data) => {
      setData(data);
    });
  }, []);


  const maxScans = useMemo(() => {
      return Math.max(...Object.values(analyticsData || {}), 0)
  }, [analyticsData]);


  return (
      <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <ComposableMap
            projectionConfig={{
              scale: 140,
              rotate: [-11, 0, 0],
              center: [0,40]
            }}
            projection="geoMercator"
            style={{ width: `100%`, height: "420px", margin: "0 auto", display: "block" }}
        >
          {geography && (
              <Geographies geography={geography}>
                {({ geographies }) =>
                    geographies.map((geo) => {
                      const newData = analyticsData
                      const countryId = geo.id;

                      let alpha = 0;
                      if (Object.keys(newData).length > 0 && countryId in newData && maxScans > 0 ) {
                        alpha = newData[countryId] / maxScans;

                        alpha = Math.max(alpha, 0.15);
                      } else {
                        const csvEntry = data.find((s) => s.ISO3 === countryId);
                        if (csvEntry && csvEntry["1995"]) {
                          alpha = +csvEntry["1995"];
                        }
                      }

                      const fillColor = alpha > 0
                          ? type === 'scanned'
                              ? `rgba(255, 136, 0, ${alpha})`
                              : `rgba(0, 192, 106, ${alpha})`
                          : "#c7c7c7";

                      return (
                          <Geography
                              key={geo.rsmKey}
                              geography={geo}
                              fill={fillColor}
                              style={{
                                default: { outline: "none" },
                                hover: { outline: "none" },
                                pressed: { outline: "none" }
                              }}
                          />
                      );
                    })
                }
              </Geographies>
          )}
        </ComposableMap>
      </Box>
  );
};

export default AnalyticMap;