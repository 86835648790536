const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    paddingBottom: "32px",
    border: "none",
    background: "transparent",
    pointerEvent: 'none',
    margin: "0 !important",
    "& .MuiAccordionDetails-root": {
      padding: 0
    },
    "&::before": {
      content: "none"
    },
    "& h3 > *": {
      margin: "0 !important"
    }
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 32px',
    margin: "0 !important",
    height: "64px",
    "& .MuiAccordionSummary-content": {
      margin: "0 !important",
    },
    "@media(max-width:1024px)": {
      padding: '0 16px',
    }
  },

  headerContent: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    minHeight: "64px",
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    backgroundColor: '#FFF',
    borderRadius: '16px',
    padding: '32px',
    boxShadow: "0px 0px 16px 0px #E5E5E5",
    "@media(max-width:1024px)": {
      padding: '16px',
    }
  },

  file_container: {
    display: 'flex',
    gap: "16px",
    padding: "0",
    flexDirection: 'column',
    alignItems: 'start',

  },
  file_form: {
    display: 'flex',
    gap: "32px",
    flexDirection: 'column',
    alignItems: 'center',
    border: "1px solid #D8D8D8",
    borderRadius: "10px",
    padding: "32px",
    width: "100%",
    boxSizing: "border-box",
    position: 'relative',
    "@media(max-width:1024px)": {
      padding: '16',
    }
  },
  file_fileName: {
    color: "#000000",
    fontSize: "16px",
    fontWeight: "600",
    textAlign: "center"
  },
  file_cross: {
    position: "absolute",
    top: "16px",
    right: "16px",
    cursor: 'pointer',
    backgroundColor: "#F5F5F5",
    borderRadius: "5px",
    width: "24px",
    height: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  file_content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px"
  },
}

export default styles