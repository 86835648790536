import {Box, Typography} from "@mui/material"
import styles from "./styles"
import {useWindowSize} from "../../../../../shared/hooks/useWindowSize";
import {t} from "i18next";
import {TabDesktop} from "./TabDesktop/TabDesktop";
import {TabMobile} from "./TabMobile/TabMobile";
import {maskEmail} from "../../../../../shared/utils/maskEmail";

interface Props {
  type: 'rewards' | 'bills' | 'operations',
  data: any[]
}

export const getTextForTypeOperation = (type: 'A_Buy' | 'Buy' | 'Sell') => {
  switch(type) {
    case "A_Buy":
      return t("added_from_admin")
    case "Buy":
      return `Crediting to the company's balance `
    case "Sell":
      return t("operations_crediting")
    default:
      return ''
  }
}

export const getNumberForTypeOperation = (operationType: "A_Buy" | "Buy" | "Sell", count: number) => {
  const formattedCount = new Intl.NumberFormat("en-US").format(count);
  return (
      <Typography sx={operationType === "Sell" ? styles.text : styles.text_green}>
        {operationType === "Sell" ? `- ${formattedCount}` : `+ ${formattedCount}`} {t("qr")}
      </Typography>
  );
}

export const rewardValue = (type: "reward" | "withdrawal", email: string) => {
  if(type === 'reward') {
    return t('withdrawal_funds_completed') + maskEmail(email)
  } else {
    return t("withdrawal_funds");
  }
}

const HistoryTab = ({ type, data}: Props) => {
  const {width} = useWindowSize()

  return (
    <Box sx={styles.container}>
      {data.map((item: any, index) => (
        (width > 1024 || type === 'rewards') ? (
          <TabDesktop key={index} type={type} item={item}/>
        ) : (
          <TabMobile key={index} type={type} item={item}/>
        )
      ))}
    </Box>
  )
}

export default HistoryTab
