import {Box, Typography} from "@mui/material";
import styles from "./styles";
import {data} from "./constants";
import {ButtonUI} from "../../../ui";
import {useNavigate} from "react-router";
import {setMenuItem} from "../../../../app/slices/drawer";
import {useDispatch} from "react-redux";

interface Props {
  setOpenModal: (openModal: boolean) => void;
}

const AddNewProduct = ({ setOpenModal }: Props) => {
  const { title, subtitle } = data;
  const navigate = useNavigate();
  const dispatch = useDispatch()

  return (
    <Box
        sx={styles.container}
        onClick={(e) => {e.stopPropagation()}}
    >
      <Box sx={styles.header}>
        <Typography variant="h6">{title}</Typography>
        <img
          src="/assets/icons/modal_close.svg"
          onClick={() => setOpenModal(false)}
          style={{cursor: 'pointer'}}
        />
      </Box>

      <Typography variant="subtitle2" sx={styles.subtitle}>
        {subtitle}
      </Typography>

      <Box sx={styles.buttons}>
        <ButtonUI
          type="default"
          text="Go to"
          onClick={() => {
            setOpenModal(false)
            dispatch(setMenuItem('/company'))
            navigate('/home/company')
          }}
        />
      </Box>
    </Box>
  );
};

export default AddNewProduct;
