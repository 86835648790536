import {tStr} from "../../../../../shared/utils/translateFunc";

const styles = {
  container: {},
  card: {
    width: '100%',
    padding: '32px 0',
    boxSizing: 'border-box',
    backgroundColor: '#FFF',
    borderRadius: '16px',
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer'
  },
  cardDraft: {
    '&::before': {
      content: `"${tStr("draft") || 'DRAFT'}"`,
      position: 'absolute',
      top: '20px',
      left: '-45px',
      width: '150px',
      height: '24px',
      background: 'linear-gradient(135deg, #C7C7C7 50%, #C7C7C7 50%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transform: 'rotate(-45deg)', // Поворот текста
      fontSize: '15px',
      color: 'white',
      pointerEvents: 'none',
      zIndex: 1
    }
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '10px'
  },
  tags: {
    display: 'flex',
    gap: '8px',
    flexDirection: 'row',
    flexWrap: 'nowrap'
  },
  tag: {
    padding: '0 8px',
    border: '1px solid #5F5F5F',
    borderRadius: '16px',
    width: 'fit-content'
  },
  bar: {
    position: 'relative',
    width: '0',
    height: '8px',
    background: 'linear-gradient(90deg, #FF8800 0%, #FF6954 100%)',
    borderRadius: '10px',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '86px',
      height: '4px',
      backgroundColor: '#D8D8D8',
      top: '20%',
      left: '0',
      zIndex: '-1',
      borderRadius: '10px'
    }
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.01em',
    textAlign: 'left'
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.01em',
    textFlign: 'left',
    color: '#5F5F5F',
    paddingBottom: '8px'
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    minWidth:'90px'
  },
  icon: {
    position: 'absolute',
    right: '32px',
    top: '50%',
    transform: 'translateY(-50%)'
  }

} as const

export default styles