import {Box, ClickAwayListener, FormControl, MenuItem, Select} from "@mui/material";
import {ButtonUI} from "../../ui";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {CreateNewCompany} from "../Modals";
import {Link} from "react-router-dom";
import {ModalOverlay} from "../../../helpers/ModalOverlayClick";
import {auth} from "../../../app/slices/auth";
import {createPortal} from "react-dom";
import {setMenuItem} from "../../../app/slices/drawer";
import styles from "./styles";
import {FlagImage} from "react-international-phone";
import {CompaniesBlock, LanguageBlock, NotificationBlock, ProfileBlock, QrBlock} from "./Blocks";
import useLocalStorage from "../../../shared/hooks/useLocalStorage";
import i18n from "i18next";
import {toast} from "react-toastify";
import {BASE_URL} from "../../../api/Service";

interface Props {
  isLangChange: boolean;
  isAuth?: boolean;
  data?: any;
  isLogo?: boolean,
}

interface MenuStatesType {
  open: boolean;
  openCompany: boolean;
  qrMenu: boolean;
  profileMenu: boolean;
  langMenu: boolean;
  notification: boolean;
}

const Header = ({isLogo=false}: Props) => {
  const path = window.location.pathname;
  const dispatch = useDispatch();

  const {isAuth} = useSelector((state: any) => state.auth);

  const [lang, setLang] = useLocalStorage("language", i18n.language.toUpperCase())

  const companies = useSelector((state: any) => state.dashboard.companies);

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      dispatch(auth(true));
    }
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const [menuStates, setMenuStates] = useState<MenuStatesType>({
    open: false,
    openCompany: false,
    qrMenu: false,
    profileMenu: false,
    langMenu: false,
    notification: false,
  });
  
  const handleAllClose = () => {
    setMenuStates((prevState) =>
        Object.keys(prevState).reduce((acc, key) => {
          acc[key as keyof MenuStatesType] = false;
          return acc;
        }, {} as MenuStatesType)
    );
  };

  const handleMenuToggle = (menuKey: keyof MenuStatesType) => {
    setMenuStates((prevState) => {
      const newState: MenuStatesType = Object.keys(prevState).reduce((acc, key) => {
        acc[key as keyof MenuStatesType] = key === menuKey ? !prevState[key] : false;
        return acc;
      }, {} as MenuStatesType);
      return newState;
    });
  };

  const handleMenuClose = (menuKey: keyof MenuStatesType) => {
    setMenuStates((prevState) => ({
      ...prevState,
      [menuKey]: false,
    }));
  };

  const changeLanguage = (code: string) => {
    setLang(code);
    i18n.changeLanguage(code.toLowerCase(), (error) => {
      if(error) toast.error("Something wrong")
    })

    localStorage.setItem('language', code.toLowerCase());
  }


  // const mockNotification: any = []
  return (
      <header>
        {isAuth && path.includes('/home') ? (
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: "112px",
              backgroundColor: "#fbfbfb"
            }}>
              <Box sx={{flex: '1'}}>
                <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: "32px",
                      padding: "32px"
                    }}
                >
                  <ClickAwayListener onClickAway={handleAllClose}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: "16px", flex: '1' }}>
                      <Box sx={{ flexGrow: '1' }}>
                        {isLogo ? (
                            <Box sx={{ padding: "0 70px" }}>
                              <Link
                                  to={'/home/dashboard'}
                                  onClick={() => dispatch(setMenuItem('/dashboard'))}
                              >
                                <img
                                    src={'/assets/logo.svg'}
                                    alt={'logo'}
                                    style={{ cursor: 'pointer' }}
                                />
                              </Link>
                            </Box>
                        ) : (
                            <Box sx={{display:`${path.includes('/notifications') ? 'none': ''}`}}>
                              {companies?.length > 0 ? (
                                  <CompaniesBlock
                                      handleMenuClose={() => handleMenuClose('openCompany')}
                                      handleMenuOpen={() => handleMenuToggle('openCompany')}
                                      menu={menuStates.openCompany}
                                      open={menuStates.open}
                                      companies={companies}
                                      handleModalOpen={setOpenModal}
                                  />
                              ) : (
                                  <ButtonUI
                                      text="Create company"
                                      type="outline"
                                      onClick={() => setOpenModal(true)}
                                  />
                              )}
                            </Box>
                        )}
                      </Box>
                      <QrBlock
                          handleMenuClose={() => handleMenuClose('qrMenu')}
                          handleMenuOpen={() => handleMenuToggle('qrMenu')}
                          menu={menuStates.qrMenu}
                          open={menuStates.open}
                      />
                      <ProfileBlock
                          handleMenuClose={() => handleMenuClose('profileMenu')}
                          handleMenuOpen={() => handleMenuToggle('profileMenu')}
                          menu={menuStates.profileMenu}
                          open={menuStates.open}
                      />
                      <NotificationBlock
                          handleMenuClose={() => handleMenuClose('notification')}
                          handleMenuOpen={() => handleMenuToggle('notification')}
                          menu={menuStates.notification}
                          open={menuStates.open}
                      />
                      <LanguageBlock
                          handleAllClose={handleAllClose}
                          handleMenuOpen={() => handleMenuToggle('langMenu')}
                          handleMenuClose={() => handleMenuClose('langMenu')}
                          menu={menuStates.langMenu}
                          open={menuStates.open}
                          changeLanguage={changeLanguage}
                          lang={lang}
                      />
                    </Box>
                  </ClickAwayListener>
                </Box>
              </Box>
            </Box>
        ) : (
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding:'32px 40px'}}>
              <a href={`${BASE_URL}`}>
                <img src={'/assets/logo.svg'} alt={'logo'}/>
              </a>
              <FormControl
                  variant="outlined"
                  size="small"
              >
                <Select
                    displayEmpty
                    defaultValue="EN"
                    onChange={(event) => changeLanguage(event.target.value)}
                    sx={styles.customSelector}
                >
                  <MenuItem value="EN" >
                    <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                      <FlagImage iso2="gb" size={20}/>
                      <span style={{paddingTop: '1px'}}>EN</span>
                    </div>
                  </MenuItem>
                  <MenuItem value="CZ" >
                    <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                      <FlagImage iso2="cz" size={20}/>
                      <span style={{paddingTop: '1px'}}>CZ</span>
                    </div>
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
        )
      }
      {openModal && createPortal(
          <ModalOverlay onClose={() => setOpenModal(false)} openModal={openModal}>
            <CreateNewCompany
                setOpenModal={setOpenModal}
            />
          </ModalOverlay>,
          document.getElementById('portal') as HTMLElement
        )
      }
    </header>
  );
};

export default Header;