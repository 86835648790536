import 'react-international-phone/style.css';
import { BaseTextFieldProps, MenuItem, Select, Typography, Box } from '@mui/material';
import React, {useEffect} from 'react';
import { CountryIso2, defaultCountries, FlagImage, parseCountry, usePhoneInput } from 'react-international-phone';
import useLocalStorage from "../../../shared/hooks/useLocalStorage";

export interface MUIPhoneProps extends BaseTextFieldProps {
  value: string;
  onChange: (phone: string ) => void,
  newCountry?: string,
}

const restrictedCountries = ( country:string ) => {
  return [
    'North Korea',
    'Iran',
    'Syria',
    'Cuba',
    'Sudan',
    'South Sudan',
    'Belarus',
    'Russia',
    'Myanmar',
    'Venezuela',
    'Yemen',
    'Zimbabwe',
    'Central African Republic',
    'Libya',
    ].includes(country)
}

export const filteredCountries = defaultCountries.filter(i => !restrictedCountries(i[0]))

const MuiCountryInput: React.FC<MUIPhoneProps> = ({
  value,
  onChange,
  error,
  newCountry,
}) => {
  const [countryCode] = useLocalStorage('country_code', '')

  const { country, setCountry } = usePhoneInput({
    defaultCountry: countryCode || 'us',
    value,
    countries: defaultCountries,
    onChange: (data) => {
      onChange(data.country.name);
    },
  });

  useEffect(() => {
    if (newCountry) {
      setCountry(newCountry as CountryIso2);
    }
  }, [newCountry]);

  return (
      <Box display="flex" alignItems="center" gap={4} sx={{width:'100%'}}>
        <Select
            MenuProps={{
              PaperProps: {
                style: {
                  height: '300px',
                  minWidth: '200px',
                  width: '400px',
                },
              },
              style: {
                top: '5px',
                left:'',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
            sx={{
              border: `${error ? '1px solid #FF6954' : '1px solid rgb(216, 216, 216)'}`,
              borderRadius: '10px',
              width: '100%',
              maxWidth: '100%',
              fieldset: {
                display: 'none',
              },
              '&.Mui-focused:has(div[aria-expanded="false"])': {
                fieldset: {
                  display: 'block',
                },
              },
              '.MuiSelect-select': {
                padding: '15px 14px',
              },
              svg: {
                right: 0 ,
              },
            }}
            value={country.iso2}
            onChange={(e) => setCountry(e.target.value as CountryIso2)}
            renderValue={(value) => (
                country ? (
                    <Box display="flex" alignItems="center">
                      <FlagImage iso2={value} />
                      <Typography marginLeft={1}>{country.name}</Typography>
                    </Box>
                ) : null
            )}
        >
          {filteredCountries.map((c) => {
            const country = parseCountry(c);
            return (
                <MenuItem key={country.iso2} value={country.iso2}>
                  <FlagImage iso2={country.iso2} style={{ marginRight: '8px' }} />
                  <Typography marginRight="8px">{country.name}</Typography>
                </MenuItem>
            );
          })}
        </Select>
      </Box>
  );
};

export default MuiCountryInput;
