import instance from "../Service";

export default class Payment {
  static async buy_starter_pack_card() {
    return await instance.get("/pay/buy_starter_pack_card")
  }
  static async buy_growth_pack_card(){
    return await instance.get(`/pay/buy_growth_pack_card`)
  }
  static async buy_enterprise_pack_card(){
    return await instance.get(`/pay/buy_enterprise_pack_card`)
  }
  static async buy_starter_pack_invoice() {
    return await instance.get("/pay/buy_starter_pack")
  }
  static async buy_growth_pack_invoice(){
    return await instance.get(`/pay/buy_growth_pack`)
  }
  static async buy_enterprise_pack_invoice(){
    return await instance.get(`/pay/buy_enterprise_pack`)
  }
}
