import { Button, IconButton, SxProps } from '@mui/material'
import IconUI from './icon'
import styles from "./styles.module.scss"

interface Props {
  text?: string,
  type?: 'default' | 'icon' | 'outline',
  onClick?: () => void,
  width?: string,
  disabled?: boolean
  iconStyles?: object,
  hasBorder?: boolean,
  icon?: string,
  iconSize?: number,
  pulse?: boolean,
  mobile?: boolean,
  sx?: SxProps
}

const ButtonUI = ({
  text,
  onClick,
  type = 'default',
  mobile = false,
  width,
  disabled = false,
  iconStyles,
  hasBorder = true,
  icon = 'arrow_left',
  iconSize,
  sx,
  pulse = false
}: Props) => {
  return (
    <>
      {
        type === 'default' && <Button
          onClick={onClick}
          disabled={disabled}
          sx={{
            width: width ? width : 'max-content',
            backgroundColor: disabled ? '#D8D8D8' : '#000000',
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#a2a2a2'
            },
            ...sx
          }}
          className={`${styles.pulse} ${pulse ? styles.pulseAnimate : ''}`}
        >
          {text}
        </Button>
      }
      {
        type === 'outline' && <Button
          onClick={onClick}
          disabled={disabled}
          sx={{
            borderRadius: '48px',
            padding: '12px 32px',
            backgroundColor: '#transparent',
            border: hasBorder ? '2px solid #000000' : '',
            color: '#000000',
            fontSize: '16px',
            fontWeight: '600',
            width: width ? width : 'max-content',
            textTransform: 'none',
            height: '50px',
            boxSizing: 'border-box',
            '&:hover': {
              backgroundColor: 'rgba(201,201,201,0.7)'
            },
            ...iconStyles,
            ...sx
          }}
        >
          {text}
        </Button>
      }
      {
        type === 'icon' &&
        <IconButton
          onClick={onClick}
          disabled={disabled}
          sx={{
            width: '48px',
            height: '48px',
            borderRadius: '48px',
            backgroundColor: (disabled ? '#D8D8D8' : (mobile ? '#000000' : 'none')),
            color: '#FFFFFF',
            border: 'none',
            ...sx
          }}
        >
          <IconUI name={icon} size={iconSize} style={iconStyles} animate={icon === 'miniloader'}/>
        </IconButton>
      }
    </>
  )
}

export default ButtonUI