import { Box, SxProps, Typography } from '@mui/material'
import { ButtonUI, InputUI, SearchUI } from '../../../ui'
import { ReactElement, ReactNode } from 'react'

interface BaseProps {
  title: string,
  search?: boolean,
  buttonText?: string | null,
  onClick?: () => void,
  buttonType?: 'default' | 'icon' | 'outline' | undefined | null
  buttons?: { type: string, text: string, onClick: () => void }[] | null
  renderButtons?: ReactElement[]
  sx?: SxProps
}

interface SearchProps {
  search: true
  searchValue: string
  handleSearch: (value: string) => void
}

interface NoSearchProps {
  search?: false
  searchValue?: never
  handleSearch?: never
}

type Props = BaseProps & (SearchProps | NoSearchProps)

const PersonalAreaHeader = ({
  title,
  search,
  onClick,
  buttonText,
  buttonType,
  buttons,
  handleSearch,
  searchValue,
  renderButtons,
  sx
}: Props) => {
  return (
    <Box sx={{
      padding: '32px',
      display: 'grid',
      gridTemplateColumns: '1fr 264px 1fr',
      rowGap: '32px',
      alignItems: 'center',
      '@media (max-width: 624px)': {
        padding: '32px 16px 32px 16px',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: search ? 'repeat(2, 1fr)' : ''
      },
      ...sx
    }}>
      <Typography sx={{ fontWeight: 500 }} variant="h5">{title}</Typography>
      {search && (
        <SearchUI
          sx={{
            '@media (max-width: 624px)': {
              gridRow: '2',
              gridColumn: 'span 2 / span 2'
            }
          }}
          styleIcon={{ top: '25%' }}
          searchValue={searchValue}
          handleSearch={handleSearch}
        />
      )}
      <Box sx={{
        '@media (min-width: 624px)': {
          gridColumn: '3'
        },
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '16px'
      }}>
        {buttonText && buttonType && (
          <ButtonUI type={buttonType} text={buttonText} onClick={onClick}/>
        )}
        {renderButtons}
        {buttons?.map((el, index) => (
          <ButtonUI
              key={el.text}
              type={el.type as 'default' | 'icon' | 'outline'}
              text={el.text}
              onClick={el.onClick}
          />
        ))}
      </Box>
    </Box>
  )
}

export default PersonalAreaHeader