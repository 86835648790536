import {Box, Typography} from "@mui/material";
import styles from "./styles";
import {data} from "./constants";
import {ButtonUI, LabelUI} from "../../../ui";
import {useDispatch} from "react-redux";
import {Form, useNavigate} from "react-router-dom";
import {Field, Formik} from "formik";
import * as yup from "yup";
import {createProduct} from "../../../../app/slices/products";
import {useTranslation} from "react-i18next";
import {tDouble} from "../../../../shared/utils/translateFunc";
import {getDashboard} from "../../../../app/slices/dashboard";

interface Props {
  setOpenModal: (openModal: boolean) => void;
}

const validationSchema = yup.object().shape({
  product_name: yup.string().min(10, "The product name must not be less than 10 characters.").required(tDouble("field_empty")),
});

const AddNewProductDetail = ({ setOpenModal }: Props) => {
  const {  subtitle } = data;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const company_id = localStorage.getItem("company_id");

  const { t } = useTranslation()

  const submitHandler = async (values: any) => {
    try {
      // @ts-ignore
      await dispatch(createProduct({ company_id, ...values }))
        .unwrap()
        .then((payload: any) => {
          const productId = payload.Product._id;
          navigate(`/home/products/${productId}`);
          setOpenModal(false);
        })
        // @ts-ignore
        .finally(() => dispatch(getDashboard()))

    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <Formik
      initialValues={{
        product_name: "",
      }}
      validationSchema={validationSchema}
      onSubmit={submitHandler}
    >
      {({ errors, touched, values, handleSubmit, setFieldValue }) => (
        <Form onClick={(e) => {e.stopPropagation()}}>
          <Box sx={styles.container}>
            <Box sx={styles.header}>
              <Typography variant="h6">{t("add_new_product")}</Typography>
              <img
                src="/assets/icons/modal_close.svg"
                onClick={() => setOpenModal(false)}
                style={{cursor: 'pointer'}}
                alt="close modal icon"
              />
            </Box>

            <Typography variant="subtitle2" sx={styles.subtitle}>
              {subtitle}
            </Typography>

            <Box sx={{ padding: "0 32px", display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  flexGrow: 1,
                }}
              >
                <Box sx={{ padding: "0 16px" }}>
                  <LabelUI
                    htmlFor="title"
                    text={t("title")}
                    error={errors.product_name && touched.product_name}
                  />
                </Box>

                <Field
                  name="product_name"
                  style={{
                    border:
                      errors.product_name && touched.product_name
                        ? "1px solid #FF6954"
                        : "1px solid #D8D8D8",
                    borderRadius: "10px",
                    padding: "8px 16px",
                    position: "relative",
                    width: "100%",
                    boxSizing: "border-box",
                    height: "50px",
                  }}
                  value={values.product_name}
                  onChange={(e: any) =>
                    setFieldValue("product_name", e.target.value)
                  }
                />
                {errors.product_name && touched.product_name &&(
                  <Box sx={{ padding: '0 16px' }}>
                    <Typography
                        variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                      {errors.product_name}
                    </Typography>
                  </Box>

                )}
              </Box>
            </Box>

            <Box sx={styles.buttons}>
              <ButtonUI
                type="outline"
                text={t("cancel")}
                onClick={() => setOpenModal(false)}
              />
              <ButtonUI type="default" text={t("add")} onClick={handleSubmit} />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddNewProductDetail;
