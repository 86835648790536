import {FormControl, InputBase, SxProps} from '@mui/material'
import IconUI from './icon'
import {useTranslation} from "react-i18next";

interface Props {
  styleIcon?: object,
  searchValue: string
  handleSearch: (value: string) => void
  sx?: SxProps
}

const SearchUI = ({styleIcon, searchValue, handleSearch, sx}: Props) => {
  const {t} = useTranslation()

  return (
      <FormControl sx={{
        width: 'max(264px, 100%)',
        boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)',
        borderRadius: '48px',
        ...sx
      }}>
        <InputBase
            sx={{
              border: 'none',
              backgroundColor: '#FFF',
              borderRadius: 'inherit',
              padding: '8px 16px',
              position: 'relative'
            }}
            onChange={(e) => handleSearch(e.target.value)}
            value={searchValue}
            placeholder={t("search")}
        />
        <IconUI
            name={searchValue ? 'cross' : 'search'}
            direction="right"
            onClick={searchValue ? () => handleSearch('') : undefined}
            style={styleIcon}
        />
      </FormControl>
  )
}

export default SearchUI