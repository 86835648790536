import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import styles from "./styles";
import CustomTabs from "../../../Tabs";
import { CustomTabPanel } from "../../../../../helpers";
import FormVariant from "./forms";
import axios from "axios";
import { setStatus } from "../../../../../app/slices/kyb";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "@uidotdev/usehooks";

const Content = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const isMobile = width && width <= 1024;
  const [tab, setTab] = useState(0);
  const access_token = localStorage.getItem("access_token");
  const tabs = [t("company"), "Identity", "Address", "Documents"];
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const dispatch = useDispatch();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  useEffect(() => {
    // @ts-ignore
    try {
      const response = axios
        .get(`${process.env.REACT_APP_BASE_URL_RU}/api/kyb/get_kyb_status`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            dispatch(setStatus(response.data.KYB_Status.kyb_status));
            setData(response.data.KYB_Status);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Accordion variant="outlined" sx={styles.container} expanded={isOpen}>
      <AccordionSummary
        sx={styles.header}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Box sx={styles.headerContent}>
          <Typography variant="h6">{t("company")}</Typography>
          {isOpen && !isMobile && (
            <Box sx={{ paddingRight: `${isMobile ? "16px" : "32px"}` }}>
              <CustomTabs value={tab} handleChange={handleChange} tabs={tabs} />
            </Box>
          )}
        </Box>
      </AccordionSummary>
      {isOpen && isMobile && (
        <Box sx={{ 
        padding: `${isMobile ? "0 5px" : "0 32px"}`,

         margin:"-25px 0 32px 0",
         ' & .MuiTabs-flexContainer': {
          justifyContent:'space-between',
          gap: '0',
          }}}>
          <CustomTabs value={tab} handleChange={handleChange} tabs={tabs} />
        </Box>
      )}
      <AccordionDetails>
        <CustomTabPanel value={tab} index={0}>
          <Box sx={styles.content}>
            <FormVariant type="first_tier" data={data} />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
          <Box sx={styles.content}>
            <FormVariant type="second_tier" data={data} />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={2}>
          <Box sx={styles.content}>
            <FormVariant type="third_tier" data={data} />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={3}>
          <Box sx={styles.content}>
            <FormVariant type="fourth_tier" data={data} />
          </Box>
        </CustomTabPanel>
      </AccordionDetails>
    </Accordion>
  );
};

export default Content;
