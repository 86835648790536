import { Box } from "@mui/material"

import Header from "../Header"
import Footer from "../Footer"
import './styles.css'

interface Props {
  typeScreen: 'All' | 'WithLayout';
}

const Loader = ({typeScreen}: Props) => {
  return (
    <Box
      sx={{
        height:'100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        boxSizing: 'border-box',
      }}>
      <Header isLangChange />
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Box>
     <Footer />
    </Box >
  )
}

export default Loader