import {Box} from "@mui/material";
import s from './styles';
import {ButtonUI, InputUI} from "../../../../ui";
import {useRef, useState} from "react";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebook, faXTwitter, faTelegram} from '@fortawesome/free-brands-svg-icons';
import {Link} from "react-router-dom";
import { data } from "../model/constants";
import {BASE_URL} from "../../../../../api/Service";

interface Props {
  setOpenModal: (openModal: boolean) => void;
}

export const ShareModal = ({ setOpenModal }: Props) => {
  const [viewStates, setViewStates] = useState<Record<number, boolean>>({});
  const [iconStates, setIconStates] = useState<Record<number, boolean>>({});

  const referralCode = useSelector((state: any) => state.referral.referralLink);
  const inputRefs = useRef<Record<number, HTMLInputElement | null>>({});

  const handleCopy = (index: number) => {
    setViewStates((prev) => ({ ...prev, [index]: true }));

    const currentInput = inputRefs.current[index];
    if (currentInput) {
      navigator.clipboard
        .writeText(currentInput.value)
        .then(() => console.log("good"))
        .catch((err) => console.error("Ошибка копирования:", err));
    }

    setTimeout(() => {
      setIconStates((prev) => ({ ...prev, [index]: true }));
    }, 600);
    setTimeout(() => {
      setIconStates((prev) => ({ ...prev, [index]: false }));
    }, 2400);
    setTimeout(() => {
      setViewStates((prev) => ({ ...prev, [index]: false }));
    }, 3000);
  };

  const postText = `Secure your products & boost loyalty with IQtag! Blockchain QR codes ensure authenticity & reward customers. Join now: ${BASE_URL}/sign-up?code=${referralCode} #IQtag`;

  return (
    <Box sx={s.container} onClick={(e) => e.stopPropagation()}>
      <Box sx={s.header}>
        <div></div>
        <img
          src="/assets/icons/modal_close.svg"
          onClick={() => setOpenModal(false)}
          style={{ cursor: "pointer" }}
          alt="close modal icon"
        />
      </Box>
      <Box sx={s.bottom}>
        <h2 style={s.title}>Share</h2>
        <ul style={s.list}>
          {data.map((item, index) => (
            <li key={index} style={s.listItem}>
              <div style={s.firstBlock}>
                <FontAwesomeIcon
                  icon={
                    item.title === 'X'
                      ? faXTwitter
                      : item.title === 'Facebook'
                      ? faFacebook
                      : faTelegram
                  }
                  size="2x"
                  style={{ color: '#429EEF' }}
                />
              </div>
              <InputUI
                icon={iconStates[index] ? "check" : "copy"}
                icon_direction="right"
                value={
                  referralCode !== undefined
                    ? `${BASE_URL}/sign-up?code=${referralCode}`
                    : "None data"
                }
                iconStyles={{ cursor: "pointer" }}
                ref={(el) => { inputRefs.current[index] = el; }}
                isChangeView={viewStates[index]}
                onClick={() => handleCopy(index)}
              />
              <Box sx={s.buttonOverlay}>
                <Link
                    to={`${item.link} ${item.title === 'Facebook' 
                        ? `${BASE_URL}/sign-up?code=${referralCode}`
                        : encodeURIComponent(`${postText}`)
                    }`}
                      target="_blank"
                      rel="noopener noreferrer"
                >
                  <ButtonUI type="outline" text="Share" />
                </Link>
              </Box>
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );
};
