import {Dispatch, SetStateAction, useEffect, useState} from "react";
import styles from "./styles";
import {
  Box,
  Typography,
} from "@mui/material";
import { ButtonUI, InputUI } from "../../../ui";
import {getProduct} from "../../../../app/slices/products";
import {addCustomField} from "../../../../app/slices/field";
import {Form, Formik} from "formik";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

interface Props {
  setOpenModal: (openModal: boolean) => void;
  productId: string | undefined,
  setUpdateFields: Dispatch<SetStateAction<boolean>>,
  index: string,
  canCreateButton: boolean,
}

interface SelectDataItem {
  value: string,
  label: string
}

const AddNewField = ({
  setOpenModal,
  productId,
  setUpdateFields,
  index,
  canCreateButton
}: Props) => {
  const [selectData, setSelectData] = useState<SelectDataItem[]>([
    { value: "field", label: "[FIELD]" },
    { value: "button", label: "[BUTTON]" },
    { value: "textarea", label: "[TEXTAREA]" },
  ]);
  const dispatch = useDispatch();
  const { t } = useTranslation()

  const submitHandler = async (values: any) => {
    try {
      console.log(values)
      // @ts-ignore
      dispatch(addCustomField({ productId, ...values , index}))
          .then(() => setOpenModal(false))
          .finally(() => {
            // @ts-ignore
            dispatch(getProduct(productId))
            setUpdateFields(true)
          })

    } catch (e: any) {
      console.log(e);
    }
  };

  useEffect(() => {
    setSelectData(
        canCreateButton
            ? selectData
            : selectData.filter((item) => item.value !== "button")
    )
  }, [canCreateButton])

  return (
    <Formik
      initialValues={{
        type: "field",
        title: "",
        description: ""
      }}
      onSubmit={submitHandler}
    >
    {({ values, handleSubmit, setFieldValue }) => (
      <Form style={{
        width: '100%',
        maxWidth: '532px',
      }}>
        <Box
            sx={styles.container}
            onClick={(e) => {e.stopPropagation()}}
        >
          <Box sx={styles.header}>
            <Typography variant="h6">Add new field</Typography>
            <img
              src="/assets/icons/modal_close.svg"
              onClick={() => setOpenModal(false)}
              alt={''}
              style={{cursor: 'pointer'}}
            />
          </Box>

          <Box sx={styles.content}>
            <InputUI
              label="Type"
              select
              selectData={selectData}
              value={values.type}
              selectHandleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue('type',event.target.value)}
            />

            <InputUI label={t("title")} value={values.title} onChange={
              (event: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('title',event.target.value)}
            />

            {values.type === "textarea" ? (
              <Box sx={{display: 'flex'}}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('description',event.target.value)}
              >
                <InputUI label={t("description")} value={values.description} multiline />
              </Box>
            ) : (
              <Box
                  sx={{display: 'flex'}}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('description',event.target.value)}
              >
                <InputUI label="Content" value={values.description}/>
              </Box>
            )}
          </Box>

          <Box sx={styles.buttons}>
            <ButtonUI
              type="outline"
              text={t("cancel")}
              onClick={() => setOpenModal(false)}
              width="114px"
            />

            <ButtonUI
              type="default"
              text={t("add")}
              onClick={() => {
                handleSubmit()
              }}
              width="114px"
            />
          </Box>
        </Box>
      </Form>
    )}
    </Formik>
  );
};

export default AddNewField;
