export const styles = {
  bar: {
    position: 'relative',
    top:'3px',
    width: "96px",
    height: "4px",
    backgroundColor: '#D8D8D8',
    borderRadius: "10px"
  },
  flexCenter:{
    display:'flex',
    alignItems:'center',
  }
} as const