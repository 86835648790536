export const verificationTransformData = (data: any, rejectedFields: string[]) => {
  return Object.keys(data).reduce((acc: any, key: any) => {
    if (["company_name", "date_of_incorp", "company_reg_number", "companys_website", "employees_have", "business_description"].includes(key)) {
      acc["first_tier"][key] = data[key];

      if (!data[key])
        rejectedFields.push(key)

    } else if (["email", "phone", "primary_contact_name", "position"].includes(key)) {
      acc["second_tier"][key] = data[key];

      if (!data[key])
        rejectedFields.push(key)

    } else if (["country", "address1", "address2", "city", "region", "zip", "a_country", "a_address1", "a_address2", "a_city", "a_region", "a_zip"].includes(key)) {
      acc["third_tier"][key] = data[key];

      if (!data[key])
        rejectedFields.push(key)

    } else if (key === "pdf_file_paths") {
      acc["fourth_tier"][key] = data[key];

      data[key].forEach((value: any, index: number) => {
        const fileKey = `file${index + 1}`;
        if (!value) {
          rejectedFields.push(fileKey)
        }
      });
    }
    return acc;
  }, {"first_tier": {}, "second_tier": {}, "third_tier": {}, "fourth_tier": []});
}