import { Box, Grid2, Typography } from "@mui/material";
import styles from "./styles";
import { useNavigate } from "react-router";
import { useState } from "react";
import { ButtonUI } from "../../../../ui";
import dayjs from "dayjs";
import { Product } from "../../../../../shared/types/instances";
import { useWindowSize } from "@uidotdev/usehooks";
import { ProductMobileCard } from "./ProductMobileCard";
import { StatisticsBar } from "../../../StatisticsBar/StatisticsBar";

interface Props {
  data: Product[];
}

const ProductTab = ({ data }: Props) => {
  const navigate = useNavigate();
  const [showDataLength, setShowDataLength] = useState(10);
  const { width } = useWindowSize();
  const handleNavigate = (id: string) => {
    navigate(`/home/products/${id}`);
    localStorage.setItem("ProductInfoTab", "0");
  };
  return (
    <Box
      sx={{
        display: "flex",
        gap: "16px",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {data.slice(0, showDataLength).map((item: any) =>
        width && width > 1024 ? (
          <Grid2
            key={item._id}
            container
            spacing={4}
            sx={{
              ...styles.card,
              ...(item.status === "draft" && styles.cardDraft),

              "@media (max-width: 1024px)": {
                display: "none",
              },
            }}
            onClick={() => handleNavigate(item._id)}
          >
            <Box
              sx={{
                position: "absolute",
                left: "16px",
                top: "13px",
                width: "96px",
                height: "96px",
                border: "1px solid #F5F5F5",
                borderRadius: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={
                  item.photos[0]
                    ? `https://app.iqtag.io${item.photos[0]}`
                    : "/assets/placeholder.svg"
                }
                alt={item.product_name}
                style={{
                  maxWidth: "72px",
                  maxHeight: "72px"
                }}
              />
            </Box>

            <Grid2 size={5.5} sx={{ paddingLeft: "160px" }}>
              <Box sx={styles.info}>
                <Typography variant="caption" sx={styles.title}>
                  {item.product_name}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    ...styles.subtitle,
                    "@media (max-width: 1024px)": { display: "none" },
                  }}
                >
                  Created {dayjs(item.created_at).format("D MMM YYYY")}
                </Typography>
                <Box
                  sx={{
                    ...styles.tags,
                    "@media (max-width: 1024px)": { display: "none" },
                  }}
                >
                  {item.tags.map((tag: string) => (
                    <Box key={tag} sx={styles.tag}>
                      <Typography variant="caption" sx={styles.subtitle}>
                        {tag}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid2>

            <Grid2 size={1.3}>
              <Box sx={styles.flexCenter}>
                <Typography variant="caption" sx={styles.title}>
                  {new Intl.NumberFormat("en-US").format(item.total)}
                </Typography>
              </Box>
            </Grid2>

            <Grid2
              size={1}
              sx={{
                "@media (max-width: 1024px)": { display: "none" },
              }}
            >
              <Box sx={styles.flexCenter}>
                <Typography variant="caption" sx={styles.title}>
                  {new Intl.NumberFormat("en-US").format(item.total_verified)}
                </Typography>
              </Box>
            </Grid2>

            <Grid2 size={1.3} sx={{ transform: "translateX(-20px)" }}>
              <StatisticsBar
                totalVerified={item.total_verified}
                total={item.total}
                callPlaceIsProductTab
              />
            </Grid2>

            <Grid2
              size={1.2}
              sx={{
                "@media (max-width: 1024px)": { display: "none" },
              }}
            >
              <Box sx={styles.flexCenter}>
                <Typography variant="caption" sx={styles.title}>
                  {new Intl.NumberFormat("en-US").format(item.total_scanned)}
                </Typography>
              </Box>
            </Grid2>

            <Grid2 size={1}>
              {item.feedback_chat.length > 0 ? (
                <Box sx={styles.flexCenter}>
                  <img
                    src="/assets/icons/comment.svg"
                    alt="comments"
                    style={{ marginRight: "8px" }}
                  />
                  <Typography variant="caption" sx={styles.title}>
                    {item.feedback_chat.length > 99
                      ? "99+"
                      : item.feedback_chat.length}
                  </Typography>
                </Box>
              ) : (
                <Box sx={styles.flexCenter}>
                  <img src="/assets/icons/no_comment.svg" alt="no_comment" />
                </Box>
              )}
            </Grid2>

            <Box sx={styles.icon}>
              <img src="/assets/icons/arrow_right.svg" alt="right" />
            </Box>
          </Grid2>
        ) : (
          <ProductMobileCard product={item} />
        )
      )}
      {data.length > 10 && showDataLength < data.length && (
        <ButtonUI
          type="outline"
          text="More"
          onClick={() => setShowDataLength(showDataLength + 10)}
        />
      )}
    </Box>
  );
};

export default ProductTab;
