import {
  Box,
  Button,
  ClickAwayListener,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { CustomTooltip } from "../../../../ui";
import { Link } from "react-router-dom";
import styles from "../../styles";
import IconUI from "../../../../ui/icon";
import React from "react";
import { changeCompany } from "../../../../../app/slices/company";
import { getDashboard } from "../../../../../app/slices/dashboard";
import { setLogo } from "../../../../../app/slices/kyb";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../../../../api/Service";

interface Props {
  handleMenuOpen: () => void;
  handleMenuClose: () => void;
  menu: boolean;
  open?: boolean;
  companies: any;
  handleModalOpen: (state: boolean) => void;
  mobile?: boolean;
}

export const CompaniesBlock = ({
  handleMenuOpen,
  handleMenuClose,
  open,
  menu,
  companies,
  handleModalOpen,
  mobile = false,
}: Props) => {
  const dispatch = useDispatch();

  const current_company_id =
    useSelector((state: any) => state.dashboard.dashboard.Current_Company) ||
    null;
  const company = companies.find(
    (item: any) => item._id === current_company_id
  );

  const logoKyb = useSelector((state: any) => state.kyb.logo_company);

  const handleChangeCompany = async (company_id: string) => {
    try {
      if (company_id !== current_company_id) {
        // @ts-ignore
        await dispatch(changeCompany({ company_id })).finally(() => {
          // @ts-ignore
          dispatch(getDashboard());
          dispatch(setLogo(""));
        });
      }
      handleMenuClose();
    } catch (err: any) {}
  };

  return (
    <ClickAwayListener onClickAway={handleMenuClose}>
      <div>
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px", maxWidth:"180px" }}>
          <CustomTooltip
            open={mobile ? menu : menu || open}
            onClose={handleMenuClose}
            title={
              <Box
                sx={{ position: "relative", transform: "translate(50px, 8px)" }}
              >
                <Box
                  sx={{
                    width: "24px",
                    height: "13px",
                    backgroundColor: "#ffffff",
                    clipPath: "polygon(0 0, 24px 0, 12px 12px)",
                    transform: "rotate(180deg)",
                    position: "absolute",
                    left: "44px",
                    top: "-12px",
                  }}
                ></Box>
                <Box
                  sx={{
                    boxShadow: "0px 0px 16px 0px #E5E5E5",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    width: "227px",
                    color: "#2D2D2D",
                    padding: "0",
                  }}
                >
                  <List>
                    {(companies || [])?.map((item: any, index: number) => (
                      <ListItem disablePadding key={index}>
                        <Link
                          to={"/home/dashboard"}
                          style={{
                            width: "100%",
                            outline: "none",
                            textDecoration: "none",
                          }}
                        >
                          <ListItemButton
                            disableRipple
                            sx={styles.tooltip_item}
                            onClick={() => {
                              handleChangeCompany(item._id);
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontWeight: 400,
                                color: "#2D2D2D",
                              }}
                            >
                              {item.company_name}
                            </Typography>
                            <ListItemIcon sx={{ minWidth: "24px" }}>
                              <IconUI
                                name={
                                  item === company
                                    ? "radioCheck"
                                    : "radioNoCheck"
                                }
                              />
                            </ListItemIcon>
                          </ListItemButton>
                        </Link>
                      </ListItem>
                    ))}

                    <ListItem disablePadding>
                      <ListItemButton
                        disableRipple
                        sx={styles.tooltip_add}
                        onClick={() => {
                          handleMenuClose();
                          handleModalOpen(true);
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: "24px" }}>
                          <IconUI name="circle_plus" />
                        </ListItemIcon>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 400, color: "#2D2D2D" }}
                        >
                          Add company
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Box>
              </Box>
            }
          >
            <Button
              onClick={handleMenuOpen}
              disableRipple
              sx={{
                textTransform: "none",
                minWidth: "100px",
                "&:hover": {
                  backgroundColor: "inherit",
                  boxShadow: "none",
                },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Box
                  sx={{
                    maxWidth: "180px",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {company && logoKyb ? (
                    <img
                      src={`${BASE_URL}/${logoKyb}`}
                      alt={"logo"}
                      style={{
                        cursor: "pointer",
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />
                  ) : (
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        fontSize: "24px",
                        fontWeight: 600,
                        color: "#0E0E0E",
                        maxWidth: "170px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {company?.company_name || ""}
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={
                    menu
                      ? { transform: "rotate(180deg)" }
                      : { transform: "rotate(0deg)" }
                  }
                >
                  <IconUI name="icon-down" size={8} />
                </Box>
              </Box>
            </Button>
          </CustomTooltip>
        </Box>
      </div>
    </ClickAwayListener>
  );
};
