import styles from "../styles";
import {Grid2, Link, Typography} from "@mui/material";
import dayjs from "dayjs";
import IconUI from "../../../../../ui/icon";
import {ButtonUI} from "../../../../../ui";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router";
import {getNumberForTypeOperation, getTextForTypeOperation, rewardValue} from "../HistoryTab";

interface Props {
  type: 'rewards' | 'bills' | 'operations',
  item: any
}

const RewardsValue = ({item}:{item: any}) => {
  if(item.type === 'withdrawal') {
    return <>{`-${new Intl.NumberFormat('en-US').format(item.amount)} EUR`}</>
  } else {
    return <>{`+${new Intl.NumberFormat('en-US').format(item.value)} EUR`}</>
  }
}

export const TabDesktop = ({type, item}: Props) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const checkReferral = useLocation().pathname.includes('referal')

  return (
      <Grid2 container sx={{...styles.item}} columnSpacing={4}>
        <Grid2 size={checkReferral ? 4 : type === 'operations' ? 3.95 : 3.65}>
          <Typography variant="caption" sx={styles.text}>
            {dayjs(item.date).format('DD.MM.YYYY / HH:mm:ss')}
          </Typography>
        </Grid2>
        <Grid2 size={checkReferral ? 5 : type === 'operations' ? 3.55 : 3.15}>
        <Typography
              variant="caption"
              sx={(type === "operations" && String(item.count).startsWith("+")) ? styles.text_green : styles.text}
          >
            {type === "rewards" && rewardValue(item.type, item.description)}
            {type === "bills" && `${t("purchased")} ${new Intl.NumberFormat('en-US').format(item.quantity)} ${t("qr")}`}
            {type === "operations" && getNumberForTypeOperation(item.type, item.count)}
          </Typography>
        </Grid2>
        <Grid2 size={type === 'operations' ? 3 : 2.5}>
          <Typography
              variant="caption"
              sx={(type === "rewards" && item.type === 'reward') ? styles.text_green : styles.text}
          >
            {type === "rewards" && <RewardsValue item={item} />}
            {type === "bills" && `${new Intl.NumberFormat('en-US').format(item.amount)} EUR`}
            {type === "operations" &&
                <>
                  {getTextForTypeOperation(item.type)}
                  <strong>{item.product_name ? item.product_name: item.company_name}</strong>
                </>
            }
          </Typography>
        </Grid2>

        {item.type === 'Sell' &&
            <IconUI
                name={'arrow_right'}
                direction={'right'}
                style={{top:'37.5%',cursor: 'pointer'}}
                onClick={() => navigate(`/home/products/${item.product_id}`)}
            />
        }

        {type === 'bills' &&
            <Grid2
                size={2.5}
                sx={{display: 'flex',
                  justifyContent: 'flex-end',
                  '@media (max-width: 1024px)': {
                    paddingTop:'8px',
                    gridRow: '3',
                    gridColumn: '1/3'
                  }
                }}
            >
              <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.receipt_url}
                  sx={{
                    paddingRight: '75px',
                    '@media (max-width: 1200px)': {
                      paddingRight: '40px',
                    },
                  }}
              >
                <ButtonUI
                    text={t("download")}
                    type={'outline'}
                    iconStyles={{
                      fontSize: '14px',
                      padding: '4px 16px',
                      lineHeight: 'auto',
                      height: 'auto',
                      cursor: 'pointer',
                    }}
                />
              </Link>
            </Grid2>
        }
      </Grid2>
  )
}