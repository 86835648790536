const styles = {
  container: {
    width: '800px',
    padding: "32px",
    background: "#FFFFFF",
    borderRadius: "16px",
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    boxShadow: "0px 0px 16px 0px #E5E5E5",
    boxSizing: 'border-box',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    margin: '0',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    paddingBottom: '32px',
  },
  subtitle: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.01em",
    color: "#5F5F5F",
  },
  bottom: {
    display: 'flex',
    gap: '24px',
    flexDirection: 'column',
    paddingBottom: '32px'
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '0',
    margin: '0'
  },
  listItem: {
    display: 'grid',
    gridTemplateColumns: '70px 1fr 100px',
    columnGap: '24px',
  },
  firstBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '4px',

    '& img' : {
      filter: 'red'
    }
  },
  buttonOverlay: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  paragraph: {
    margin: '-10px 0 0 0',

  }
} as const

export default styles