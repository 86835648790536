import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import { ChangeEvent, useMemo, useState } from 'react'
import NoData from '../NoData/NoData'
import { checkNoDataDescription, CustomTabPanel } from '../../../../helpers'
import styles from './styles'
import ProductTab from './List/ProductTab'
import { ModalOverlay } from '../../../../helpers/ModalOverlayClick'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useSelector } from 'react-redux'
import { createPortal } from 'react-dom'
import { CreateNewCompany } from '../../Modals'
import ProductFilters from '../../Filters/ProductFilters'
import { Product, SortConfig, SortOrder } from '../../../../shared/types/instances'
import { sortProducts } from '../../../../shared/utils/sort-products'
import AddNewProductDetail from '../../Modals/AddNewProductDetail'
import { ITEMS_PER_PAGE } from '../../../../shared/constants'
import PaginationUI from '../../../ui/pagination'
import {useTranslation} from "react-i18next";

interface Props {
  hasPagination?: boolean;
  activeTagsIndex?: number[];
  searchValue?: string;
}

const ProductsList = ({ hasPagination, activeTagsIndex = [], searchValue }: Props) => {
  const {t} = useTranslation()

  const { products, companies } = useSelector((state: any) => state.dashboard)
  const path = window.location.pathname

  const [ tab, setTab ] = useState(0)
  const [ isOpen, setIsOpen ] = useState(true)
  const [ openCreateCompany, setOpenCreateCompany ] = useState(false)
  const [ openCreateProduct, setOpenCreateProduct ] = useState(false)

  const [ sortConfig, setSortConfig ] = useState<SortConfig>({ key: 'date', order: 'desc' })
  const [ filter, setFilter ] = useState({
    date: { value: 'desc' as SortOrder, sortable: true },
    qrcode: { value: 'asc' as SortOrder, sortable: true },
    verified: { value: 'asc' as SortOrder, sortable: true },
    scanned: { value: 'asc' as SortOrder, sortable: true },
    feedback: { value: 'asc' as SortOrder, sortable: true }
  })

  const tags = Array.from(
      new Set(
          useSelector((state: any) =>
              state.dashboard.products.map((item: any) => item.tags)
          ).flat()
      )
  ) as string[];

  const [ page, setPage ] = useState(1)

  const handleSortChange = (field: keyof typeof filter) => {
    setFilter((prev) => {
      const currentValue = prev[field].value
      let newValue: SortOrder = 'asc'

      if (currentValue === 'asc') newValue = 'desc'
      else if (currentValue === 'desc') newValue = 'asc'

      const updatedFilter = {
        date: { ...prev.date, value: 'none' as SortOrder },
        qrcode: { ...prev.qrcode, value: 'none' as SortOrder },
        verified: { ...prev.verified, value: 'none' as SortOrder },
        scanned: { ...prev.scanned, value: 'none' as SortOrder },
        feedback: { ...prev.feedback, value: 'none' as SortOrder }
      }

      updatedFilter[field].value = newValue

      let key = ''
      if (field === 'date') key = 'date'
      if (field === 'qrcode') key = 'qrcodes'
      if (field === 'verified') key = 'verified'
      if (field === 'scanned') key = 'scanned'
      if (field === 'feedback') key = 'feedbacks'

      setSortConfig({ key, order: newValue })
      return updatedFilter
    })
  }

  const sortedProducts = useMemo(() => {
    if (!products) return []
    return sortProducts(products, sortConfig)
  }, [ products, sortConfig ])

  const filteredProducts = useMemo(() => {
    let result: Product[] = sortedProducts as Product[]

    if (searchValue && searchValue.trim() !== '') {
      const lowerSearch = searchValue.toLowerCase()
      result = result.filter(product =>
        product.product_name.toLowerCase().includes(lowerSearch)
      )
    }

    if (activeTagsIndex.length > 0) {
      const lowerActiveTags = activeTagsIndex.map((index: number) =>
        tags[index].toLowerCase()
      )
      console.log(tags[0])

      result = result.filter((product) =>
        product.tags.some((tag) =>
          lowerActiveTags.includes(tag.toLowerCase())
        )
      )
    }

    return result
  }, [ sortedProducts, searchValue, activeTagsIndex ])

  const paginatedProducts = useMemo(() => {
    if (!hasPagination) return filteredProducts
    return filteredProducts.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)
  }, [ filteredProducts, page, hasPagination ])

  const handleChangePage = (_: ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  return (
    <Accordion variant="outlined" sx={styles.container} expanded={isOpen}>
      <AccordionSummary
        sx={styles.header}
        expandIcon={<ExpandMoreIcon onClick={() => setIsOpen(!isOpen)} style={{cursor: 'pointer'}}/>}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Box
          sx={{
            '@media (max-width: 1024px)': {
              flexDirection: 'column',
              alignItems: 'start'
            },
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Typography variant="h6">
            {activeTagsIndex?.length ? `
            Products for tag ${activeTagsIndex.map((index: number) => ` "${tags[index]}"`)}` :
              'Products'}
          </Typography>
          {/*{isOpen && (*/}
          {/*  <Box sx={{ paddingRight: '32px' }}>*/}
          {/*    <CustomTabs value={tab} handleChange={handleChange} tabs={tabs}/>*/}
          {/*  </Box>*/}
          {/*)}*/}
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <ProductFilters
          filter={filter}
          sortConfig={sortConfig}
          onChangeSort={handleSortChange}
        />

        <Box>
          {companies.length > 0 ? (
            paginatedProducts.length > 0 ? (
              <Box>
                <CustomTabPanel value={tab} index={0}>
                  <ProductTab data={paginatedProducts}/>
                </CustomTabPanel>
                {hasPagination && filteredProducts.length > ITEMS_PER_PAGE && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '32px' }}>
                    <PaginationUI
                      count={Math.ceil(filteredProducts.length / ITEMS_PER_PAGE)}
                      page={page}
                      onChange={handleChangePage}
                    />
                  </Box>
                )}
              </Box>
            ) : (
              <NoData
                buttonText="Create product"
                description={checkNoDataDescription('dashboard')}
                onClick={() => {
                  setOpenCreateProduct(!openCreateProduct)
                }}
              />
            )
          ) : (
            <NoData
              buttonText={t("create_company")}
              description={checkNoDataDescription(path)}
              onClick={() => {
                setOpenCreateCompany(!openCreateCompany)
              }}
            />
          )}
        </Box>
      </AccordionDetails>
      {openCreateCompany &&
        createPortal(
          <ModalOverlay onClose={() => setOpenCreateCompany(false)} openModal={openCreateCompany}>
            <CreateNewCompany setOpenModal={setOpenCreateCompany}/>
          </ModalOverlay>,
          document.getElementById('portal') as HTMLElement
        )}

      {openCreateProduct &&
        createPortal(
          <ModalOverlay onClose={() => setOpenCreateProduct(false)} openModal={openCreateProduct}>
            <AddNewProductDetail
              setOpenModal={setOpenCreateProduct}
            />
          </ModalOverlay>,
          document.getElementById('portal') as HTMLElement
        )}
    </Accordion>
  )
}

export default ProductsList
