import {Box, Button, List, ListItem, ListItemButton, ListItemIcon, Typography} from "@mui/material";
import {FlagImage} from "react-international-phone";
import IconUI from "../../../../ui/icon";
import {CustomTooltip} from "../../../../ui";
import React from "react";
import {HeaderMenu} from "../../../../../shared/types/instances";

interface LanguageMenu extends HeaderMenu {
  handleAllClose: () => void;
  changeLanguage: (code: string) => void,
  lang: string,
  isMobile?: boolean,
}

export const LanguageBlock = ({
   handleMenuClose,
   handleMenuOpen,
   menu,
   open,
   handleAllClose,
   changeLanguage,
   lang, 
   isMobile = false,
}: LanguageMenu) => {
  return (
      <CustomTooltip
          open={menu || open}
          onClose={handleMenuClose}
          title={
            <Box 
                sx={{
                  position: 'relative', 
                  transform: isMobile ? "translate(20px, 10px)" : "translate(-32px, 52px)",
                }}
            >
              <Box sx={{
                width: "24px",
                height: "13px",
                backgroundColor: "#ffffff",
                clipPath: "polygon(0 0, 24px 0, 12px 12px)",
                transform: "rotate(180deg)",
                position: 'absolute',
                left: "16px",
                top: "-12px"
              }}>

              </Box>
              <Box sx={{
                boxShadow: "0px 0px 16px 0px #E5E5E5",
                backgroundColor: '#FFFFFF',
                borderRadius: "10px",
                width: '109px',
                color: "#2D2D2D",
                padding: "0",
              }}>
                <List>
                  <ListItem disablePadding>
                    <ListItemButton
                        disableRipple
                        sx={{
                          textDecoration: 'none',
                          padding: "8px 16px",
                          display: 'flex',
                          alignItems: 'center',
                          gap: "16px"
                        }}
                        onClick={() => {
                          changeLanguage("EN");
                          handleAllClose();
                        }}
                    >
                      <ListItemIcon sx={{minWidth: "24px"}}>
                        <FlagImage iso2="gb" />
                      </ListItemIcon>
                      <Typography variant="subtitle1" sx={{fontWeight: 500, color: "#2D2D2D"}}>
                        EN
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                        disableRipple
                        sx={{
                          textDecoration: 'none',
                          padding: "8px 16px",
                          display: 'flex',
                          alignItems: 'center',
                          gap: "16px"
                        }}
                        onClick={() => {
                          changeLanguage("CZ");
                          handleAllClose();
                        }}
                    >
                      <ListItemIcon sx={{minWidth: "24px"}}>
                        <FlagImage iso2="cz" />
                      </ListItemIcon>
                      <Typography variant="subtitle1" sx={{fontWeight: 500, color: "#2D2D2D"}}>
                        CZ
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Box>
            </Box>
          }
      >
        <Button
            onClick={handleMenuOpen}
            disableRipple
            sx={{textTransform: 'none'}}
        >
          <Box sx={{display: 'flex', alignItems: 'center', gap: "8px"}}>
            <Typography variant="subtitle1" sx={{fontWeight: 400, color: "#2D2D2D"}}>{lang}</Typography>
            <Box sx={menu ? {transform: 'rotate(180deg)'} : {transform: 'rotate(0deg)'}}>
              <IconUI name="icon-down" size={8}/>
            </Box>
          </Box>
        </Button>

      </CustomTooltip>
  )
}