import {Box, FormControl, Grid2, Typography} from "@mui/material"
import styles from "./styles"
import IconUI from "../../ui/icon";
import {SortConfig, SortOrder} from "../../../shared/types/instances";
import {getSortIcon} from "../../../shared/utils/get-sort-icon";
import {useTranslation} from "react-i18next";
import { useWindowSize } from "@uidotdev/usehooks";

interface FilterState {
  date: { value: SortOrder; sortable: boolean };
  description: { value: SortOrder; sortable: boolean };
  names: { value: SortOrder; sortable: boolean };
  count: { value: SortOrder; sortable: boolean };
  quantity: { value: SortOrder; sortable: boolean };
  values: { value: SortOrder; sortable: boolean };
  amounts: { value: SortOrder; sortable: boolean };
}

interface Props {
  type: 'rewards' | 'bills' | 'operations',
  filter: FilterState;
  sortConfig: SortConfig;
  onChangeSort: (field: keyof FilterState) => void;
}

const HistoryFilters = ({type, filter, sortConfig, onChangeSort}: Props) => {
  const {t} = useTranslation()
  const check = window.location.pathname.includes('referal')
  const {width} = useWindowSize();  
  const isMobile = width && width < 1024;

  return (
      <FormControl
          variant="outlined"
          size="small"
          sx={{width: "100%"}}
      >
        {
            type === 'rewards' && (
                <Grid2 container sx={{padding:`${isMobile ? '16px' : '32px'}`}} spacing={4}>
                  <Grid2 size={check ? 4 : 4.5} sx={{display: 'flex', alignItems: 'flex-start'}}>
                    <Box sx={styles.filterWrapper(true)} onClick={() => onChangeSort('date')}>
                      <Typography variant="caption" sx={styles.filterLabel}>
                        {t("date")}
                      </Typography>
                      {filter.date.sortable && (
                          <IconUI
                              name={getSortIcon('date', sortConfig)}
                              size={16}
                          />
                      )}
                    </Box>
                  </Grid2>

                  <Grid2 size={check ? 4.9 : 3} sx={{display: 'flex', alignItems: 'flex-start'}}>
                    <Box sx={styles.filterWrapper(true)} onClick={() => onChangeSort('description')}>
                      <Typography variant="caption" sx={styles.filterLabel}>
                        {t("description")}
                      </Typography>
                      {filter.description.sortable && (
                          <IconUI
                              name={getSortIcon('description', sortConfig)}
                              size={16}
                          />
                      )}
                    </Box>
                  </Grid2>

                  <Grid2 size={3.1} sx={{display: 'flex', alignItems: 'flex-start'}}>
                    <Box sx={styles.filterWrapper(true)} onClick={() => onChangeSort('values')}>
                      <Typography variant="caption" sx={styles.filterLabel}>
                        {t("rewards")}
                      </Typography>
                      {filter.values.sortable && (
                          <IconUI
                              name={getSortIcon('values', sortConfig)}
                              size={16}
                          />
                      )}
                    </Box>
                  </Grid2>
                </Grid2>
            )
        }

        {
            type === 'bills'  && (
                <Grid2 container columnSpacing={4} sx={{padding: '32px'}}>
                  <Grid2 size={check ? 2.5 : 3.65} sx={{display: 'flex', alignItems: 'flex-start'}}>
                    <Box sx={styles.filterWrapper(true)} onClick={() => onChangeSort('date')}>
                      <Typography variant="caption" sx={styles.filterLabel}>
                        {t("date")}
                      </Typography>
                      {filter.date.sortable && (
                          <IconUI
                              name={getSortIcon('date', sortConfig)}
                              size={16}
                          />
                      )}
                    </Box>
                  </Grid2>

                  <Grid2 size={check ? 4 : 3.15} sx={{display: 'flex', alignItems: 'flex-start'}}>
                    <Box sx={styles.filterWrapper(true)} onClick={() => onChangeSort('quantity')}>
                      <Typography variant="caption" sx={styles.filterLabel}>
                        {t("description")}
                      </Typography>
                      {filter.quantity.sortable && (
                          <IconUI
                              name={getSortIcon('quantity', sortConfig)}
                              size={16}
                          />
                      )}
                    </Box>
                  </Grid2>

                  <Grid2 size={2.5} sx={{display: 'flex', alignItems: 'flex-start'}}>
                    <Box sx={styles.filterWrapper(true)} onClick={() => onChangeSort('amounts')}>
                      <Typography variant="caption" sx={styles.filterLabel}>
                        {t("price")}
                      </Typography>
                      {filter.amounts.sortable && (
                          <IconUI
                              name={getSortIcon('amounts', sortConfig)}
                              size={16}
                          />
                      )}
                    </Box>
                  </Grid2>

                  <Grid2 size={2.5}>
                    <div>

                    </div>
                  </Grid2>
                </Grid2>
            )
        }

        {
            type === 'operations' && (
                <Grid2 container spacing={4} sx={{padding: '32px'}}>
                  <Grid2 size={4} sx={{display: 'flex', alignItems: 'flex-start'}}>
                    <Box sx={styles.filterWrapper(true)} onClick={() => onChangeSort('date')}>
                      <Typography variant="caption" sx={styles.filterLabel}>
                        {t("date")}
                      </Typography>
                      {filter.date.sortable && (
                          <IconUI
                              name={getSortIcon('date', sortConfig)}
                              size={16}
                          />
                      )}
                    </Box>
                  </Grid2>

                  <Grid2 size={3.5} sx={{display: 'flex', alignItems: 'flex-start'}}>
                    <Box sx={styles.filterWrapper(true)} onClick={() => onChangeSort('count')}>
                      <Typography variant="caption" sx={styles.filterLabel}>
                        Amount
                      </Typography>
                      {filter.count.sortable && (
                          <IconUI
                              name={getSortIcon('count', sortConfig)}
                              size={16}
                          />
                      )}
                    </Box>
                  </Grid2>

                  <Grid2 size={3} sx={{display: 'flex', alignItems: 'flex-start'}}>
                    <Box sx={styles.filterWrapper(true)} onClick={() => onChangeSort('names')}>
                      <Typography variant="caption" sx={styles.filterLabel}>
                        {t("description")}
                      </Typography>
                      {filter.names.sortable && (
                          <IconUI
                              name={getSortIcon('names', sortConfig)}
                              size={16}
                          />
                      )}
                    </Box>
                  </Grid2>
                </Grid2>
            )
        }
      </FormControl>
  )
}

export default HistoryFilters