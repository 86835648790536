import {Box, Button, List, ListItem, ListItemButton, ListItemIcon, Typography} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {setMenuItem} from "../../../../../app/slices/drawer";
import IconUI from "../../../../ui/icon";
import {CustomTooltip} from "../../../../ui";
import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {HeaderMenu} from "../../../../../shared/types/instances";


export const QrBlock = ({handleMenuClose, handleMenuOpen, menu, open}: HeaderMenu) => {
  const dispatch = useDispatch();
  const {t} = useTranslation()
  const companies = useSelector((state: any) => state.dashboard.companies);
  const user = useSelector((state: any) => state.dashboard.user);
  const current_company_id = useSelector((state: any) => state.dashboard.dashboard.Current_Company) || null;
  const company = companies.find((item: any) => item._id === current_company_id);
  return (
      <CustomTooltip
          open={menu || open}
          onClose={handleMenuClose}
          title={
            <Box sx={{position: 'relative', transform: "translate(-40px, 52px)"}}>
              <Box sx={{
                width: "24px",
                height: "13px",
                backgroundColor: "#ffffff",
                clipPath: "polygon(0 0, 24px 0, 12px 12px)",
                transform: "rotate(180deg)",
                position: 'absolute',
                right: "44px",
                top: "-12px"
              }}>

              </Box>
              <Box sx={{
                boxShadow: "0px 0px 16px 0px #E5E5E5",
                backgroundColor: '#FFFFFF',
                borderRadius: "10px",
                width: '227px',
                color: "#2D2D2D",
                padding: "0",
              }}>
                <List>
                  {/* <Link to="/home/top-up" style={{textDecoration: 'none'}}
                        onClick={() => {
                          if(company.kyb_status !== 'approved'){
                            dispatch(setMenuItem('/top-up'))
                          }
                        }}
                        > */}
                    <ListItem disablePadding>
                      <ListItemButton
                          disabled={company?.kyb_status !== 'approved'}
                          disableRipple
                          sx={{
                            textDecoration: 'none',
                            padding: "8px 16px",
                            display: 'flex',
                            alignItems: 'center',
                            gap: "16px"
                          }}
                          onClick={()=>{
                            window.location.replace('/home/top-up')
                            dispatch(setMenuItem('/top-up'))
                            handleMenuClose()
                          }}
                      >
                        <ListItemIcon sx={{minWidth: "24px"}}>
                          <IconUI name="topup"/>
                        </ListItemIcon>
                        <Typography variant="subtitle1" sx={{fontWeight: 500, color: "#2D2D2D"}}>
                          {`Top Up ${t("qr_balance")}`}
                        </Typography>
                      </ListItemButton>

                    </ListItem>
                  {/* </Link> */}
                </List>
              </Box>
            </Box>
          }
      >
        <Button
            onClick={handleMenuOpen}
            disableRipple
            sx={{textTransform: 'none'}}
        >

          <Box sx={{display: 'flex', alignItems: 'center', gap: "16px", width: '100%'}}>
            <Box sx={{flexGrow: 1}}>
              <Typography variant="subtitle1" sx={{
                fontWeight: 500,
                textAlign: 'right',
                color: "#2D2D2D"
              }}>{user.qr_balance}</Typography>
              <Typography variant="subtitle2"
                          sx={{textAlign: 'right', color: "#5F5F5F"}}>{t("qr_balance")}</Typography>
            </Box>
            <Box sx={{
              backgroundColor: '#000',
              borderRadius: '50px',
              padding: "12px",
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <IconUI name="qrcode"/>
            </Box>
            <Box sx={menu ? {transform: 'rotate(180deg)'} : {transform: 'rotate(0deg)'}}>
              <IconUI name="icon-down" size={8}/>
            </Box>

          </Box>
        </Button>

      </CustomTooltip>
  )
}