const styles = {
  tabs: {
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'none',
    display: 'flex',
    gap: '32px',
    padding: "0",
    ' & .MuiTabs-flexContainer': {
      gap: '32px',
      '& .MuiTabs-indicator': {
        width: '16px !important',
      },
    },
    '& .MuiTouchRipple-root': {
      padding: 0,
      textAlign: 'center !important',
    },
    '& .MuiTabs-indicator': {
      display: 'none'
    },
    '& .MuiTabs-indicatorSpan': {
      backgroundColor: '#FF8800',
      color: "#FF8800",
      width: '16px',
    },
    '& .Mui-selected': {
      color: "#FF8800 !important",
      minWidth: 'max-content',
    },
    '& .Mui-selected::after': {
      content: "''",
      position: "absolute",
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
      width: "16px",
      height: "4px",
      backgroundColor: "#FF8800",
      borderRadius: "10px !important"
    },
    '& .MuiTab-root': {
      minWidth: 'max-content',
    },
    '@media(max-width:360px)': {
      '& .MuiButtonBase-root':{
        fontSize: '12px',
      }

    }
  },
  tab: {
    padding: 0,
    textTransform: 'none',
    '& .Mui-selected': {
      color: "#FF8800"
    },
  }
} as const

export default styles