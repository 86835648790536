import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {handleApiError} from "../../helpers/handleApiError";
import Bills from "../../api/repositories/bills";

const initialState = {
  billsData: [],
  operationsData: [],
};

export const getBillsOperationsData = createAsyncThunk(
    "bills/getBillsOperationsData",
    async () => {
      try {
        const response = await Bills.get_bills_operations_data();
        return response.data;

      } catch (error) {
        handleApiError(error)
      }
    },
);


const billsSlice = createSlice({
  name: "bills",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(getBillsOperationsData.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getBillsOperationsData.fulfilled, (state, { payload }) => {
          state.isLoading = false;

          if(payload) {
            state.billsData = payload.Event.bills
            state.operationsData = payload.Event.operations
          }
        })
  },
});

export default billsSlice.reducer;