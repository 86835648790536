const styles = {
  item: {
    position: 'relative',
    padding: '32px 0',
    borderBottom: "solid 1px #F5F5F5",
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '75% 25%',
    gridGap: '8px',
  },
  text: {
    fontSize: "14px",
    color: "#5F5F5F"
  },
  text_green: {
    color: '#00C06A',
    fontSize: "14px"
  }
}

export default styles