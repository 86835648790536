import {Card, CardActions, CardContent, CardMedia, Typography} from "@mui/material"
import {ButtonUI} from "../../../ui"
import {Link} from "react-router-dom";

interface Props {
  onClick?: () => void
  description?: string
  buttonText?: string,
  icon?: string,
  link?: string,
  isNotification?: boolean,
}

const NoData = ({ onClick, buttonText, description, icon = 'no_data', link, isNotification = false}: Props) => {

  return (
    <Card variant="outlined" sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '32px',
      justifyContent: 'center',
      border: "none",
      alignItems: 'center',
      padding: isNotification ? '24px 16px 16px 16px' : '64px',
      borderRadius: "16px",
    }}>
      <CardMedia
        sx={{ width: '88px', height: '88px' }}
        image={`/assets/icons/${icon}.svg`}
      />
      <CardContent
          sx={{
            textAlign: 'center',
            display: 'flex',
            gap: "16px",
            flexDirection: 'column',
            padding: isNotification ? '0px' : '0 32px'
      }}>
        <Typography variant="h6">No Data</Typography>
        <Typography sx={{
          color: "#5F5F5F",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "24px",
          letterSpacing: "1%",
        }} variant="caption">
          {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
        </Typography>
      </CardContent>

      {
        buttonText && buttonText !== '' &&
        < CardActions >
          { link
              ? <Link to={link}>
                <ButtonUI type="default" text={buttonText} onClick={onClick}/>
              </Link>
              : <ButtonUI type="default" text={buttonText} onClick={onClick}/>
          }
        </CardActions>
      }
    </Card >
  )
}

export default NoData