import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Products from "../../api/repositories/products";
import {handleApiError} from "../../helpers/handleApiError";
import {toast} from "react-toastify";

const initialState = {
  qr: {},
  isLoading: false,
};

export const addQrCodes = createAsyncThunk(
    "products/addQrCodes",
    async (payload) => {
      try {
        const response = await Products.add_qr_codes(
            {
              product_id: payload.productId,
              count : payload.qr_amount,
              color: payload.color,
              type : "ALL"
            });

        return response.data;
      } catch (error) {
        if(error.response.data.detail.Message === 'Dont have balance')
          toast.error("You don't have enough QR codes on your balance to purchase")
        else
          handleApiError(error)
      }
    },
);

const qrsSlice = createSlice({
  name: "qr",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(addQrCodes.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(addQrCodes.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          state.qr = payload;
        });
  },
});

export default qrsSlice.reducer;