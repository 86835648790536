import { Box, Button, ClickAwayListener, List, ListItem, ListItemButton, ListItemIcon, MenuItem, Select, Typography } from "@mui/material";
import { setAnalyticsTime, setAnalyticsView } from "../../../../../app/slices/analytics";
import { useDispatch, useSelector } from "react-redux";

import { CustomTooltip } from "../../../../ui";
import IconUI from "../../../../ui/icon";
import styles from "./styles";
import { useState } from "react";
import {useTranslation} from "react-i18next";
import { useEffect } from "react";
const AnalyticHeader = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const analyticsView = useSelector((state: any) => state.analytics.analyticsView);
  const [time, setTime] = useState(t("week"));
  useEffect(() => {
    // Установить начальное значение времени в Redux
    dispatch(setAnalyticsTime(7)); // 7 дней
  }, [dispatch]);

  const handleChangeTime = (time: string) => {

    const valueToTime = (time: string) => {
      switch (time) {
        case t("week"):
          return 7;
        case t("month"):
          return 30;
        case t("year"):
          return 360;
        default:
          return 30;
      }
    };

    setTime(time);
    dispatch(setAnalyticsTime(valueToTime(time)));
  };

  const [open, setOpen] = useState(false);
  const [openGraphMenu, setOpenGraphMenu] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setOpenGraphMenu(false);
  };

  const handleGraphMenuOpen = () => {
    setOpenGraphMenu(true);
  };

  const handleGraphMenuClose = () => {
    setOpenGraphMenu(false);
  };

  const setAnalytics = (variant: string) => {
    dispatch(setAnalyticsView(variant));
    handleClose();
  };

 
  return (
    <Box sx={styles.container}>
      <Typography variant="h6" sx={styles.title}>
        QR code
      </Typography>
      <Box sx={styles.selectContainer}>

        <Select
          value={time}
          defaultValue={t("week")}
          onChange={(event) => handleChangeTime(event.target.value)}
          sx={styles.customSelector}
        >
          <MenuItem value={t("year")}>
            <Typography variant="caption" sx={styles.selector}>{t("year")}</Typography>
          </MenuItem>
          <MenuItem value={t("month")}>
            <Typography variant="caption" sx={styles.selector}>{t("month")}</Typography>
          </MenuItem>
          <MenuItem value={t("week")}>
            <Typography variant="caption" sx={styles.selector}>{t("week")}</Typography>
          </MenuItem>
        </Select>
        {/* 
        <Select
          defaultValue="all"
          onChange={() => handleChangeTime}
          sx={styles.customSelector}
        >
          <MenuItem value="all">
            <Typography variant="caption" sx={styles.selector}>All</Typography>
          </MenuItem>
          <MenuItem value="fr">
            <Typography variant="caption" sx={styles.selector}>FR</Typography>
          </MenuItem>
        </Select> */}

        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: "16px" }}>

              <CustomTooltip
                open={openGraphMenu || open}
                onClose={handleGraphMenuClose}
                title={
                  <Box sx={{ position: 'relative', transform: "translate(-60px, 8px)" }}>
                    <Box sx={styles.triangle}>

                    </Box>
                    <Box sx={styles.tooltip_container}>
                      <List >
                        <ListItem disablePadding>
                          <ListItemButton
                            disableRipple
                            sx={styles.tooltip_item}
                            onClick={() => setAnalytics("Graph")}
                          >
                            <Typography variant="subtitle1" sx={{ fontWeight: 400, color: "#2D2D2D", padding: '0'}}>Graph</Typography>
                            <ListItemIcon sx={{ minWidth: "24px" }}>
                              <IconUI name={analyticsView === "Graph" ? "radioCheck" : "radioNoCheck"} />
                            </ListItemIcon>

                          </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                          <ListItemButton
                            disableRipple
                            sx={styles.tooltip_item}
                            onClick={() => setAnalytics("Map")}
                          >
                            <Typography variant="subtitle1" sx={styles.text}>Map</Typography>
                            <ListItemIcon sx={{ minWidth: "24px" }}>
                              <IconUI name={analyticsView === "Map" ? "radioCheck" : "radioNoCheck"} />
                            </ListItemIcon>
                          </ListItemButton>
                        </ListItem>

                      </List>
                    </Box>
                  </Box>
                }
              >
                <Button
                  onClick={handleGraphMenuOpen}
                  disableRipple
                  sx={{ textTransform: 'none', minWidth: "100px" ,padding: '0'}}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
                    <Typography variant="subtitle2" sx={styles.text}>{analyticsView}</Typography>
                    <Box sx={openGraphMenu ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }}>
                      <IconUI name="icon-down" size={8} />
                    </Box>
                  </Box>
                </Button>

              </CustomTooltip>
            </Box>
          </div>
        </ClickAwayListener>

      </Box>

    </Box>
  );
};

export default AnalyticHeader;