import {Tab, Tabs} from "@mui/material";
import styles from "./styles"

interface TabsType {
  label: string,
  disabled: boolean
}

interface CustomTabsProps {
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
  tabs: TabsType[] | string[]
}

const  CustomTabs = ({ value, handleChange, tabs }: CustomTabsProps) => {
  function setTabs(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
    <Tabs value={value} onChange={handleChange} sx={styles.tabs} onClick={(event) => event.stopPropagation()}>
      {
        tabs.map((tab, index) => {
          const isTabObject = typeof tab === "object" && "label" in tab;
          return (
            <Tab
              key={index}
              sx={styles.tab}
              disableRipple
              label={isTabObject ? tab.label : tab}
              disabled={isTabObject ? tab.disabled : false}
              {...setTabs(index)}
            />
          )
        })
      }
    </Tabs>
  )
}

export default CustomTabs