export const maskEmail = (email: string) => {
  if(email){
    const [localPart, domainPart] = email.split('@');
    const [_, ...restDomainParts] = domainPart.split('.');

    const maskedLocalPart = localPart.slice(0, 3) + '***';
    const maskedFirstDomainPart = '***';
    const maskedDomain = [maskedFirstDomainPart, ...restDomainParts].join('.');

    return `${maskedLocalPart}@${maskedDomain}`;
  }
  return ''
}