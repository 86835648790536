const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '32px 32px 16px 32px',
  },
  selectContainer: {
    display: 'flex',
    gap: '32px',
    alignItems: 'center',
  },
  selector: { fontSize: "14px", fontWeight: 500, textAlign: 'right', color: "#5F5F5F", paddingRight: "8px" },
  customSelector: {
    boxShadow: "none",
    color: "#5F5F5F",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
      border: 0,
    },
    '& .MuiSelect-select': {
      padding: "0 24px 0 0 !important"
    }
  },
  title: {
    fontSize: '16px',
    fontWeight: '500',
  },
  triangle: {
    width: "24px",
    height: "13px",
    backgroundColor: "#ffffff",
    clipPath: "polygon(0 0, 24px 0, 12px 12px)",
    transform: "rotate(180deg)",
    position: 'absolute',
    right: "16px",
    top: "-12px"
  },
  tooltip_container: {
    boxShadow: "0px 0px 16px 0px #E5E5E5",
    backgroundColor: '#FFFFFF',
    borderRadius: "10px",
    width: '164px',
    color: "#2D2D2D",
    padding: "0",

  },
  tooltip_item: {
    textDecoration: 'none',
    padding: "8px 16px",
    display: 'flex',
    alignItems: 'center',
    gap: "16px",
    justifyContent: "space-between",

  },
  text: {
    fontWeight: 400,
    color: "#2D2D2D",
  }
}
export default styles