import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import {useEffect, useLayoutEffect, useMemo, useState} from "react";
import CustomTabs from "../../Tabs";
import styles from "./styles";
import NoData from "../NoData/NoData";
import { checkNoDataDescription, CustomTabPanel } from "../../../../helpers";
import { HistoryFilters } from "../../Filters";
import HistoryTab from "./List/HistoryTab";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useDispatch, useSelector} from "react-redux";
import {getHistoryData} from "../../../../app/slices/referral";
import {getBillsOperationsData} from "../../../../app/slices/bills";
import {SortConfig, SortOrder} from "../../../../shared/types/instances";
import {sortProducts} from "../../../../shared/utils/sort-products";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router";
import {useWindowSize} from "../../../../shared/hooks/useWindowSize";

interface Props {
  type?: 'rewards' | 'purchases' | 'operations' | 'notifications'
}

const History = ({ type }: Props) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()  
  const {pathname} = useLocation()
  const {width} = useWindowSize();

  const [tab, setTab] = useState(0);
  const tabs = [t("bills"), "Operations"]

  const [isOpen, setIsOpen] = useState(true)

  const menu_item = useSelector((state: any) => state.drawer.menu_item)

  const [data, setData] = useState([])

  const rewards = useSelector((state:any) => state.referral.historyData)
  const operations = useSelector((state:any) => state.bills.operationsData)
  const bills = useSelector((state:any) => state.bills.billsData)

  const [ sortConfig, setSortConfig ] = useState<SortConfig>({ key: 'date', order: 'desc' })
  const [ filter, setFilter ] = useState({
    date: { value: 'desc' as SortOrder, sortable: true },
    description: { value: 'asc' as SortOrder, sortable: true },
    quantity: { value: 'asc' as SortOrder, sortable: true },
    count: { value: 'asc' as SortOrder, sortable: true },
    values: { value: 'asc' as SortOrder, sortable: true },
    names: { value: 'asc' as SortOrder, sortable: true },
    amounts: { value: 'asc' as SortOrder, sortable: true }
  })

  const handleSortChange = (field: keyof typeof filter) => {
    setFilter((prev) => {
      const newOrder: SortOrder = prev[field].value === 'asc' ? 'desc' : 'asc';

      const updatedFilter = Object.keys(prev).reduce((acc, key) => {
        acc[key as keyof typeof filter] = {
          ...prev[key as keyof typeof filter],
          value: key === field ? newOrder : 'asc',
        };
        return acc;
      }, {} as typeof filter);

      setSortConfig({ key: field, order: newOrder });
      return updatedFilter;
    });
  }

  const sortedProducts = useMemo(() => {
    if (!data) return []
    return sortProducts(data, sortConfig)
  }, [ data, sortConfig])

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  useLayoutEffect(() => {
    if(pathname === '/home/referal' && rewards.length === 0) {
      // @ts-ignore
      dispatch(getHistoryData())
    } else if (pathname === '/home/bills' && (bills.length === 0 || operations.length === 0) ) {
      // @ts-ignore
      dispatch(getBillsOperationsData())
    }
  }, [])

  useEffect(() => {
    if(pathname === '/home/referal'){
      setData(rewards)
    } else if(pathname === '/home/bills' && tab === 0){
      setData(bills)
    } else {
      setData(operations)
    }

  }, [rewards,operations,bills, tab])

  return (
    <Accordion variant="outlined" sx={styles.container} expanded={isOpen}>
      <AccordionSummary
        sx={styles.header}
        expandIcon={<ExpandMoreIcon onClick={() => setIsOpen(!isOpen)} style={{cursor: 'pointer'}}/>}
        aria-controls="panel1-content"
        id="panel1-header"

      >
        <Box sx={styles.headerContent}>
          <Typography variant="h6">History</Typography>
          {
            type !== 'rewards' && isOpen &&
            <Box sx={{ paddingRight: '32px' }}>
              <CustomTabs value={tab} handleChange={handleChangeTab} tabs={tabs} />
            </Box>
          }
        </Box>

      </AccordionSummary>
      <AccordionDetails>
        {menu_item === '/referal'
            ? <HistoryFilters
                  type="rewards"
                  filter={filter}
                  sortConfig={sortConfig}
                  onChangeSort={handleSortChange}
              />
            : (width > 1024) && <HistoryFilters
                  type={tab === 0 ? "bills" : "operations"}
                  filter={filter}
                  sortConfig={sortConfig}
                  onChangeSort={handleSortChange}
              />
        }

        {menu_item === '/referal'
            ? <>
              { (rewards.length > 0 ? rewards : []).length > 0 ?
                  <HistoryTab type="rewards" data={sortedProducts}/>
                  :
                  <NoData description={checkNoDataDescription(pathname)}/>
              }
            </>
            : <>
              <CustomTabPanel value={tab} index={0}>
                { (bills.length > 0 ? bills : []).length > 0 ?
                    <HistoryTab type="bills" data={sortedProducts}/>
                    :
                    <NoData description={checkNoDataDescription(pathname)}/>
                }
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={1}>
                { (operations.length > 0 ? operations : []).length > 0 ?
                    <HistoryTab type="operations" data={sortedProducts}/>
                    :
                    <NoData description={checkNoDataDescription(pathname)}/>
                }
              </CustomTabPanel>
            </>
        }
      </AccordionDetails>

    </Accordion >
  )
}

export default History