const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "16px"
  },
  info: {
    backgroundColor: "#50A2FF",
    borderRadius: "10px",
    padding: "12px 16px",
    display: "flex",
    gap: "16px",
    alignItems: "center"
  },
  infoApproved: {
    backgroundColor: "#00C06A"
  },
  infoRejected: {
    backgroundColor: "#FFBC50"
  },
  title: {
    color: "#FFF",
    fontWeight: "500"
  },
  description: {
    lineHeight: "24px",
    letterSpacing: "0.01em",
    color: "#5F5F5F",
  }
}

export default styles