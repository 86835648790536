import {Navigate} from "react-router-dom";
import React from "react";

const ProtectedAuthRoute = ({ children }: { children: React.ReactNode}) => {
  const isAuth = localStorage.getItem('access_token')

  if (isAuth !== null) {
    return <Navigate to="/home/dashboard" replace />;
  }

  return children;
};

export default ProtectedAuthRoute;