import {Box, Typography} from "@mui/material";
import {useState} from "react";
import CustomTabs from "../../Tabs";
import styles from "./styles";
import {useTranslation} from "react-i18next";
import {AnalyticsData} from "./AnalyticsData/AnalyticsData";

// const data = [
//   {name: "27.11", uv: 573},
//   {name: "28.11", uv: 436},
//   {name: "29.11", uv: 52},
//   {name: "30.11", uv: 87},
//   {name: "01.12", uv: 331},
//   {name: "02.12", uv: 645},
//   {name: "03.12", uv: 998},
//   {name: "04.12", uv: 637},
//   {name: "05.12", uv: 382},
//   {name: "06.12", uv: 144},
//   {name: "07.12", uv: 140},
//   {name: "08.12", uv: 743},
//   {name: "09.12", uv: 99},
//   {name: "10.12", uv: 147},
//   {name: "11.12", uv: 69},
//   {name: "12.12", uv: 317},
//   {name: "13.12", uv: 857},
//   {name: "14.12", uv: 281},
//   {name: "15.12", uv: 860},
//   {name: "16.12", uv: 475},
//   {name: "17.12", uv: 683},
//   {name: "18.12", uv: 190},
//   {name: "19.12", uv: 769},
//   {name: "20.12", uv: 381},
//   {name: "21.12", uv: 686},
//   {name: "22.12", uv: 946},
//   {name: "23.12", uv: 356},
//   {name: "24.12", uv: 492},
//   {name: "25.12", uv: 157},
//   {name: "26.12", uv: 542}
// ]

const Analytics = ({ menu }: { menu: 'dashboard' | 'products'; }) => {
  const [tab, setTab] = useState(0);

  const {t} = useTranslation()
  const tabs = [t("scanned"), t("verified")];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Typography variant="h6">{t("analytics")}</Typography>
        <CustomTabs value={tab} handleChange={handleChange} tabs={tabs} />
      </Box>
      <Box sx={styles.content}>
        {tab === 0 ?
            <AnalyticsData type={"scanned"} menu={menu} tab={tab}/>
          :
            <AnalyticsData type={"verified"} menu={menu} tab={tab}/>
        }
      </Box >
    </Box >
  );
};

export default Analytics;