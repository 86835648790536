import styles from "../../styles";
import {Box, Typography} from "@mui/material";
import useAnalyticsData from "../../../../../../shared/hooks/useAnalyticsData";
import {ObjectsData} from "../../../../../../shared/types/instances";
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';

// Регистрация локали
countries.registerLocale(enLocale);


interface Props {
  type: 'scanned' | 'verified',
  menu: 'products' | 'dashboard',
}

export const AccordionMap = ({type, menu}: Props) => {
  const countriesData: ObjectsData = useAnalyticsData(type, menu, true)

  // ВОЗМОЖНО ПОНАДОБИТСЯ
  // const simplifyCountryName = (name: string): string => {
  //   if (!name) return 'Unknown country';
  //
  //   const cleanedName = name
  //       .replace(/\b(People's|Democratic|Federal|Republic of|Federation|Kingdom of|Province of|the)\b/gi, '') // Убираем только ключевые слова
  //       .replace(/\s+and\s+/gi, ' & ') // Сокращаем "and" до "&"
  //       .replace(/\s{2,}/g, ' ') // Убираем лишние пробелы
  //       .trim(); // Убираем пробелы по краям
  //
  //   return cleanedName;
  // };

  const getCountryName = (code: string): string => {
    return countries.getName(code, 'en') || code;
  };

  return (
      <>
        {Object.entries(countriesData).map(([key, value]) => (
            <Box sx={styles.flex_center_box}>
              <Box
                  sx={{
                    height: "24px",
                    backgroundColor: type === 'scanned' ? "#FF8800" : 'rgb(0, 192, 106)',
                    width: "8px",
                    borderRadius: "32px",
                  }}>
              </Box>
              <Box>
                <Typography variant="h6" sx={styles.title}>
                  {value}
                </Typography>
                <Typography variant="caption" sx={styles.subtitle}>
                  {getCountryName(key)}
                </Typography>
              </Box>
            </Box>
        ))}
      </>
  )
}