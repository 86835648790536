import { Box } from "@mui/material";
import { ReactNode, useEffect } from "react";
interface Props {
  onClose: () => void;
  openModal: boolean;
  children: ReactNode;
  isBigModalPx?: number;
}

export const ModalOverlay = ({ onClose, openModal, children, isBigModalPx }: Props) => {
  const handleOverlayClick = () => {
    onClose();
  };

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [openModal]);

  if (!openModal) return null;

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        padding: "0 16px",
        backgroundColor: "rgba(255, 255, 255, 0.64)",
        position: "fixed",
        top: 0,
        zIndex: 100,
        backdropFilter: "blur(24px)",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        overflow: "hidden",

        ...(isBigModalPx && {
          [`@media (max-height: ${isBigModalPx}px)`]: {
            alignItems: 'flex-start',
            overflowY: 'scroll'
          }
        })
      }}
      onClick={handleOverlayClick}
    >
      {children}
    </Box>
  );
};
